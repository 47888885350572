import { Button } from 'antd'
import * as B from 'src/components'
import { useController } from './controller'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMailBulk } from '@fortawesome/free-solid-svg-icons'

const MessageListPage = () => {
  const {
    onChangeTablePage,
    tablePage,
    tableColumns,
    messagesTotal,
    messages,
    messageLoading,
    selectedRowKeys,
    setSelectedRowKeys,
    onClickDeleteSelected,
    onClickDeleteAll,
  } = useController()

  return (
    <B.BaseTemplate width="1200px">
      <B.Box mb={4}>
        <B.BaseText size={48}>
          <FontAwesomeIcon icon={faMailBulk} />
        </B.BaseText>

        <B.BaseTitle level={3}>문자 발송 결과</B.BaseTitle>
      </B.Box>

      <B.Box mt={4}>
        <B.Box mb={2}>
          <B.BaseSpace>
            <Button type="primary" danger onClick={onClickDeleteAll}>
              발송결과 전체 삭제
            </Button>
            <Button onClick={onClickDeleteSelected}>선택된 발송결과 삭제</Button>
          </B.BaseSpace>
        </B.Box>
        <B.BaseTable
          size="small"
          columns={tableColumns}
          dataSource={messages}
          loading={messageLoading}
          bordered
          pagination={{
            current: tablePage,
            pageSize: 20,
            total: messagesTotal,
            onChange: onChangeTablePage,
          }}
          rowSelection={{
            selectedRowKeys,
            onChange: (selected: any) => setSelectedRowKeys(selected),
          }}
        />
      </B.Box>
    </B.BaseTemplate>
  )
}

export default MessageListPage
