import React from 'react'
import * as B from 'src/components'
import { Button, Form, Input, Switch } from 'antd'
import { HomeOutlined } from '@ant-design/icons'
import { useController } from './controller'

const AdminNoticeWirtePage = () => {
  const { form, initialValues, onSubmit, bEdit } = useController()

  const manageString = bEdit ? '수정' : '생성'

  return (
    <div>
      <B.Box mb={4}>
        <B.BaseBreadcrumb
          items={[
            { name: <HomeOutlined />, link: '/admin' },
            { name: `팝업 목록`, link: '/admin/notice' },
            { name: `팝업 ${manageString}` },
          ]}
        />
      </B.Box>

      <B.BaseCard title={<B.BaseText mr={2}>팝업 {manageString}</B.BaseText>}>
        <B.BaseForm form={form} initialValues={initialValues} onFinish={onSubmit} layout="vertical">
          <Form.Item name="title" label="제목" rules={[{ required: true }]}>
            <Input placeholder="제목" />
          </Form.Item>

          <Form.Item name="content" label="내용" rules={[{ required: true }]}>
            <Input.TextArea placeholder="내용" rows={6} style={{ resize: 'none' }} />
          </Form.Item>

          <Form.Item name="visible" label="공개 여부" valuePropName="checked">
            <Switch />
          </Form.Item>

          <B.Box>
            <Button type="primary" htmlType="submit" block>
              팝업 {manageString}
            </Button>
          </B.Box>
        </B.BaseForm>
      </B.BaseCard>
    </div>
  )
}

export default AdminNoticeWirtePage
