import { useCallback, useState } from 'react'

export const useController = () => {
  const [visible, setVisible] = useState(false)

  const handleToggle = useCallback(() => {
    setVisible(!visible)
  }, [visible])

  return { visible, setVisible, handleToggle }
}
