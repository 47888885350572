import { Table } from 'antd'
import styled from 'styled-components'

export const BaseTable = styled(Table)<any>`
  & .ant-table-thead .ant-table-cell {
    background-color: ${(props) => props.theme.primaryDark};
    color: white;
  }

  & .ant-table-tbody {
    .ant-table-row:nth-of-type(2n) .ant-table-cell {
      background: #f8f8f8;
    }
  }
`
