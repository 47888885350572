import React from 'react'
import { BaseTemplate } from 'src/components'
import { Row, Col, Menu } from 'antd'
import { PublicRoute } from 'src/components/routes/PublicRoute'
import { Link, Switch, useRouteMatch } from 'react-router-dom'
import AgencyPage from '.'
import { PieChartOutlined, ProfileOutlined, UserOutlined } from '@ant-design/icons'
import AgencyCodeLogPage from './Code/Log'
import AgencyCodePage from './Code'
import AgencyUserViewPage from './View'

const AgencyRoutes = () => {
  const { path } = useRouteMatch()
  return (
    <BaseTemplate width="100%">
      <Row style={{ flexWrap: 'nowrap' }} gutter={20}>
        <Col flex="0 0 200px">
          <Menu style={{ background: 'transparent' }}>
            <Menu.Item key="1" icon={<UserOutlined />}>
              <Link to="/agency">회원 목록</Link>
            </Menu.Item>
            <Menu.Item key="2" icon={<PieChartOutlined />}>
              <Link to="/agency/code">코드 발급</Link>
            </Menu.Item>
            <Menu.Item key="3" icon={<ProfileOutlined />}>
              <Link to="/agency/code/log">코드 발급 내역</Link>
            </Menu.Item>
          </Menu>
        </Col>
        <Col flex="1 1 auto">
          <Switch>
            <PublicRoute path={`${path}/code/log`} component={AgencyCodeLogPage} />
            <PublicRoute path={`${path}/code`} component={AgencyCodePage} />
            <PublicRoute path={`${path}/user/:id`} component={AgencyUserViewPage} />
            <PublicRoute path={`${path}`} component={AgencyPage} />
            
          </Switch>
        </Col>
      </Row>
    </BaseTemplate>
  )
}

export default AgencyRoutes
