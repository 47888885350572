import { Progress } from 'antd'
import * as B from 'src/components'
import { mix } from 'polished'
import { useMemo } from 'react'

interface Props {
  percent: number
}
export const MessageTrafficLight = ({ percent }: Props) => {
  const trafficLightStatusWord = useMemo(() => {
    if (percent > 0.66) return '원활'
    else if (percent > 0.33) return '보통'
    return '혼잡'
  }, [percent])

  return (
    <B.Box style={{ position: 'relative' }}>
      <Progress
        strokeColor={{
          '0%': mix(percent ?? 0, '#87d068', '#F5222D'),
          '50%': `#87d068`,

          '100%': `#87d068`,
        }}
        type="dashboard"
        showInfo={false}
        percent={(percent ?? 0) * 100}
        status="active"
      />
      <B.Box style={{ position: 'absolute', top: 45, left: 45 }}>
        <B.BaseText bold style={{ color: mix(percent ?? 0, '#87d068', '#F5222D') }}>
          {trafficLightStatusWord}
        </B.BaseText>
      </B.Box>
    </B.Box>
  )
}
