import React, { useCallback, useEffect, useState } from 'react'
import * as B from 'src/components'
import { ColumnsType } from 'antd/lib/table'
import { usePointLogsQuery,
       } from 'src/generated/graphql'
import { format } from 'date-fns'

export interface ChargeFormState {
  name: string
  money: number
}

export const useController = () => {
  const { data: pointLogsData, refetch, loading } = usePointLogsQuery({
    variables: { data: {} },
  })

  const pointLogs = pointLogsData?.pointLogs?.items ?? []
  const total = pointLogsData?.pointLogs?.total ?? 0

  // 테이블 페이지네이션
  const [tablePage, setTablePage] = useState(1)

  const onChangeTablePage = useCallback((page: number) => {
    setTablePage(page)
  }, [])

  // 페이지가 바뀌면 새로 불러온다
  useEffect(() => {
    refetch({ data: { page: tablePage } })
    // eslint-disable-next-line
  }, [tablePage])

  // 테이블 구성
  const tableColumns: ColumnsType<NonNullable<typeof pointLogs[number]>> = [
    {
      title: '입금시각',
      dataIndex: 'createdAt',
      render: (createdAt: Date) => (
        <B.BaseText>{format(new Date(createdAt), 'yyyy.MM.dd HH:mm:ss')}</B.BaseText>
      ),
      align: 'center',
    },

    {
      title: '타입',
      dataIndex: ['pointLogType', 'content'],
      align: 'center',
    },
    {
      title: '노트',
      dataIndex: ['user', 'note'],
      align: 'center',
    },
    {
      title: '아이디',
      dataIndex: ['user', 'email'],
      align: 'center',
    },

    {
      title: '발송내용',
      dataIndex: ['message', 'content'],
      align: 'center',
    },

    {
      title: '지급&회수 포인트',
      dataIndex: 'point',
      align: 'center',
    },
    {
      title: '잔여 포인트',
      dataIndex: 'restPoint',
      align: 'center',
    },
  ]

  return {
    pointLogs: pointLogs.map((pointLog) => ({ ...pointLog, key: pointLog.id })),
    total,
    tableColumns,
    loading,
    tablePage,
    onChangeTablePage,
  }
}
