import { forwardRef } from 'react'
import styled from 'styled-components'
import Upload, { RcFile, UploadProps } from 'antd/lib/upload'
import { UploadRequestOption } from 'rc-upload/lib/interface'
import { Button } from 'antd'
import XLSX from 'xlsx'
import { showError } from 'src/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileExcel } from '@fortawesome/free-solid-svg-icons'
import * as B from 'src/components'

const StyledUpload = styled(Upload)`
  & .ant-upload {
    width: 100%;
  }
`

interface Props extends Omit<UploadProps, 'onChange'> {
  onChange?: (tel: string) => void
  xlsx?: boolean
}
export const MessageTelUpload = forwardRef<any, Props>(
  ({ onChange, customRequest, xlsx, ...rest }: Props, ref) => {
    return (
      <StyledUpload
        showUploadList={false}
        customRequest={async (options: UploadRequestOption) => {
          const file = options.file as RcFile
          const extension = file.name.substr(file.name.lastIndexOf('.') + 1).toLowerCase()

          if (xlsx) {
            if (extension !== 'xlsx') return showError('xlsx 파일만 업로드해주세요.')
          } else {
            if (extension !== 'txt' && extension !== 'csv')
              return showError('txt 또는 csv 파일만 업로드해주세요.')
          }

          const fileReader = new FileReader()

          fileReader.onload = () => {
            if (xlsx) {
              const wb = XLSX.read(fileReader.result, { type: 'binary' })
              const sheetNames = Object.keys(wb.Sheets)

              const data: { name: string; tel: string }[] = XLSX.utils.sheet_to_json(
                wb.Sheets[sheetNames[0]],
                {
                  raw: true,
                },
              )

              if (
                !(
                  wb.Sheets[sheetNames[0]]['A1'].v === 'tel' &&
                  wb.Sheets[sheetNames[0]]['B1'].v === 'name'
                )
              ) {
                return showError('엑셀 파일의 형태가 잘못되었습니다. 샘플 파일을 다운받아주세요')
              }

              const dataString = data.map((d) => `${d['tel']} ${d['name']}`).join('\n')
              if (onChange) onChange(dataString)
            } else {
              if (onChange) onChange(fileReader.result as string)
            }
          }

          fileReader.readAsBinaryString(file)
        }}
        style={{ width: '100%' }}
        ref={ref}
      >
        <Button size="large" block shape="round">
          <FontAwesomeIcon icon={faFileExcel} />
          <B.BaseText pl={1}>전화번호 업로드</B.BaseText>
        </Button>
      </StyledUpload>
    )
  },
)
