import React, { useEffect } from 'react'
import * as B from 'src/components'
import { ColumnsType } from 'antd/lib/table'
import { useStatisticsQuery } from 'src/generated/graphql'

export const useController = () => {
  const { data: statisticsData, loading } = useStatisticsQuery()

  const statistics = statisticsData?.statistics

  const dailyCharges = statistics?.dailyCharges ?? []
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const dailyMessages = statistics?.dailyMessages ?? []
  const weeklyCharges = statistics?.weeklyCharges ?? []
  const weeklyMessages = statistics?.weeklyMessages ?? []
  const monthlyCharges = statistics?.monthlyCharges ?? []
  const monthlyMessages = statistics?.monthlyMessages ?? []

  const totalCharges = dailyCharges.reduce((acc, charge) => acc + Number(charge.money), 0)
  const totalMessages = dailyMessages.reduce((acc, message) => acc + Number(message.count), 0)

  useEffect(() => {
    localStorage.setItem('dailyMessages', JSON.stringify(dailyMessages))
  }, [dailyMessages])

  // 테이블 구성
  const dailyChargesTableColumns: ColumnsType<NonNullable<typeof dailyCharges[number]>> = [
    {
      title: '일시',
      dataIndex: 'date',
      align: 'center',
      render: (date: string) => <B.BaseText type="subText">{date}</B.BaseText>,
    },
    {
      title: '결제 금액',
      dataIndex: 'money',
      align: 'center',
      render: (money: number) => (
        <B.BaseText bold>
          <B.MoneyFormat money={money} />
        </B.BaseText>
      ),
    },
  ]

  const weeklyChargesTableColumns: ColumnsType<NonNullable<typeof weeklyCharges[number]>> = [
    {
      title: '시작일',
      dataIndex: 'start',
      align: 'center',
      render: (date: string) => <B.BaseText type="subText">{date}</B.BaseText>,
    },
    {
      title: '종료일',
      dataIndex: 'end',
      align: 'center',
      render: (date: string) => <B.BaseText type="subText">{date}</B.BaseText>,
    },
    {
      title: '결제 금액',
      dataIndex: 'money',
      align: 'center',
      render: (money: number) => (
        <B.BaseText bold>
          <B.MoneyFormat money={money} />
        </B.BaseText>
      ),
    },
  ]

  const dailyMessagesTableColumns: ColumnsType<NonNullable<typeof dailyMessages[number]>> = [
    {
      title: '일시',
      dataIndex: 'date',
      align: 'center',
      render: (date: string) => <B.BaseText type="subText">{date}</B.BaseText>,
    },
    {
      title: '발송 문자 수',
      dataIndex: 'count',
      align: 'center',
      render: (count: number) => (
        <B.BaseText bold>
          <B.MoneyFormat money={count} />
        </B.BaseText>
      ),
    },
  ]

  const weeklyMessagesTableColumns: ColumnsType<NonNullable<typeof weeklyMessages[number]>> = [
    {
      title: '시작일',
      dataIndex: 'start',
      align: 'center',
      render: (date: string) => <B.BaseText type="subText">{date}</B.BaseText>,
    },
    {
      title: '종료일',
      dataIndex: 'end',
      align: 'center',
      render: (date: string) => <B.BaseText type="subText">{date}</B.BaseText>,
    },
    {
      title: '발송 문자 수',
      dataIndex: 'count',
      align: 'center',
      render: (count: number) => (
        <B.BaseText bold>
          <B.MoneyFormat money={count} />
        </B.BaseText>
      ),
    },
  ]

  return {
    loading,
    statistics,

    dailyCharges: dailyCharges?.map((dailyCharge) => ({ ...dailyCharge, key: dailyCharge.date })),
    dailyMessages: dailyMessages?.map((dailyMessage) => ({
      ...dailyMessage,
      key: dailyMessage.date,
    })),
    weeklyCharges: weeklyCharges?.map((weeklyCharge) => ({
      ...weeklyCharge,
      key: weeklyCharge.date,
    })),
    weeklyMessages: weeklyMessages?.map((weeklyMessage) => ({
      ...weeklyMessage,
      key: weeklyMessage.date,
    })),
    monthlyCharges: monthlyCharges?.map((monthlyCharge) => ({
      ...monthlyCharge,
      key: monthlyCharge.date,
    })),
    monthlyMessages: monthlyMessages?.map((monthlyMessage) => ({
      ...monthlyMessage,
      key: monthlyMessage.date,
    })),

    dailyChargesTableColumns,
    weeklyChargesTableColumns,
    dailyMessagesTableColumns,
    weeklyMessagesTableColumns,

    totalCharges,
    totalMessages,
  }
}
