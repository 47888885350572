import React from 'react'
import { BaseFormProvider } from 'src/contexts/BaseFormContext'
import * as B from 'src/components'
import { BaseTemplate } from 'src/components/organisms'
import { useController } from './controller'
import { Row, Col, Form, Input, Button } from 'antd'
import RulesValidator from 'src/utils/utils.rules.validator'
import * as S from './style'

const JoinPage = () => {
  const { form, onSubmit, initialValues } = useController()

  return (
    <BaseFormProvider value={{ onSubmit, form }}>
      <S.StyledContent>
        <S.StyledBackgroundImageWrapper>
          <BaseTemplate backgroundColor="transparent">
            <B.Box mb={4}>
              <B.TextCenter>
                <B.BaseLogo height={160} />
              </B.TextCenter>
            </B.Box>

            <B.Box pt={2} width={800}>
              <B.BaseCard noPadding style={{ border: 'none' }}>
                <Row>
                  <Col xs={24} sm={12}>
                    <B.Box p={2}>
                      <B.BaseForm
                        form={form}
                        onFinish={onSubmit}
                        initialValues={initialValues}
                        labelCol={{ span: 8 }}
                      >
                        <B.Center>
                          <B.BaseTitle level={4} mb={0}>
                            회원가입
                          </B.BaseTitle>
                        </B.Center>
                        <B.BaseDivider />

                        <Row>
                          <Col span={24}>
                            <Form.Item name="name" label="이름" rules={[{ required: true }]}>
                              <Input placeholder="이름" />
                            </Form.Item>

                            <Form.Item name="email" label="아이디" rules={[{ required: true }]}>
                              <Input placeholder="아이디" />
                            </Form.Item>

                            <Form.Item
                              name="password"
                              label="비밀번호"
                              rules={[
                                () => ({
                                  validator: (rule, value) => {
                                    if (!RulesValidator.isValidPassword(value))
                                      return Promise.reject(
                                        '비밀번호는 8자 이상으로 구성해야 합니다.',
                                      )
                                    return Promise.resolve()
                                  },
                                }),
                                { required: true },
                              ]}
                              hasFeedback
                            >
                              <Input.Password placeholder="8자 이상 입력 " />
                            </Form.Item>

                            <Form.Item
                              name="passConf"
                              label="비밀번호 확인"
                              dependencies={['password']}
                              rules={[
                                { required: true },
                                ({ getFieldValue }) => ({
                                  validator(rule, value) {
                                    if (!value || getFieldValue('password') === value)
                                      return Promise.resolve()
                                    return Promise.reject('비밀번호가 일치하지 않습니다.')
                                  },
                                }),
                              ]}
                              hasFeedback
                            >
                              <Input.Password placeholder="비밀번호 확인" />
                            </Form.Item>

                            <Form.Item name="code" label="지인 코드" rules={[{ required: true }]}>
                              <Input placeholder="지인 코드" />
                            </Form.Item>

                            <Form.Item>
                              <B.Box>
                                <B.TextCenter>
                                  <Button type="default" htmlType="submit">
                                    회원가입
                                  </Button>
                                </B.TextCenter>
                              </B.Box>
                            </Form.Item>

                            <B.Center>
                              <B.BaseText>이미 회원 이신가요?</B.BaseText>
                            </B.Center>
                            <B.Center>
                              <B.BaseLink to="/">로그인하기</B.BaseLink>
                            </B.Center>
                            <B.Center>
                              <B.Box mtb={2}>
                                <B.BaseText>
                                  <img src="/telegram.png" width="32" height="32" alt="telegram" />
                                  <B.BaseText pl={1}>문의사항: 나 문 희</B.BaseText>
                                </B.BaseText>
                              </B.Box>
                            </B.Center>
                          </Col>
                        </Row>
                      </B.BaseForm>
                    </B.Box>
                  </Col>
                  <Col xs={24} sm={12}>
                    <S.StyledDecorationCard>
                      <B.Box ptb={5} width="100%">
                        <B.TextCenter>
                          <B.BaseText block type="white" size={32}>
                            지인 운영제
                          </B.BaseText>
                          <B.BaseText bold block type="white" size={32} mb={4}>
                            SMS COMPANY
                          </B.BaseText>
                        </B.TextCenter>
                      </B.Box>
                    </S.StyledDecorationCard>
                  </Col>
                </Row>
              </B.BaseCard>
            </B.Box>
          </BaseTemplate>
        </S.StyledBackgroundImageWrapper>
      </S.StyledContent>
    </BaseFormProvider>
  )
}

export default JoinPage
