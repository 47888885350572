import React, { useEffect, useState } from 'react'
import { message, Modal } from 'antd'
import * as B from 'src/components'
import { Switch } from 'react-router-dom'
import { AdminRoute, AgencyRoute, LoggedRoute, PublicRoute } from './components/routes'
import { useUserContext } from './contexts/UserContext'
import { useNoticesQuery } from './generated/graphql'
import AdminRoutes from './pages/Admin/routes'
import ChargePage from './pages/Charge'
import HomePage from './pages/Home'
import JoinPage from './pages/Join'
import MessageRoutes from './pages/Message/routes'
import MypageRoutes from './pages/Mypage/routes'
import ScrollToTop from './utils/ScrollToTop'
import htmlParser from 'html-react-parser'
import { format } from 'date-fns'
import storage from './utils/utils.storage'
import AgencyRoutes from './pages/Agency/routes'

function App() {
  const { loading } = useUserContext()
  message.config({
    duration: 5,
    maxCount: 1,
  })

  // 팝업 기능
  const { data } = useNoticesQuery({ variables: { data: { visible: true } } })
  const [noticeShown, setNoticeShown] = useState(false)
  const notices = data?.notices?.items

  useEffect(() => {
    if (notices && !noticeShown) {
      setNoticeShown(true)

      notices.forEach((notice) => {
        const checkNoticeShow = storage.getItem(`NOTICE:${notice.id}`)
        if (!checkNoticeShow)
          Modal.confirm({
            icon: null,
            title: notice.title,
            content: (
              <>
                <B.BaseText type="optional" size="small" block mb={2}>
                  {format(new Date(notice.createdAt), 'yyyy.MM.dd')}
                </B.BaseText>
                <B.BaseText>{htmlParser(notice.content.replace(/\n/g, '<br/>'))}</B.BaseText>
              </>
            ),
            width: 600,
            mask: false,
            okText: '이 창 다시보지 않기',
            onOk: () => {
              storage.setItem(`NOTICE:${notice.id}`, true)
            },
            cancelText: '확인',
          })
      })
    }
  }, [notices, noticeShown])

  return loading ? (
    <div> </div>
  ) : (
    <>
      <ScrollToTop />
      <Switch>
        <AdminRoute path="/admin" component={AdminRoutes} />
        <LoggedRoute path="/message" component={MessageRoutes} underInspection />
        <LoggedRoute path="/charge" component={ChargePage} underInspection />
        <LoggedRoute path="/mypage" component={MypageRoutes} />
        <PublicRoute path="/join" component={JoinPage} />
        {/* <PublicRoute path="/login" component={LoginRoutes} /> */}
        <AgencyRoute path="/agency" component={AgencyRoutes} underInspection />
        <PublicRoute path="/" component={HomePage} />
      </Switch>
    </>
  )
}

export default App
