import styled from 'styled-components'
import { Layout, Drawer } from 'antd'
import { lighten } from 'polished'
const { Header, Footer, Content } = Layout

export const StyledHeaderWrapper = styled.div`
  margin-top: ${(props) => props.theme.headerHeight}px;
`

interface StyledHeaderProps {
  noheadershadow?: 'true'
  dark?: 'true'
}
export const StyledHeader = styled(Header)<StyledHeaderProps>`
  position: fixed;
  left: 0;
  right: 0%;
  top: 0;
  background-color: ${(props) => (props.dark ? '#fbfbfb' : 'white')};
  padding: 20px;
  z-index: 1;

  ${(props) => (props.noheadershadow ? `` : `border-bottom: 1px solid rgba(0, 0, 0, 0.05);`)}

  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const StyledHeaderBox = styled.div`
  margin-top: ${(props) => props.theme.headerHeight}px;
  padding: 20px;
  position: fixed;
  left: 0;
  right: 0%;
  top: 0;
  z-index: 1;
`

interface ContentProps {
  narrow?: 'true'
  nopadding?: 'true'
  width?: string
}
export const StyledContent = styled(Content)<ContentProps>`
  min-height: 100vh;
  width: ${(props) => (props.narrow ? '440px' : '900px')};
  max-width: 100%;
  padding: ${(props) => (props.nopadding ? '0px' : '20px')};
  margin: 0 auto;
  ${(props) => props.width && `width: ${props.width};`}
`

interface StyledFooterProps {
  noheadershadow?: 'true'
  dark?: 'true'
}
export const StyledFooter = styled(Footer)<StyledFooterProps>`
  padding: 0;

  ${(props) => (props.dark ? `background-color: #101010` : ``)}
`

export const StyledDrawer = styled(Drawer)`
  color: ${(props) => props.theme.primaryDark};
  & .ant-drawer-body {
    padding: 0;
  }
`

export const StyledDrawerItem = styled.div`
  cursor: pointer;
  padding: 18px 24px;
  transition: background-color 0.5s;
  border-bottom: 1px solid ${(props) => props.theme.disabled};
  color: ${(props) => props.theme.subText};

  &:hover {
    background-color: ${(props) => lighten(0.1, props.theme.primaryLight)};
  }
`
