import React from 'react'
import * as B from 'src/components'
import { Radio, Row, Col, Button, Form, InputNumber, Table, Input } from 'antd'
import { HomeOutlined } from '@ant-design/icons'
import { useController } from './controller'
import { UserStatus } from 'src/generated/graphql'
import { format } from 'date-fns'

const AdminPointAgencyPage = () => {
  const {
    loading,
    tableColumns,
    onClickSearch,
    searchForm,
    users,
    selectedUser,
    form,
    onSubmit,
  } = useController()

  return (
    <div>
      <B.Box mb={4}>
        <B.BaseBreadcrumb
          items={[
            { name: <HomeOutlined />, link: '/admin' },
            { name: '포인트 관리', link: '/admin/point' },
            { name: '대리점 정산' },
          ]}
        />
      </B.Box>

      <B.Box>
        <Row gutter={20}>
          <Col xs={24} sm={24} md={12}>
            <B.BaseCard title="대리점 찾기">
              <B.BaseForm layout="vertical" onFinish={onClickSearch} form={searchForm}>
                <Row style={{ flexWrap: 'nowrap' }}>
                  <Col flex="1 1 auto">
                    <Form.Item name="searchText" label="아이디" labelCol={{ span: 0 }}>
                      <Input placeholder="아이디" />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Button type="primary" htmlType="submit">
                      검색
                    </Button>
                  </Col>
                </Row>
              </B.BaseForm>
              <B.BaseDivider>검색 결과</B.BaseDivider>
              <Table
                size="small"
                scroll={{ x: '100%' }}
                columns={tableColumns}
                dataSource={users}
                loading={loading}
                bordered
              />
            </B.BaseCard>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <B.BaseCard title="정산포인트 관리">
              {selectedUser && (
                <>
                  <B.Box>
                    <B.BaseTitle level={4}>회원 정보</B.BaseTitle>
                    <B.BaseText block>
                      <B.BaseText bold>아이디</B.BaseText>: {selectedUser.email}
                    </B.BaseText>
                    <B.BaseText block>
                      <B.BaseText bold>건당 포인트</B.BaseText>:{' '}
                      <B.MoneyFormat money={selectedUser?.userCode?.cost ?? 0} />
                    </B.BaseText>
                    <B.BaseText block>
                      <B.BaseText bold>잔여 포인트</B.BaseText>:{' '}
                      <B.MoneyFormat money={selectedUser.point} />
                    </B.BaseText>
                    <B.BaseText block>
                      <B.BaseText bold>정산 포인트</B.BaseText>:{' '}
                      <B.MoneyFormat money={selectedUser.agencyPoint} />
                    </B.BaseText>

                    <B.BaseText block>
                      <B.BaseText bold>추천인 코드</B.BaseText>: {selectedUser.userCode?.content}
                    </B.BaseText>
                    <B.BaseText block>
                      <B.BaseText bold>계정 생성일</B.BaseText>:{' '}
                      {format(new Date(selectedUser.createdAt), 'yyyy.MM.dd')}
                    </B.BaseText>
                    <B.BaseText block>
                      <B.BaseText bold>최근 접속일</B.BaseText>:{' '}
                      {format(new Date(selectedUser.loggedAt), 'yyyy.MM.dd')}
                    </B.BaseText>
                    <B.BaseText block>
                      <B.BaseText bold>정지 여부</B.BaseText>:{' '}
                      {selectedUser.status === UserStatus.Normal ? (
                        <B.BaseText>활성화</B.BaseText>
                      ) : (
                        <B.BaseText type="danger">정지</B.BaseText>
                      )}
                    </B.BaseText>
                  </B.Box>
                  <B.BaseDivider />
                  <B.Box>
                    <B.BaseTitle level={4}>정산포인트 회수</B.BaseTitle>
                    <B.BaseForm form={form} onFinish={onSubmit}>
                      <Form.Item name="point" label="회수 포인트" rules={[{ required: true }]}>
                        <InputNumber style={{ width: '100%' }} placeholder="예) 20000" />
                      </Form.Item>
                      <Form.Item name="type" label="정산 후 작업" rules={[{ required: true }]}>
                        <Radio.Group>
                          <Radio.Button value="POINT">포인트로</Radio.Button>
                          <Radio.Button value="CASH">현금으로</Radio.Button>
                        </Radio.Group>
                      </Form.Item>
                      <B.Box>
                        <Button type="primary" htmlType="submit">
                          지급&회수
                        </Button>
                      </B.Box>
                    </B.BaseForm>
                  </B.Box>
                </>
              )}
            </B.BaseCard>
          </Col>
        </Row>
      </B.Box>
    </div>
  )
}

export default AdminPointAgencyPage
