import React from 'react'
import { PublicRoute } from 'src/components/routes/PublicRoute'
import { Switch, useRouteMatch } from 'react-router-dom'
import MypagePage from '.'
import MypagePasswordPage from './Password'

const MypageRoutes = () => {
  const { path } = useRouteMatch()
  return (
    <Switch>
      <PublicRoute path={`${path}/password`} component={MypagePasswordPage} />
      <PublicRoute path={`${path}/`} component={MypagePage} />
    </Switch>
  )
}

export default MypageRoutes
