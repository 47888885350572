import { HomeOutlined } from '@ant-design/icons'
import { Form, Input, Button } from 'antd'
import * as B from 'src/components'
import { useController } from './controller'

const AgencyPage = () => {
  const {
    users,
    loading,
    tableColumns,
    total,
    tablePage,
    onChangeTablePage,
    onClickSearch,
    form,
    me,
  } = useController()

  return (
    <div>
      <B.Box mb={4}>
        <B.BaseBreadcrumb
          items={[{ name: <HomeOutlined />, link: '/admin' }, { name: '회원 목록' }]}
        />
      </B.Box>

      <B.Box mb={4}>
        <B.BaseText bold mr={1}>
          정산 포인트
        </B.BaseText>
        <B.BaseText>
          <B.MoneyFormat money={me?.agencyPoint ?? 0} /> 포인트
        </B.BaseText>
      </B.Box>

      <B.Box mb={4}>
        <B.BaseForm form={form} onFinish={onClickSearch}>
          <Form.Item name="searchText">
            <Input placeholder="아이디 검색" style={{ width: 200 }} />
          </Form.Item>

          <Button type="primary" htmlType="submit">
            검색
          </Button>
        </B.BaseForm>
      </B.Box>

      <B.BaseCard
        title={
          <B.BaseText>
            유저 목록 총{' '}
            <B.BaseText type="primary" bold>
              <B.MoneyFormat money={total} />
            </B.BaseText>
            명
          </B.BaseText>
        }
      >
        <B.BaseTable
          size="small"
          scroll={{ x: '1200px' }}
          columns={tableColumns}
          dataSource={users}
          loading={loading}
          bordered
          pagination={{
            current: tablePage,
            pageSize: 20,
            total: total,
            onChange: onChangeTablePage,
          }}
        />
      </B.BaseCard>
    </div>
  )
}

export default AgencyPage
