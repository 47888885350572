import React, { useCallback, useEffect, useState } from 'react'
import * as B from 'src/components'
import { ColumnsType } from 'antd/lib/table'
import { useNoticesQuery, Notice, useDeleteNoticeMutation } from 'src/generated/graphql'
import { format } from 'date-fns'
import { Button } from 'antd'
import { Link } from 'react-router-dom'
import { showError, showSuccess } from 'src/utils'

export const useController = () => {
  const { data, loading, refetch } = useNoticesQuery({ variables: { data: {} } })
  const [deleteNotice] = useDeleteNoticeMutation()

  const notices = data?.notices?.items ?? []
  const total = data?.notices?.total ?? 0

  // 테이블 페이지네이션
  const [tablePage, setTablePage] = useState(1)

  const onChangeTablePage = useCallback((page: number) => {
    setTablePage(page)
  }, [])

  // 페이지가 바뀌면 새로 불러온다
  useEffect(() => {
    refetch({ data: { page: tablePage } })
    // eslint-disable-next-line
  }, [tablePage])

  const onClickDeleteNotice = useCallback(
    async (notice: Notice) => {
      try {
        await deleteNotice({ variables: { id: notice.id } })
        showSuccess('팝업을 삭제하였습니다.')
        refetch({ data: { page: tablePage } })
      } catch (e) {
        showError(e?.message)
      }
    },
    [deleteNotice, tablePage, refetch],
  )

  // 테이블 구성
  const tableColumns: ColumnsType<NonNullable<typeof notices[number]>> = [
    {
      title: 'ID',
      dataIndex: 'id',
      align: 'center',
    },
    {
      title: '조회',
      dataIndex: [],
      render: (notice: Notice) => (
        <Link to={`/admin/notice/write/${notice.id}`}>
          <Button size="small">조회</Button>
        </Link>
      ),
      align: 'center',
    },
    {
      title: '제목',
      dataIndex: 'title',
      align: 'center',
    },
    {
      title: '내용',
      dataIndex: 'content',
      align: 'center',
      ellipsis: true,
    },
    {
      title: '공개 여부',
      dataIndex: 'visible',
      align: 'center',
      render: (visible: boolean) =>
        visible ? (
          <B.BaseText type="primary">공개</B.BaseText>
        ) : (
          <B.BaseText type="danger">비공개</B.BaseText>
        ),
    },
    {
      title: '생성일',
      dataIndex: 'createdAt',
      render: (createdAt: Date) => (
        <B.BaseText>{format(new Date(createdAt), 'yyyy.MM.dd')}</B.BaseText>
      ),
      align: 'center',
    },
    {
      title: '관리',
      dataIndex: [],
      align: 'center',
      render: (notice: Notice) => {
        return (
          <Button danger onClick={() => onClickDeleteNotice(notice)}>
            삭제
          </Button>
        )
      },
    },
  ]

  return {
    notices: notices.map((notice) => ({ ...notice, key: notice.id })),
    loading,
    tableColumns,
    total,
    onChangeTablePage,
    tablePage,
  }
}
