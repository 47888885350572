import * as B from 'src/components'
import * as S from './style'
import { Row, Col, Form, Input, Button } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretSquareRight } from '@fortawesome/free-solid-svg-icons'
import theme from 'src/styles/theme'
import { useController } from './controller'
import { useUserContext } from 'src/contexts/UserContext'

const HomePage = () => {
  const { onSubmit, form } = useController()
  const { logged } = useUserContext()
  return (
    <B.Box>
      <S.StyledContent>
        <S.StyledBackgroundImageWrapper>
          <B.Box style={{ position: 'relative' }}>
            <B.BaseTemplate backgroundColor="transparent" noPadding width="100%">
              <S.SectionWholeContentWrapper>
                <S.SectionBackground />

                <S.SectionWholeContent>
                  <S.SectionWrapperCenter>
                    <B.Box pb={5} pt={5}>
                      <B.TextCenter>
                        <B.Box>
                          <B.BaseLogo height={160} />
                          <B.BaseText bold size={48} block mtb={4} type="white">
                            지인 운영제 SMS
                          </B.BaseText>
                        </B.Box>

                        {logged ? (
                          <B.Box mt={10}>
                            <B.Box mtb={2}>
                              <B.BaseText>
                                <img src="/telegram.png" width="32" height="32" alt="telegram" />
                                <B.BaseText pl={1} type="white" bold>
                                  문의사항: 텔레그램: @dorangs1
                                </B.BaseText>
                              </B.BaseText>
                            </B.Box>
                          </B.Box>
                        ) : (
                          <B.Box>
                            <B.Box width={850} mb={2}>
                              <B.BaseCard>
                                <B.BaseForm form={form} onFinish={onSubmit} labelCol={{ span: 0 }}>
                                  <B.BaseText bold block size="huge" mb={2}>
                                    회원전용 로그인
                                  </B.BaseText>
                                  <Row gutter={10}>
                                    <Col span={6}>
                                      <B.BaseText size={60} style={{ color: theme.primaryDark }}>
                                        <FontAwesomeIcon icon={faCaretSquareRight} />
                                      </B.BaseText>
                                    </Col>
                                    <Col span={18}>
                                      <B.Box mt={1}>
                                        <Form.Item label="아이디" name="email">
                                          <Input placeholder="아이디를 입력하세요" />
                                        </Form.Item>

                                        <Form.Item label="비밀번호" name="password">
                                          <Input placeholder="비밀번를 입력하세요" type="password" />
                                        </Form.Item>
                                      </B.Box>
                                    </Col>
                                  </Row>
                                  <Form.Item>
                                    <Button type="default" htmlType="submit" block>
                                      로그인
                                    </Button>
                                  </Form.Item>
                                  <B.Center>
                                    <B.BaseText>
                                      회원코드를 받으셨나요??{' '}
                                      <B.BaseLink to="/join">가입하기</B.BaseLink>
                                    </B.BaseText>
                                  </B.Center>
                                </B.BaseForm>
                              </B.BaseCard>
                            </B.Box>

                            <B.Box mtb={2}>
                              <B.BaseText>
                                <img src="/telegram.png" width="32" height="32" alt="telegram" />
                                <B.BaseText pl={1} type="white" bold>
                                  문의사항 불가능 회원제입니다.
                                </B.BaseText>
                              </B.BaseText>
                            </B.Box>
                          </B.Box>
                        )}
                      </B.TextCenter>
                    </B.Box>
                  </S.SectionWrapperCenter>
                </S.SectionWholeContent>
              </S.SectionWholeContentWrapper>
            </B.BaseTemplate>
          </B.Box>
        </S.StyledBackgroundImageWrapper>
      </S.StyledContent>
    </B.Box>
  )
}

export default HomePage
