import { Col, Form, Tabs } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { media } from 'src/styles/media'
import styled from 'styled-components'

const { TabPane } = Tabs

export const StyledLeftCol = styled(Col)`
  flex: 0 0 840px;
  min-height: 650px;
  ${media.sm`
    flex: 0 0 100%;
  `};
`

export const StyledRightCol = styled(Col)`
  position: relative;
  flex: 0 0 320px;

  ${media.sm`
    flex: 0 0 0px;
    display:none;
  `}
`

export const StyledIPhone = styled.img`
  position: absolute;
  width: 300px;
`

export const StyledMessageTextAreaBox = styled.div`
  position: absolute;
  top: 80px;
  padding: 0 20px;
  width: 297px;
`

export const StyledMessageTextArea = styled(TextArea)`
  resize: none;
`

export const StyledFullWidthFormItem = styled(Form.Item)`
  & label {
    display: block;
    height: auto !important;
  }
`

export const StyledTabs = styled(Tabs)`
  min-height: 400px;

  & .ant-tabs-nav-list {
    height: 100%;
    display: flex;

    .ant-tabs-tab {
      flex: 1 1 auto;
    }
  }

  & .ant-tabs-content-holder {
    display: none;
  }
`
export const StyledTabPane = styled(TabPane)``
