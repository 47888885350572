import { useCallback, useMemo } from 'react'
import * as B from 'src/components'
import { ColumnsType } from 'antd/lib/table'
import {
  UserCode,
  UserCodeType,
  useUserCodesQuery,
  useCreateUserCodeMutation,
  useDeleteUserCodeMutation,
} from 'src/generated/graphql'
import { format } from 'date-fns'
import { useForm } from 'antd/lib/form/Form'
import { Button } from 'antd'
import { useUserContext } from 'src/contexts/UserContext'
import { showError, showSuccess } from 'src/utils'

export interface CreateCodeFormState {
  cost: number
  bonus: number
  type: UserCodeType
}

export const useController = () => {
  const [form] = useForm<CreateCodeFormState>()
  const { user } = useUserContext()
  const { data, loading, refetch } = useUserCodesQuery({
    variables: { data: { used: false, creatorId: user.id } },
  })
  const [createUserCode] = useCreateUserCodeMutation()
  const [deleteUserCode] = useDeleteUserCodeMutation()

  const userCodes = data?.userCodes?.items ?? []
  const total = data?.userCodes?.total ?? 0

  const initialValues = useMemo<Partial<CreateCodeFormState>>(() => {
    return {
      type: UserCodeType.Normal,
    }
  }, [])

  const onSubmit = useCallback(async () => {
    const updatedFormState = form.getFieldsValue(true) as CreateCodeFormState

    const { bonus, cost, type } = updatedFormState

    if (bonus > 5) return showError('무료전송건수는 최대 5개까지 가능합니다.')

    try {
      await createUserCode({ variables: { data: { cost, bonus, type } } })

      showSuccess('코드를 발급하였습니다.')

      refetch({ data: { used: false, creatorId: user.id } })
    } catch (e) {
      showError(e?.message)
    }
  }, [form, createUserCode, refetch, user])

  const onClickDeleteUserCode = useCallback(
    async (userCode: UserCode) => {
      try {
        await deleteUserCode({ variables: { id: userCode.id } })

        showSuccess('코드를 삭제했습니다.')

        refetch({ data: { used: false, creatorId: user.id } })
      } catch (e) {
        showError(e?.message)
      }
    },
    [refetch, deleteUserCode, user],
  )

  // 테이블 구성
  const tableColumns: ColumnsType<NonNullable<typeof userCodes[number]>> = [
    {
      title: '생성일',
      dataIndex: 'createdAt',
      render: (createdAt: Date) => (
        <B.BaseText>{format(new Date(createdAt), 'yyyy.MM.dd')}</B.BaseText>
      ),
      align: 'center',
    },
    {
      title: '코드',
      dataIndex: 'content',
      align: 'center',
    },
    {
      title: '건당 포인트',
      dataIndex: 'cost',
      align: 'center',
    },
    {
      title: '무료 전송 건수',
      dataIndex: 'bonus',
      align: 'center',
    },
    {
      title: '생성자',
      dataIndex: ['creator', 'email'],
      align: 'center',
    },
    {
      title: '삭제',
      dataIndex: [],
      render: (userCode: UserCode) => (
        <Button
          onClick={() => {
            onClickDeleteUserCode(userCode)
          }}
        >
          삭제
        </Button>
      ),
      align: 'center',
    },
  ]

  return {
    initialValues,
    userCodes: userCodes.map((userCode) => ({ ...userCode, key: userCode.id })),
    loading,
    tableColumns,
    total,
    onSubmit,
    form,
  }
}
