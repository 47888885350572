import React from 'react'
import { Route, RouteProps, Redirect } from 'react-router-dom'
import { useUserContext } from 'src/contexts/UserContext'
import { useIsUnderInspectionQuery, UserType } from 'src/generated/graphql'
import { showError } from 'src/utils'

export const AgencyRoute = ({
  component,
  underInspection,
  ...rest
}: RouteProps & { underInspection?: boolean }) => {
  const { user } = useUserContext()
  const { data: underInspectionData } = useIsUnderInspectionQuery()
  const isUnderInspection = underInspectionData?.isUnderInspection ?? false

  if (!component) return null
  const Component = component as React.ComponentType<RouteProps>

  if (underInspection && isUnderInspection) {
    showError('사이트가 점검중입니다.')
    return <Redirect to="/" />
  }

  if (!user) return <Redirect to="/" />
  if (user.userType !== UserType.Agency) return <Redirect to="/" />

  return (
    <Route
      {...rest}
      render={(props) => {
        return <Component />
      }}
    />
  )
}
