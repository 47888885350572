import React, { useCallback, useEffect, useMemo, useState } from 'react'
import * as B from 'src/components'
import { notification } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { ColumnsType } from 'antd/lib/table'
import { useUserContext } from 'src/contexts/UserContext'
import {
  useChargeMutation,
  UserCharge,
  UserChargeStatus,
  useUserChargesQuery,
  useDeleteUserChargeMutation,
  useMeQuery,
} from 'src/generated/graphql'
import { format } from 'date-fns'
import { SmileOutlined } from '@ant-design/icons'
import theme from 'src/styles/theme'
import { showError, showSuccess } from 'src/utils'

export interface ChargeFormState {
  name: string
  money: number
}

export const useController = () => {
  const [form] = useForm()
  const [charge] = useChargeMutation()
  const { user } = useUserContext()
  const { data: userChargesData, refetch, loading } = useUserChargesQuery({
    variables: { data: { userId: user.id } },
  })
  const [deleteUserCharge] = useDeleteUserChargeMutation()

  const userCharges = userChargesData?.userCharges?.items ?? []
  const total = userChargesData?.userCharges?.total ?? 0

  const initialValues = useMemo<ChargeFormState>(() => ({ money: 0, name: '' }), [])

  const { data: meData } = useMeQuery()
  const me = meData?.me

  // 테이블 페이지네이션
  const [tablePage, setTablePage] = useState(1)

  const onChangeTablePage = useCallback((page: number) => {
    setTablePage(page)
  }, [])

  // 페이지가 바뀌면 새로 불러온다
  useEffect(() => {
    refetch({ data: { userId: user.id, page: tablePage } })
    // eslint-disable-next-line
  }, [tablePage])

  const onSubmit = useCallback(async () => {
    const updatedFormState = form.getFieldsValue(true) as ChargeFormState

    const { money, name } = updatedFormState

    try {
      await charge({ variables: { data: { money, name } } })

      notification.open({
        message: '충전 신청',
        description: '충전 확인 요청을 하였습니다.',
        icon: <SmileOutlined style={{ color: theme.primary }} />,
        duration: 3,
      })

      form.setFieldsValue({ name: '', money: undefined })

      refetch({ data: { userId: user.id, page: tablePage } })
    } catch (e) {
      showError(e?.message)
    }
  }, [form, charge, refetch, user, tablePage])

  // eslint-disable-next-line
  const onClickDeleteUserCharge = useCallback(
    async (userCharge: UserCharge) => {
      try {
        await deleteUserCharge({ variables: { id: userCharge.id } })
        showSuccess('성공적으로 삭제하였습니다.')
        refetch({ data: { userId: user.id, page: tablePage } })
      } catch (e) {
        showError(e?.message)
      }
    },
    [refetch, deleteUserCharge, tablePage, user],
  )

  // 테이블 구성
  const tableColumns: ColumnsType<NonNullable<typeof userCharges[number]>> = [
    {
      title: '날짜',
      dataIndex: 'createdAt',
      render: (createdAt: Date) => (
        <B.BaseText>{format(new Date(createdAt), 'yyyy.MM.dd')}</B.BaseText>
      ),
      align: 'center',
    },
    {
      title: '이름',
      dataIndex: 'name',
      align: 'center',
    },
    {
      title: '충전 금액',
      dataIndex: 'money',
      align: 'center',
    },
    {
      title: '수락/거절 여부',
      dataIndex: 'status',
      render: (status: UserChargeStatus) =>
        status === UserChargeStatus.Ready ? (
          <B.BaseText type="subText">대기</B.BaseText>
        ) : status === UserChargeStatus.Applied ? (
          <B.BaseText type="primary">수락</B.BaseText>
        ) : (
          <B.BaseText type="danger">거절</B.BaseText>
        ),
      align: 'center',
    },
    // {
    //   title: '관리',
    //   dataIndex: [],
    //   render: (userCharge: UserCharge) =>
    //     userCharge.status === UserChargeStatus.Ready ? (
    //       <Button onClick={() => onClickDeleteUserCharge(userCharge)} size="small" danger>
    //         삭제
    //       </Button>
    //     ) : (
    //       '-'
    //     ),
    //   align: 'center',
    // },
  ]

  return {
    form,
    initialValues,
    onSubmit,
    userCharges: userCharges.map((userCharge) => ({ ...userCharge, key: userCharge.id })),
    total,
    tableColumns,
    loading,
    tablePage,
    onChangeTablePage,
    me,
  }
}
