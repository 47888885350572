import React, { useCallback, useEffect, useState } from 'react'
import * as B from 'src/components'
import { ColumnsType } from 'antd/lib/table'
import { UserCodeType, useUserCodesQuery } from 'src/generated/graphql'
import { format } from 'date-fns'
import { useUserContext } from 'src/contexts/UserContext'

export interface CreateCodeFormState {
  cost: number
  bonus: number
}

export const useController = () => {
  const { user } = useUserContext()
  const { data, loading, refetch } = useUserCodesQuery({
    variables: { data: { used: true, creatorId: user.id } },
  })

  const userCodes = data?.userCodes?.items ?? []
  const total = data?.userCodes?.total ?? 0

  // 테이블 페이지네이션
  const [tablePage, setTablePage] = useState(1)

  const onChangeTablePage = useCallback((page: number) => {
    setTablePage(page)
  }, [])

  // 페이지가 바뀌면 새로 불러온다
  useEffect(() => {
    refetch({
      data: {
        used: true,
        creatorId: user.id,
        page: tablePage,
      },
    })
    // eslint-disable-next-line
  }, [tablePage])

  // 테이블 구성
  const tableColumns: ColumnsType<NonNullable<typeof userCodes[number]>> = [
    {
      title: '생성일',
      dataIndex: 'createdAt',
      render: (createdAt: Date) => (
        <B.BaseText>{format(new Date(createdAt), 'yyyy.MM.dd')}</B.BaseText>
      ),
      align: 'center',
    },
    {
      title: '코드',
      dataIndex: 'content',
      align: 'center',
    },
    {
      title: '유저아이디',
      dataIndex: ['user', 'email'],
      align: 'center',
    },
    {
      title: '건당 포인트',
      dataIndex: 'cost',
      align: 'center',
    },
    {
      title: '타입',
      dataIndex: 'userCodeType',
      align: 'center',
      render: (type: UserCodeType) =>
        type === UserCodeType.Normal ? (
          <B.BaseText>일반</B.BaseText>
        ) : (
          <B.BaseText>대리점</B.BaseText>
        ),
    },
    {
      title: '생성자',
      dataIndex: ['creator', 'email'],
      align: 'center',
    },
  ]

  return {
    userCodes: userCodes.map((userCode) => ({ ...userCode, key: userCode.id })),
    loading,
    tableColumns,
    total,
    onChangeTablePage,
    tablePage,
  }
}
