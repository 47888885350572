import { useForm } from 'antd/lib/form/Form'
import { useCallback, useEffect, useMemo } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {
  useCreateNoticeMutation,
  useNoticeQuery,
  useUpdateNoticeMutation,
} from 'src/generated/graphql'
import { showError, showSuccess } from 'src/utils'

export interface WriteNoticeFormState {
  title: string
  content: string
  visible: boolean
}

export const useController = () => {
  const [form] = useForm()
  const { id } = useParams<{ id?: string }>()
  const [createNotice] = useCreateNoticeMutation()
  const [updateNotice] = useUpdateNoticeMutation()
  const { data } = useNoticeQuery({ variables: { id: Number(id) } })
  const history = useHistory()

  const notice = data?.notice

  const initialValues: WriteNoticeFormState = useMemo(() => {
    return {
      title: '',
      content: '',
      visible: true,
    }
  }, [])

  useEffect(() => {
    if (notice)
      form.setFieldsValue({ title: notice.title, content: notice.content, visible: notice.visible })
  }, [notice, form])

  const bEdit = !!id

  const onSubmit = useCallback(async () => {
    const updatedFormState = form.getFieldsValue(true) as WriteNoticeFormState

    const { content, visible, title } = updatedFormState

    console.log(updatedFormState)

    try {
      if (bEdit) {
        await updateNotice({
          variables: {
            data: {
              id: Number(id),
              title,
              content,
              visible,
            },
          },
        })

        showSuccess('팝업을 수정하였습니다.')
      } else {
        await createNotice({
          variables: {
            data: {
              title,
              content,
              visible,
            },
          },
        })
        showSuccess('팝업을 생성하였습니다.')
      }

      history.replace('/admin/notice')
    } catch (e) {
      showError(e?.message)
    }
  }, [form, bEdit, id, createNotice, updateNotice, history])

  return { form, initialValues, onSubmit, bEdit }
}
