import { useUserContext } from 'src/contexts/UserContext'
import storage from 'src/utils/utils.storage'
import { useLoginMutation, User } from 'src/generated/graphql'
import { useForm } from 'antd/lib/form/Form'
import { showError } from 'src/utils'

export type LoginFormState = { email: string; password: string }

export const useController = () => {
  const [login] = useLoginMutation()
  const { onLogin } = useUserContext()
  const [form] = useForm()

  const onSubmit = async () => {
    const updatedFormState = form.getFieldsValue(true) as LoginFormState
    try {
      const { data } = await login({ variables: { data: updatedFormState } })
      const token = data?.login.token
      const user = data?.login.user
      if (token) storage.setItem('access_token', token)
      if (user) onLogin(user as User)
    } catch (e) {
      showError(e?.message)
    }
  }

  return { form, onSubmit }
}
