import { useCallback, useEffect, useState } from 'react'
import * as B from 'src/components'
import { Button, Popconfirm } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import {
  UserCharge,
  UserChargeStatus,
  useUserChargesQuery,
  useUpdateUserChargeMutation,
} from 'src/generated/graphql'
import { format } from 'date-fns'
import { showError } from 'src/utils'
import { useForm } from 'antd/lib/form/Form'

export interface ChargeFormState {
  name: string
  money: number
}

export interface SearchChargeFilterFormState {
  searchId: string
  searchName: string
  searchNote: string
}

export const useController = () => {
  const [form] = useForm<SearchChargeFilterFormState>()
  const {
    data: userChargesData,
    refetch,
    loading,
  } = useUserChargesQuery({
    variables: { data: {} },
  })
  const [updateUserCharge] = useUpdateUserChargeMutation()

  const userCharges = userChargesData?.userCharges?.items ?? []
  const total = userChargesData?.userCharges?.total ?? 0

  // 테이블 페이지네이션
  const [tablePage, setTablePage] = useState(1)

  const onChangeTablePage = useCallback((page: number) => {
    setTablePage(page)
  }, [])

  const search = useCallback(async () => {
    const updatedFormState = form.getFieldsValue(true) as SearchChargeFilterFormState
    const { searchId, searchName, searchNote } = updatedFormState

    refetch({
      data: {
        searchId,
        searchName,
        searchNote,
        page: tablePage,
      },
    })
  }, [form, tablePage, refetch])

  const onClickSearch = useCallback(() => {
    setTablePage(1)
    search()
  }, [search])

  // 페이지가 바뀌면 새로 불러온다
  useEffect(() => {
    const updatedFormState = form.getFieldsValue(true) as SearchChargeFilterFormState
    const { searchId, searchName, searchNote } = updatedFormState
    refetch({ data: { searchId, searchName, searchNote, page: tablePage } })
    // eslint-disable-next-line
  }, [tablePage])

  const onClickUpdateUserCharge = useCallback(
    async (userCharge: UserCharge, status: UserChargeStatus) => {
      try {
        await updateUserCharge({ variables: { data: { id: userCharge.id, status } } })
        refetch({ data: { page: tablePage } })
      } catch (e: any) {
        showError(e?.message)
      }
    },
    [updateUserCharge, tablePage, refetch],
  )

  // 테이블 구성
  const tableColumns: ColumnsType<NonNullable<typeof userCharges[number]>> = [
    {
      title: '사용시각',
      dataIndex: 'createdAt',
      render: (createdAt: Date) => (
        <B.BaseText>{format(new Date(createdAt), 'yyyy.MM.dd HH:mm:ss')}</B.BaseText>
      ),
      align: 'center',
    },
    {
      title: '노트',
      dataIndex: ['user', 'note'],
      align: 'center',
    },
    {
      title: '입금자명',
      dataIndex: 'name',
      align: 'center',
    },
    {
      title: '아이디',
      dataIndex: ['user', 'email'],
      align: 'center',
    },
    {
      title: '입금 금액',
      dataIndex: 'money',
      align: 'center',
    },
    {
      title: '처리',
      dataIndex: [],
      render: (userCharge: UserCharge) =>
        userCharge.status === UserChargeStatus.Ready ? (
          <>
            <B.BaseSpace style={{ justifyContent: 'center' }}>
              <Popconfirm
                title="수락을 누르면 해당 회원에게 포인트가 지급됩니다."
                okText="지급"
                onConfirm={() => onClickUpdateUserCharge(userCharge, UserChargeStatus.Applied)}
              >
                <Button>수락</Button>
              </Popconfirm>

              <Button
                onClick={() => onClickUpdateUserCharge(userCharge, UserChargeStatus.Rejected)}
              >
                거절
              </Button>
            </B.BaseSpace>
          </>
        ) : userCharge.status === UserChargeStatus.Applied ? (
          <B.BaseText type="primary">수락</B.BaseText>
        ) : (
          <B.BaseText type="danger">거절</B.BaseText>
        ),

      align: 'center',
    },
  ]

  return {
    userCharges: userCharges.map((userCharge) => ({ ...userCharge, key: userCharge.id })),
    total,
    tableColumns,
    loading,
    tablePage,
    onChangeTablePage,
    onClickSearch,
    form,
  }
}
