import { useForm } from 'antd/lib/form/Form'
import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { useUpdatePasswordMutation } from 'src/generated/graphql'
import { showError, showSuccess } from 'src/utils'

export interface MypagePasswordFormState {
  password: string
  passConf: string
  passPrev: string
}

export const useController = () => {
  const [form] = useForm()
  const [updatePassword] = useUpdatePasswordMutation()
  const history = useHistory()

  const onSubmit = useCallback(async () => {
    const updatedFormState = form.getFieldsValue(true) as MypagePasswordFormState

    const { password, passPrev } = updatedFormState

    try {
      await updatePassword({ variables: { data: { password, passPrev } } })
      history.replace('/mypage/info')
      showSuccess('비밀번호를 수정하였습니다.')
    } catch (e) {
      showError(e?.message)
    }
  }, [form, updatePassword, history])

  return {
    form,
    onSubmit,
  }
}
