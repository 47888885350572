import React, { useState } from 'react'
import { Layout, Row, Col, Space, Modal, Menu } from 'antd'
import * as S from './style'
import { Link } from 'react-router-dom'
import { useUserContext } from 'src/contexts/UserContext'
import * as B from 'src/components'
import { useController } from './controller'
import { UserType } from 'src/generated/graphql'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltCircleLeft } from '@fortawesome/free-solid-svg-icons'

const { Sider } = Layout

interface Props {
  loading?: boolean
  internalServerError?: boolean
  children?: React.ReactNode
  narrow?: boolean
  bottom?: React.ReactNode
  noPadding?: boolean
  backgroundColor?: string
  noHeaderShadow?: boolean
  dark?: boolean
  width?: string
}

export const BaseTemplate = ({
  children,
  loading,
  internalServerError,
  narrow = false,
  bottom,
  noPadding = false,
  backgroundColor = '#FFFFFF',
  dark = false,
  width,
}: Props) => {
  const { visible, setVisible, handleToggle } = useController()
  const { onLogout, logged, user } = useUserContext()

  const [modalVisible, setModalVisible] = useState(false)

  const conditionalRender = () => {
    if (internalServerError) return <div></div>

    if (loading) return <div></div>

    return children
  }

  return (
    <Layout style={{ backgroundColor, ...(dark ? { backgroundColor: '#101010' } : {}) }}>
      {logged && (
        <B.Box hiddenSmDown display="flex">
          <Sider>
            <Link to="/">
              <B.Box ptb={2} mb={2}>
                <B.TextCenter>
                  <B.BaseLogo />
                </B.TextCenter>
              </B.Box>
            </Link>

            <div className="logo" />
            <Menu theme="dark" mode="inline">
              {!logged ? (
                <>
                  <Menu.Item key="2">
                    <Link to="/join">회원가입</Link>
                  </Menu.Item>
                  <Menu.Item key="3" onClick={() => setModalVisible(true)}>
                    고객센터
                  </Menu.Item>
                </>
              ) : (
                <>
                  <Menu.Item key="1:0">
                    <Link to="/" color="white">
                      홈
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="1">
                    <Link to="/message" color="white">
                      발송
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="1:1">
                    <Link to="/message/list" color="white">
                      발송 결과
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="2">
                    <Link to="/charge">충전</Link>
                  </Menu.Item>
                  <Menu.Item key="3">
                    <Link to="/mypage">개인정보</Link>
                  </Menu.Item>
                  <Menu.Item key="4" onClick={() => setModalVisible(true)}>
                    텔레그램
                  </Menu.Item>
                  {user.userType === UserType.Agency && (
                    <Menu.Item key="5">
                      <Link to="/agency">대리점관리</Link>
                    </Menu.Item>
                  )}
                  <Menu.Item key="6" onClick={() => onLogout()}>
                    로그아웃
                  </Menu.Item>
                </>
              )}
            </Menu>

            <B.TextRight>
              <Space size="large"></Space>
            </B.TextRight>
          </Sider>
        </B.Box>
      )}

      <Layout style={{ backgroundColor, ...(dark ? { backgroundColor: '#101010' } : {}) }}>
        <B.Box hiddenSmUp>

          <B.Box>
            <S.StyledHeaderBox>
                <B.TextRight>
                  <B.BaseText type="white" size={40} clickable>
                    <FontAwesomeIcon icon={faArrowAltCircleLeft} onClick={handleToggle} />
                  </B.BaseText>
                </B.TextRight>
             </S.StyledHeaderBox>
          </B.Box>
        </B.Box>

        {bottom ? <div>{bottom}</div> : null}
        <S.StyledContent
          narrow={narrow ? 'true' : undefined}
          nopadding={noPadding ? 'true' : undefined}
          width={width}
        >
          <B.Box pt={6} hiddenSmDown></B.Box>
          {conditionalRender()}
        </S.StyledContent>
        <S.StyledFooter dark={dark ? 'true' : undefined}>
          <B.Box mlr={1} mtb={1}>
            <B.BaseText type="subText" size="small">
              <Row>
                <Row>
                  <Col span="1">
                    <B.BaseText block></B.BaseText>
                  </Col>
                </Row>
              </Row>
            </B.BaseText>
          </B.Box>
        </S.StyledFooter>
      </Layout>

      <S.StyledDrawer
        visible={visible}
        onClose={() => setVisible(false)}
        closable={false}
        placement="right"
      >
         {logged ? (
          <>
            <Link to="/">
              <S.StyledDrawerItem>
                <Row justify="space-between" align="middle">
                  <span>홈</span>
                </Row>
              </S.StyledDrawerItem>
            </Link>
            <Link to="/message">
              <S.StyledDrawerItem>
                <Row justify="space-between" align="middle">
                  <span>발송</span>
                </Row>
              </S.StyledDrawerItem>
            </Link>
            <Link to="/message/list">
              <S.StyledDrawerItem>
                <Row justify="space-between" align="middle">
                  <span>발송 결과</span>
                </Row>
              </S.StyledDrawerItem>
            </Link>
            <Link to="/charge">
              <S.StyledDrawerItem>
                <Row justify="space-between" align="middle">
                  <span>충전</span>
                </Row>
              </S.StyledDrawerItem>
            </Link>
            <Link to="/mypage">
              <S.StyledDrawerItem>
                <Row justify="space-between" align="middle">
                  <span>마이페이지</span>
                </Row>
              </S.StyledDrawerItem>
            </Link>
            <S.StyledDrawerItem
              onClick={() => {
                setModalVisible(true)
              }}
            >
              <Row justify="space-between" align="middle">
                <span>고객센터</span>
              </Row>
            </S.StyledDrawerItem>
            {user.userType === UserType.Agency && (
              <Link to="/agency">
                <S.StyledDrawerItem>
                  <Row justify="space-between" align="middle">
                    <span>대리점 관리</span>
                  </Row>
                </S.StyledDrawerItem>
              </Link>
            )}
            <S.StyledDrawerItem
              onClick={() => {
                onLogout()
                setVisible(false)
              }}
            >
              <Row justify="space-between" align="middle">
                <span>로그아웃</span>
              </Row>
            </S.StyledDrawerItem>
          </>
        ) : (
          <>
          <Link to="/join">
            <S.StyledDrawerItem>
              <Row justify="space-between" align="middle">
                <span>회원가입</span>
              </Row>
            </S.StyledDrawerItem>
          </Link>
          </>
        )}
      </S.StyledDrawer>
      <Modal
        title="텔레그램"
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        cancelButtonProps={{ hidden: true }}
        okText="확인"
        onOk={() => setModalVisible(false)}
      >
        <B.BaseText>
          텔레그램으로 문의하기{' '}
          <B.BaseText size={16}>
            <img src="/telegram.png" width="20" height="20" alt="telegram" />
          </B.BaseText>{' '}
          <B.BaseText bold>텔레그램: @dorangs1</B.BaseText>
        </B.BaseText>
      </Modal>
    </Layout>
  )
}
