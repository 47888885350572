import React, { useCallback, useEffect, useState } from 'react'
import * as B from 'src/components'
import { ColumnsType } from 'antd/lib/table'
import { useMeQuery, useUsersQuery } from 'src/generated/graphql'
import { format } from 'date-fns'
import { useForm } from 'antd/lib/form/Form'
import { useUserContext } from 'src/contexts/UserContext'

export interface SearchUserFilterFormState {
  searchText: string
}

export const useController = () => {
  const [form] = useForm<SearchUserFilterFormState>()
  const { data, loading, refetch: searchUsers } = useUsersQuery({ variables: { data: {} } })
  const { user } = useUserContext()
  const { data: meData } = useMeQuery()
  const me = meData?.me

  const users = data?.users?.items ?? []
  const total = data?.users?.total ?? 0

  // 테이블 페이지네이션
  const [tablePage, setTablePage] = useState(1)

  const search = useCallback(async () => {
    const updatedFormState = form.getFieldsValue(true) as SearchUserFilterFormState
    const { searchText } = updatedFormState

    searchUsers({
      data: {
        searchText,
        creatorId: user.id,
        page: tablePage,
      },
    })
  }, [form, tablePage, searchUsers, user])

  const onChangeTablePage = useCallback((page: number) => {
    setTablePage(page)
  }, [])

  const onClickSearch = useCallback(() => {
    setTablePage(1)
    search()
  }, [search])

  // 페이지가 바뀌면 새로 불러온다
  useEffect(() => {
    search()
    // eslint-disable-next-line
  }, [tablePage])

  // 테이블 구성
  const tableColumns: ColumnsType<NonNullable<typeof users[number]>> = [
    {
      title: 'ID',
      dataIndex: 'id',
      align: 'center',
    },
    // {
    //   title: '조회',
    //   dataIndex: [],
    //   render: (user: User) => (
    //     <Link to={`/agency/user/${user.id}`}>
    //       <Button size="small">조회</Button>
    //     </Link>
    //   ),
    //   align: 'center',
    // },
    {
      title: '아이디',
      dataIndex: 'email',
      align: 'center',
    },
    {
      title: '추천인코드',
      dataIndex: ['userCode', 'content'],
      align: 'center',
    },
    {
      title: '건당 포인트',
      dataIndex: ['userCode', 'cost'],
      align: 'center',
    },
    {
      title: '잔여 포인트',
      dataIndex: 'point',
      align: 'center',
    },
    {
      title: '총 입금금액',
      dataIndex: 'sumCharged',
      align: 'center',
    },
    {
      title: '최근 접속일',
      dataIndex: 'loggedAt',
      render: (loggedAt: Date) => (
        <B.BaseText>{format(new Date(loggedAt), 'yyyy.MM.dd')}</B.BaseText>
      ),
      align: 'center',
    },
    {
      title: '생성일',
      dataIndex: 'createdAt',
      render: (createdAt: Date) => (
        <B.BaseText>{format(new Date(createdAt), 'yyyy.MM.dd')}</B.BaseText>
      ),
      align: 'center',
    },
    {
      title: '메시지 발송 수',
      dataIndex: 'sumSended',
      render: (sumSended: number) => (
        <B.BaseText>
          <B.MoneyFormat money={sumSended} />
        </B.BaseText>
      ),
      align: 'center',
    },
  ]

  return {
    me,
    users: users.map((user) => ({ ...user, key: user.id })),
    loading,
    tableColumns,
    total,
    onChangeTablePage,
    onClickSearch,
    tablePage,
    form,
  }
}
