import React from 'react'
import styled from 'styled-components'
import { Divider } from 'antd'

interface Props {
  compact?: boolean
  noMargin?: boolean
  bold?: boolean
  color?: string
  children?: React.ReactNode
}

export const BaseDivider = ({ compact, noMargin, bold, color, children }: Props) => {
  return (
    <StyledDivider
      compact={compact ? 'true' : undefined}
      nomargin={noMargin ? 'true' : undefined}
      bold={bold ? 'true' : undefined}
      color={color}
      children={children}
      plain
    />
  )
}

interface StyledProps {
  compact?: 'true'
  nomargin?: 'true'
  bold?: 'true'
  color?: string
}
const StyledDivider = styled(Divider)<StyledProps>`
  ${(props) => props.compact && 'margin: 12px 0;'}
  ${(props) => props.nomargin && 'margin: 0;'}
  ${(props) => props.bold && 'border-top-width: 3px;'}
  ${(props) =>
    props.color
      ? `border-top-color: ${props.color};`
      : `border-top-color: rgba(173, 169, 169, 0.4);`}
`
