import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
* {
  font-family: 'Noto Sans KR', sans-serif;
  padding: 0;
  margin: 0;
}

 html {
    color: ${(props) => props.theme.text};
  }

  .ant-message-notice-content{
    border-radius:50px;
    background-color: ${(props) => props.theme.card}
  }
`
