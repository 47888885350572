import { Breadcrumb } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'

interface BaseBreadcrumbItem {
  name: string | React.ReactNode
  link?: string
}

interface Props {
  items: BaseBreadcrumbItem[]
}

export const BaseBreadcrumb = ({ items }: Props) => {
  return (
    <Breadcrumb>
      {items.map((item, index) =>
        item.link ? (
          <Breadcrumb.Item key={index}>
            <Link to={item.link}>{item.name}</Link>
          </Breadcrumb.Item>
        ) : (
          <Breadcrumb.Item key={index}>{item.name}</Breadcrumb.Item>
        ),
      )}
    </Breadcrumb>
  )
}
