import React, { useContext, useEffect } from 'react'
import { createContext, useCallback, useState } from 'react'
import storage from 'src/utils/utils.storage'
import { loadUser } from 'src/services/user.service'
import { useHistory } from 'react-router-dom'
import { User, UserStatus } from 'src/generated/graphql'

interface UserContextProps {
  user: User
  onLogout: () => void
  logged: boolean
  onLogin: (user: User) => void
  loading: boolean
}

const UserContext = createContext({} as UserContextProps)

export function useUserContext() {
  return useContext(UserContext)
}

interface UserProviderProps {
  children?: React.ReactNode
}
export const UserProvider = ({ children }: UserProviderProps) => {
  const [user, setUser] = useState<User>({} as User)
  const [loading, setLoading] = useState(true)
  const [logged, setLogged] = useState(false)
  const history = useHistory()

  useEffect(() => {
    loadUser()
      .then((user) => {
        if (user) onLogin(user as User)
        setLoading(false)
      })
      .catch((e) => {
        setLoading(false)
      })
    // eslint-disable-next-line
  }, [])

  const onLogout = useCallback(() => {
    setUser({} as User)
    setLogged(false)

    storage.removeItem('access_token')

    history.replace('/')
  }, [setUser, setLogged, history])

  const onLogin = (user: User) => {
    setUser(user)
    setLogged(true)

    if (user.status === UserStatus.Ban) onLogout()
  }

  return (
    <UserContext.Provider value={{ user, logged, onLogin, onLogout, loading }}>
      {children}
    </UserContext.Provider>
  )
}
