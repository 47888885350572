import styled from 'styled-components'

export const StyledContent = styled.div`
  background: linear-gradient(-45deg, #000000, #f7f7f7, #000000, #f7f7f7, #000000);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`

export const StyledBackgroundImageWrapper = styled.div`
  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: url(/back.png);
    background-size: cover;
    background-position: center;
    opacity: 0.05;
  }
`

export const StyledDecorationCard = styled.div`
  background-color: ${(props) => props.theme.text};
  display: flex;
  height: 100%;
`
