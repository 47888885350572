import * as B from 'src/components'
import * as S from './style'
import { Form, Button, Tabs } from 'antd'
import { useController } from './controller'
import { Link } from 'react-router-dom'

const { TabPane } = Tabs

const MypagePage = () => {
  const {
    me,
    user,
    pointLogs,
    userChargesTotal,
    userCharges,
    pointTablePage,
    pointTableColumns,
    pointLogsTotal,
    pointLoading,
    onChangePointTablePage,
    onChangeChargeTablePage,
    chargeTablePage,
    chargeTableColumns,
    chargeLoading,
  } = useController()

  const conditionalRender = () => {
    if (!me) return null

    return (
      <S.StyledTabs defaultActiveKey="info" type="card" tabPosition="left">
        <TabPane tab="기본 정보" key="info">
          <B.BaseCard>
            <B.Box mb={2}>
              <B.BaseText block>
                <B.BaseText bold>{user.name}</B.BaseText>님 안녕하세요.
              </B.BaseText>
              <B.BaseText>개인정보입니다.</B.BaseText>
            </B.Box>
            <B.Box mb={2}>
              <B.BaseTable
                size="small"
                bordered
                pagination={{ hideOnSinglePage: true }}
                columns={[
                  {
                    title: '잔여 금액',
                    dataIndex: 'point',
                    align: 'center',
                  },
                  {
                    title: '건당 금액',
                    dataIndex: 'cost',
                    align: 'center',
                  },
                  {
                    title: '발송 가능 건수',
                    dataIndex: 'available',
                    align: 'center',
                  },
                ]}
                dataSource={[
                  {
                    key: 1,
                    code: me.userCode?.content,
                    point: me.point,
                    cost: me.userCode?.cost ?? 0,
                    available:
                      (me.userCode?.cost ?? 0) === 0
                        ? 0
                        : Math.floor(me.point / (me.userCode?.cost ?? 0)),
                  },
                ]}
              />
            </B.Box>
            
            <Form.Item label="개인정보변경">
              <Link to="/mypage/password">
                <Button size="middle">비밀번호 변경</Button>
              </Link>
            </Form.Item>
          </B.BaseCard>
        </TabPane>
        <TabPane tab="문자 이용 내역" key="point">
          <B.BaseCard>
            <B.BaseTable
              size="small"
              columns={pointTableColumns}
              dataSource={pointLogs}
              loading={pointLoading}
              bordered
              pagination={{
                current: pointTablePage,
                pageSize: 20,
                total: pointLogsTotal,
                onChange: onChangePointTablePage,
              }}
            />
          </B.BaseCard>
        </TabPane>
        <TabPane tab="입금 총 내역" key="charge">
          <B.BaseCard>
            <B.BaseTable
              size="small"
              columns={chargeTableColumns}
              dataSource={userCharges}
              loading={chargeLoading}
              bordered
              pagination={{
                current: chargeTablePage,
                pageSize: 20,
                total: userChargesTotal,
                onChange: onChangeChargeTablePage,
              }}
            />
          </B.BaseCard>
        </TabPane>
      </S.StyledTabs>
    )
  }

  return (
    <B.BaseTemplate>
      <B.Box mb={4}>
        <B.BaseTitle level={3}>개인정보 페이지</B.BaseTitle>

        <B.BaseText type="subText">
          <B.BaseText block>
            <B.BaseText bold>{user.name}</B.BaseText>님 안녕하세요.
          </B.BaseText>
        </B.BaseText>
      </B.Box>
      {conditionalRender()}
    </B.BaseTemplate>
  )
}

export default MypagePage
