import styled from 'styled-components'

export const SectionWholeContentWrapper = styled.div`
  position: relative;
`

export const SectionBackground = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  background-size: cover;
`

export const SectionWholeContent = styled.div`
  position: relative;
  width: 900px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
`

export const SectionWrapper = styled.section`
  min-height: 1vh;
`

export const SectionWrapperCenter = styled(SectionWrapper)`
  display: flex;
  justify-content: center;
`

export const StyledLaptopImage = styled.img`
  width: 100%;
  max-width: 400px;
`

export const StyledContent = styled.div`
  background: linear-gradient(-45deg, #000000, #f7f7f7, #000000, #f7f7f7, #000000);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`

export const StyledBackgroundImageWrapper = styled.div`
  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: url(/back.png);
    background-size: cover;
    background-position: center;
    opacity: 0.05;
  }
`
