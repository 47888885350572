import { Form, Button, Tooltip, Input, Row, Col, Pagination, message as msg, Select } from 'antd'
import * as B from 'src/components'
import * as S from './style'
import { useController } from './controller'
import { MessageTelUpload } from './presentations/MessageTelUpload'
import { MessageTrafficLight } from './presentations/MessageTrafficLight'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMailBulk } from '@fortawesome/free-solid-svg-icons'
import CopyToClipboard from 'react-copy-to-clipboard'
import theme from 'src/styles/theme'

import styled from 'styled-components'
import { useState } from 'react'
import FixedNumberForm from './FixedNumberForm'

const { TextArea } = Input

const MessagePage = () => {
  const {
    form,
    onSubmit,
    me,
    possibleSendNum,
    matchValidatedTels,
    convertToArrayTels,
    onChangeTablePage,
    tablePage,
    messagesTotal,
    messages,
    trafficLightPercent,
    onClickTestSend,
    onChangeUploadTel,
    onClickRemoveDuplicatedTels,
    tabIndex,
    setTabIndex,
    isReplaceMode,
    onClickSubmitButton,
  } = useController()
  const [isFixedNumber, setIsFixedNumber] = useState(false)
  const [isValidFixedNumber, setIsValidFixedNumber] = useState(false)

  const countFunction = (tels: string[]) => {
    if (tels.length > 10000) {
      const telElement = document.getElementById('tel') as HTMLTextAreaElement
      telElement.value = tels.slice(0, 9999).join('\n')
    }

    onClickSubmitButton()
  }

  const conditionalRender = () => {
    if (!me) return null

    const maximumContent = isReplaceMode ? 65 : 70
    return (
      <B.BaseForm form={form} layout="vertical" onFinish={() => onSubmit(false)}>
        <Row gutter={20} style={{ flexWrap: 'nowrap' }}>
          <S.StyledRightCol>
            <Label htmlFor="saveMsg">
              <CheckBox id="saveMsg" type="checkbox" />
              메시지 고정하기
            </Label>
            <S.StyledIPhone src="/galaxy.png" style={{ position: 'relative' }} />
            <S.StyledMessageTextAreaBox>
              <Form.Item
                name="content"
                label="내용"
                labelCol={{ span: 0 }}
                rules={[{ required: true }]}
              >
                <textarea
                  style={{ width: '100%' }}
                  maxLength={maximumContent}
                  placeholder="발송하실 내용을 입력하세요."
                  rows={20}
                />
              </Form.Item>
              <Form.Item
                shouldUpdate={(prev, curr) => prev.content !== curr.content}
                style={{ position: 'relative', top: '-60px' }}
              >
                {({ getFieldValue }) => {
                  const content = getFieldValue('content') ?? ''
                  const length = Math.min(content.length, maximumContent)

                  return (
                    <B.TextRight>
                      <B.BaseText type={length === maximumContent ? 'danger' : 'text'} mr={0.5}>
                        {length}/{maximumContent}
                      </B.BaseText>
                    </B.TextRight>
                  )
                }}
              </Form.Item>
            </S.StyledMessageTextAreaBox>
          </S.StyledRightCol>
          <S.StyledLeftCol>
            <B.Box display="flex" align="center" mb={2}>
              <B.Box mr={1.5}>발신번호 설정:</B.Box>
              <Form.Item name="sendType" rules={[{ required: true }]} noStyle>
                <Select<'random' | 'fixed'>
                  defaultValue={'random'}
                  onChange={(value) => {
                    setIsFixedNumber(value === 'fixed')
                  }}
                >
                  <Select.Option value="random">랜덤</Select.Option>
                  <Select.Option value="fixed">고정</Select.Option>
                </Select>
              </Form.Item>
              {isFixedNumber && <FixedNumberForm onValidateUpdate={setIsValidFixedNumber} />}
            </B.Box>
            <B.Box display="flex">
              <S.StyledTabs
                type="card"
                activeKey={tabIndex}
                tabPosition="left"
                onChange={(activeKey) => {
                  setTabIndex(activeKey)
                }}
              >
                <S.StyledTabPane tab="일반 발송" key="0"></S.StyledTabPane>
                <S.StyledTabPane tab="치환 발송" key="1"></S.StyledTabPane>
              </S.StyledTabs>
              <B.Box style={{ flex: '1 1 auto' }}>
                <B.BaseCard noPadding>
                  <B.Box style={{ height: 2, backgroundColor: theme.primaryLight }}></B.Box>
                  <B.Box style={{ height: 3, backgroundColor: theme.primary }}></B.Box>

                  <B.Box p={2}>
                    <B.Box mb={1}>
                      <Row>
                        <Col>
                          <B.BaseText pr={1}>문자 발송 건수</B.BaseText>
                        </Col>
                        <Col>
                          <B.BaseText bold>
                            <B.MoneyFormat money={possibleSendNum} />
                          </B.BaseText>
                          건
                        </Col>
                      </Row>
                    </B.Box>
                    <B.Box mb={1}>
                      <Row>
                        <Col>
                          <B.BaseText bold>이용해주셔서 감사합니다.</B.BaseText>
                        </Col>
                      </Row>
                    </B.Box>
                    <B.Box hiddenSmUp>
                      <Form.Item name="content" label="내용" rules={[{ required: true }]}>
                        <TextArea
                          placeholder="이곳에 문자 메시지 내용을 입력해주세요."
                          maxLength={maximumContent}
                          rows={6}
                          style={{ resize: 'none' }}
                        />
                      </Form.Item>
                    </B.Box>

                    <Form.Item shouldUpdate={(prev, curr) => prev.tel !== curr.tel} noStyle>
                      {({ getFieldValue }) => {
                        const tel: string = getFieldValue('tel') ?? ''

                        const validTels = matchValidatedTels(convertToArrayTels(tel))

                        // 전화번호 개수 구하기 (현재는 라인 수로 분할하여 계산)
                        const telCount = validTels.length

                        return (
                          <S.StyledFullWidthFormItem
                            name="tel"
                            label={
                              <>
                                <B.Box>
                                  <B.Box mb={1}>
                                    <Tooltip title="지원 가능 형식 = 010XXXXXXXX, 10XXXXXXXX, 010-XXXX-XXXX.">
                                      <B.BaseText mr={1}>
                                        기입된 번호 갯수{' '}
                                        <B.BaseText type="primary" bold>
                                          <B.MoneyFormat money={telCount} />
                                        </B.BaseText>
                                        개 (지원 형식 = 010XXXXXXXX, 10XXXXXXXX, 010-XXXX-XXXX.)
                                      </B.BaseText>
                                    </Tooltip>
                                  </B.Box>
                                  <B.Box>
                                    {isReplaceMode ? (
                                      <B.Box hiddenSmDown>
                                        <a
                                          href="https://blacklabel-object.s3.ap-northeast-2.amazonaws.com/sampleTel.xlsx"
                                          rel="noopener noreferrer"
                                          target="_blank"
                                        >
                                          <Button size="middle" type="dashed">
                                            엑셀 (XLSX) 샘플
                                          </Button>
                                        </a>
                                      </B.Box>
                                    ) : (
                                      <B.Box hiddenSmDown>
                                        <a
                                          href="https://100per-object.s3.ap-northeast-2.amazonaws.com/sample.txt"
                                          rel="noopener noreferrer"
                                          target="_blank"
                                        >
                                          <Button size="middle" type="dashed">
                                            텍스트 (TXT) 샘플
                                          </Button>
                                        </a>
                                        <a
                                          href="https://100per-object.s3.ap-northeast-2.amazonaws.com/sample.csv"
                                          rel="noopener noreferrer"
                                          target="_blank"
                                        >
                                          <Button size="middle" type="dashed">
                                            CSV 샘플
                                          </Button>
                                        </a>
                                      </B.Box>
                                    )}
                                  </B.Box>
                                </B.Box>
                                {isReplaceMode ? (
                                  <B.Box hiddenSmUp>
                                    <a
                                      href="https://blacklabel-object.s3.ap-northeast-2.amazonaws.com/sampleTel.xlsx"
                                      rel="noopener noreferrer"
                                      target="_blank"
                                    >
                                      <Button size="middle" type="dashed">
                                        엑셀 (XLSX) 샘플
                                      </Button>
                                    </a>
                                  </B.Box>
                                ) : (
                                  <B.Box hiddenSmUp>
                                    <a
                                      href="https://100per-object.s3.ap-northeast-2.amazonaws.com/sample.txt"
                                      rel="noopener noreferrer"
                                      target="_blank"
                                    >
                                      <Button size="middle" type="dashed">
                                        텍스트 파일 샘플
                                      </Button>
                                    </a>
                                    <a
                                      href="https://100per-object.s3.ap-northeast-2.amazonaws.com/sample.csv"
                                      rel="noopener noreferrer"
                                      target="_blank"
                                    >
                                      <Button size="middle" type="dashed">
                                        CSV 샘플
                                      </Button>
                                    </a>
                                  </B.Box>
                                )}
                              </>
                            }
                          >
                            <TextArea
                              placeholder={
                                isReplaceMode
                                  ? '수신 번호와 이름을 입력해주세요. 예시) 010XXXXXXXX 김개똥'
                                  : '수신 번호를 양식에 맞게 업로드하여주시거나 전화번호를 직접 입력해주세요.'
                              }
                              rows={6}
                              style={{ resize: 'none' }}
                            />
                          </S.StyledFullWidthFormItem>
                        )
                      }}
                    </Form.Item>

                    <B.Box mb={2}>
                      <B.Box display="flex">
                        <B.Box style={{ flex: '1 1 auto' }} pr={1}>
                          <Button block onClick={onClickRemoveDuplicatedTels} shape="round" danger>
                            중복된 번호 없애기
                          </Button>
                        </B.Box>

                        <B.Box style={{ flex: '1 1 auto' }}>
                          <Tooltip title="파일을 업로드하여 전화번호를 입력합니다.">
                            <MessageTelUpload onChange={onChangeUploadTel} xlsx={isReplaceMode} />
                          </Tooltip>
                        </B.Box>
                      </B.Box>
                    </B.Box>

                    <B.Box mb={1}>
                      <Button block onClick={onClickTestSend} shape="round">
                        KT,SK,LG 3사 테스트
                      </Button>
                    </B.Box>

                    <B.Box>
                      <B.TextCenter>
                        <Form.Item shouldUpdate={(prev, curr) => prev.tel !== curr.tel} noStyle>
                          {({ getFieldValue }) => {
                            const tel: string = getFieldValue('tel') ?? ''

                            const validTels = matchValidatedTels(convertToArrayTels(tel))
                            const telCount = validTels.length

                            return (
                              <Button
                                shape="round"
                                type="primary"
                                onClick={() => {
                                  countFunction(validTels)
                                }}
                                block
                                disabled={
                                  telCount === 0 ||
                                  (isFixedNumber === true && isValidFixedNumber === false)
                                }
                              >
                                <B.MoneyFormat money={telCount} />
                                건의 문자 발송하기
                              </Button>
                            )
                          }}
                        </Form.Item>
                      </B.TextCenter>
                    </B.Box>
                  </B.Box>
                </B.BaseCard>
                <B.Box></B.Box>
              </B.Box>
            </B.Box>
            <B.Box p={2}>
              <B.Box mb={1}>
                <B.Box display="flex" justify="space-between">
                  <B.Box></B.Box>
                  <B.Box>
                    <Row justify="space-between" gutter={20} align="middle">
                      <Col>현재 발송 현황</Col>
                      <Col flex="1 1 auto">
                        <MessageTrafficLight percent={trafficLightPercent} />
                      </Col>
                    </Row>
                  </B.Box>
                </B.Box>
              </B.Box>
            </B.Box>
          </S.StyledLeftCol>
        </Row>
      </B.BaseForm>
    )
  }
  return (
    <B.BaseTemplate width="1200px">
      <B.Box mb={4}>
        <B.BaseText size={48}>
          <FontAwesomeIcon icon={faMailBulk} />
        </B.BaseText>

        <B.BaseTitle level={3}>문자 발송 하기</B.BaseTitle>

        <B.BaseText type="subText">
          <B.BaseText block>발송 시, 예시 샘플을 참고로 발송해주세요</B.BaseText>
          <B.BaseText block mb={1}>
            기타 문의사항은 빠른 상담을 위해 텔레그램으로 문의주세요
          </B.BaseText>
          <B.BaseText block>
            <img src="/telegram.png" width="24" height="24" alt="telegram" />
            <B.BaseText pl={1}>문의사항: 텔레그램 @dorangs1</B.BaseText>
          </B.BaseText>
        </B.BaseText>
      </B.Box>

      {conditionalRender()}

      <B.Box mt={4}>
        <B.BaseTitle level={4}>메시지 복사하기</B.BaseTitle>
        <B.Box
          display="flex"
          style={{
            height: '500px',
            flexDirection: 'column',
            overflowY: 'scroll',
            margin: '15px 0',
          }}
        >
          {messages.map((message) => (
            <CopyToClipboard text={message.content} key={message.id}>
              <B.Box
                style={{
                  marginRight: 20,
                  marginBottom: 20,
                  width: '100%',
                  height: 200,
                  position: 'relative',
                }}
                onClick={() => msg.success('메시지를 클립보드에 복사했습니다.')}
              >
                <B.BaseCard clickable style={{ height: '100%' }}>
                  <B.Box>
                    <B.BaseText>{message.content}</B.BaseText>
                  </B.Box>
                </B.BaseCard>
                <B.Box style={{ position: 'absolute', right: 20, bottom: 20 }}>
                  <B.BaseText size="small" type="subText">
                    복사
                  </B.BaseText>
                </B.Box>
              </B.Box>
            </CopyToClipboard>
          ))}
        </B.Box>

        {messagesTotal > 0 && (
          <Pagination
            current={tablePage}
            pageSize={20}
            total={messagesTotal}
            onChange={onChangeTablePage}
          ></Pagination>
        )}
      </B.Box>
    </B.BaseTemplate>
  )
}

const Label = styled.label`
  display: flex;
  align-items: center;
  margin-left: 10px;
`

const CheckBox = styled.input`
  width: 12px;
  height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
`

export default MessagePage
