import { Button, Input, Skeleton, message } from 'antd'
import { useState } from 'react'
import { Box } from 'src/components'
import { useMySendNumberQuery, useUpdateSendNumberMutation } from 'src/generated/graphql'

interface Props {
  onValidateUpdate: (status: boolean) => void
}

const style = { width: '300px', marginLeft: '10px' }

const FixedNumberForm = ({ onValidateUpdate }: Props) => {
  const [value, setValue] = useState('')
  const [isModify, setIsModify] = useState(true)

  const { data, loading } = useMySendNumberQuery({
    onCompleted: (data) => {
      setValue(data.me?.sendNumber || '')
    },
  })

  const [update, { loading: updateLoading }] = useUpdateSendNumberMutation()

  const handleClick = async () => {
    if (isModify) {
      if (!/^\d{1,11}$/.test(value)) {
        message.error('숫자만 입력 가능하며 최대 11자리까지 입력할 수 있습니다.')
        return
      }
      // validate
      await update({
        variables: {
          data: {
            sendNumber: value,
          },
        },
      })
      setIsModify(false)
      onValidateUpdate(true)
      return
    }

    setIsModify(true)
    onValidateUpdate(false)
  }

  if (loading) {
    return <Skeleton.Input style={style} />
  }

  return (
    <Box display="flex" align="center">
      <Input
        placeholder="번호를 입력해주세요."
        disabled={!isModify}
        style={style}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
      <Button onClick={handleClick} style={{ marginLeft: '10px' }} disabled={updateLoading}>
        {isModify ? '확인' : '수정'}
      </Button>
    </Box>
  )
}

export default FixedNumberForm
