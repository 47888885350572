import { HomeOutlined } from '@ant-design/icons'
import { Table } from 'antd'
import React from 'react'
import * as B from 'src/components'
import { useController } from './controller'

const AdminPointLogPage = () => {
  const { pointLogs, total, tableColumns, loading, onChangeTablePage, tablePage } = useController()

  return (
    <div>
      <B.Box mb={4}>
        <B.BaseBreadcrumb
          items={[
            { name: <HomeOutlined />, link: '/admin' },
            { name: '포인트 관리', link: '/admin/point' },
            { name: '포인트 지급&회수 내역' },
          ]}
        />
      </B.Box>

      <B.BaseTitle level={3}>포인트 지급&회수 내역</B.BaseTitle>

      <Table
        size="small"
        columns={tableColumns}
        dataSource={pointLogs}
        loading={loading}
        bordered
        pagination={{
          current: tablePage,
          pageSize: 20,
          total: total,
          onChange: onChangeTablePage,
        }}
      />
    </div>
  )
}

export default AdminPointLogPage
