import React from 'react'
import * as B from 'src/components'
import { Button, Table } from 'antd'
import { HomeOutlined } from '@ant-design/icons'
import { useController } from './controller'
import { Link } from 'react-router-dom'

const AdminNoticePage = () => {
  const { notices, loading, tableColumns, total, tablePage, onChangeTablePage } = useController()

  return (
    <div>
      <B.Box mb={4}>
        <B.BaseBreadcrumb
          items={[{ name: <HomeOutlined />, link: '/admin' }, { name: '팝업 목록' }]}
        />
      </B.Box>

      <B.BaseCard
        title={
          <>
            <B.BaseText mr={2}>팝업 목록</B.BaseText>
            <Link to="/admin/notice/write">
              <Button>팝업 생성</Button>
            </Link>
          </>
        }
      >
        <Table
          size="small"
          columns={tableColumns}
          dataSource={notices}
          loading={loading}
          bordered
          pagination={{
            current: tablePage,
            pageSize: 20,
            total: total,
            onChange: onChangeTablePage,
          }}
        />
      </B.BaseCard>
    </div>
  )
}

export default AdminNoticePage
