import { ColumnsType } from 'antd/lib/table'
import { format } from 'date-fns'
import React, { useCallback, useEffect, useState } from 'react'
import * as B from 'src/components'
import { useUserContext } from 'src/contexts/UserContext'
import {
  useMeQuery,
  usePointLogsQuery,
  UserChargeStatus,
  useUserChargesQuery,
} from 'src/generated/graphql'

export const useController = () => {
  const { user } = useUserContext()
  const { data: meData } = useMeQuery()
  const me = meData?.me
  const { data: pointLogsData, refetch: pointRefetch, loading: pointLoading } = usePointLogsQuery({
    variables: { data: { userId: user.id } },
  })
  const {
    data: userChargesData,
    refetch: chargeRefetch,
    loading: chargeLoading,
  } = useUserChargesQuery({
    variables: { data: { userId: user.id } },
  })

  const pointLogs = pointLogsData?.pointLogs?.items ?? []
  const pointLogsTotal = pointLogsData?.pointLogs?.total ?? 0

  const userCharges = userChargesData?.userCharges?.items ?? []
  const userChargesTotal = userChargesData?.userCharges?.total ?? 0

  // 테이블 페이지네이션
  const [pointTablePage, setPointTablePage] = useState(1)

  const onChangePointTablePage = useCallback((page: number) => {
    setPointTablePage(page)
  }, [])

  const [chargeTablePage, setChargeTablePage] = useState(1)

  const onChangeChargeTablePage = useCallback((page: number) => {
    setChargeTablePage(page)
  }, [])

  // 페이지가 바뀌면 새로 불러온다
  useEffect(() => {
    pointRefetch({ data: { page: pointTablePage, userId: user.id } })
    // eslint-disable-next-line
  }, [pointTablePage])

  // 페이지가 바뀌면 새로 불러온다
  useEffect(() => {
    chargeRefetch({ data: { userId: user.id, page: chargeTablePage } })
    // eslint-disable-next-line
  }, [chargeTablePage])

  // 테이블 구성
  const pointTableColumns: ColumnsType<NonNullable<typeof pointLogs[number]>> = [
    {
      title: '생성일',
      dataIndex: 'createdAt',
      render: (createdAt: Date) => (
        <B.BaseText>{format(new Date(createdAt), 'yyyy.MM.dd')}</B.BaseText>
      ),
      align: 'center',
    },
    {
      title: '타입',
      dataIndex: ['pointLogType', 'content'],
      align: 'center',
    },
    {
      title: '금액 변동',
      dataIndex: 'point',
      align: 'center',
    },
    {
      title: '남은 금액',
      dataIndex: 'restPoint',
      align: 'center',
    },
  ]

  // 테이블 구성
  const chargeTableColumns: ColumnsType<NonNullable<typeof userCharges[number]>> = [
    {
      title: '생성일',
      dataIndex: 'createdAt',
      render: (createdAt: Date) => (
        <B.BaseText>{format(new Date(createdAt), 'yyyy.MM.dd')}</B.BaseText>
      ),
      align: 'center',
    },
    {
      title: '충전자명',
      dataIndex: 'name',
      align: 'center',
    },
    {
      title: '충전 금액',
      dataIndex: 'money',
      align: 'center',
    },
    {
      title: '수락/거절 여부',
      dataIndex: 'status',
      render: (status: UserChargeStatus) =>
        status === UserChargeStatus.Ready ? (
          <B.BaseText type="subText">대기</B.BaseText>
        ) : status === UserChargeStatus.Applied ? (
          <B.BaseText type="primary">수락</B.BaseText>
        ) : (
          <B.BaseText type="danger">거절</B.BaseText>
        ),
      align: 'center',
    },
  ]

  return {
    me,
    user,
    userCharges: userCharges.map((userCharge) => ({ ...userCharge, key: userCharge.id })),
    userChargesTotal,
    chargeTableColumns,
    chargeLoading,
    chargeTablePage,
    onChangeChargeTablePage,

    pointLogs: pointLogs.map((pointLog) => ({ ...pointLog, key: pointLog.id })),
    pointLogsTotal,
    pointTableColumns,
    pointLoading,
    pointTablePage,
    onChangePointTablePage,
  }
}
