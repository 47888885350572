import { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { CreateUserInput, useCreateUserMutation } from 'src/generated/graphql'
import { useForm } from 'antd/lib/form/Form'
import { showError, showSuccess } from 'src/utils'

export type JoinFormState = { passConf: string } & CreateUserInput

export const useController = () => {
  const history = useHistory()

  const initialValues = useMemo<JoinFormState>(
    () => ({
      name: '',
      email: '',
      password: '',
      passConf: '',
      code: '',
    }),
    [],
  )
  const [form] = useForm()

  const [createUser] = useCreateUserMutation()

  const onSubmit = useCallback(async () => {
    const updatedFormState = form.getFieldsValue(true) as JoinFormState
    try {
      await createUser({
        variables: {
          data: {
            name: updatedFormState.name,
            email: updatedFormState.email,
            password: updatedFormState.password,
            code: updatedFormState.code,
          },
        },
      })
      showSuccess('회원가입 성공')
      history.replace('/')
    } catch (e) {
      showError(e?.message)
    }
  }, [createUser, history, form])

  return {
    initialValues,
    form,
    onSubmit,
  }
}
