import { useCallback, useState } from 'react'
import { SearchUserFilterFormState } from '../../User/controller'

import { ColumnsType } from 'antd/lib/table'
import {
  User,
  useGiveAgencyPointToUserMutation,
  useUsersQuery,
  AgencyPointType,
} from 'src/generated/graphql'
import { useForm } from 'antd/lib/form/Form'
import { Button } from 'antd'
import { showError, showSuccess } from 'src/utils'

export interface GiveAgencyPointToUserFormState {
  point: number
  type: AgencyPointType
}

export const useController = () => {
  const [searchForm] = useForm<SearchUserFilterFormState>()
  const [form] = useForm<GiveAgencyPointToUserFormState>()
  const { data, loading, refetch: searchUsers } = useUsersQuery({
    variables: { data: { agency: true } },
  })
  const [selectedUser, setSelectedUser] = useState<User | null>(null)
  const [giveAgencyPointToUser] = useGiveAgencyPointToUserMutation()

  const users = data?.users?.items ?? []

  const search = useCallback(async () => {
    const updatedFormState = searchForm.getFieldsValue(true) as SearchUserFilterFormState
    const { searchText } = updatedFormState

    searchUsers({
      data: {
        searchText,
        agency: true,
      },
    })
  }, [searchForm, searchUsers])

  const onClickSearch = useCallback(() => {
    search()
  }, [search])

  const onSubmit = useCallback(async () => {
    const updatedFormState = form.getFieldsValue(true) as GiveAgencyPointToUserFormState

    const { point, type } = updatedFormState

    if (!selectedUser) return

    try {
      await giveAgencyPointToUser({ variables: { data: { point, type, userId: selectedUser.id } } })
      showSuccess('정산포인트를 회수했습니다.')
      form.setFieldsValue({ point: undefined, type: AgencyPointType.Point })
      search()
    } catch (e) {
      showError(e?.message)
    }
  }, [form, selectedUser, giveAgencyPointToUser, search])

  // 테이블 구성
  const tableColumns: ColumnsType<NonNullable<typeof users[number]>> = [
    {
      title: '선택',
      dataIndex: [],
      render: (user: User) => (
        <Button size="small" type="primary" onClick={() => setSelectedUser(user)}>
          선택
        </Button>
      ),
      align: 'center',
    },
    {
      title: 'ID',
      dataIndex: 'id',
      align: 'center',
    },
    {
      title: '아이디',
      dataIndex: 'email',
      align: 'center',
    },
    {
      title: '건당 포인트',
      dataIndex: ['userCode', 'cost'],
      align: 'center',
    },
    {
      title: '잔여 포인트',
      dataIndex: 'point',
      align: 'center',
    },
    {
      title: '정산 포인트',
      dataIndex: 'agencyPoint',
      align: 'center',
    },
  ]

  return {
    onClickSearch,
    searchForm,
    users: users.map((user) => ({ ...user, key: user.id })),
    loading,
    tableColumns,
    selectedUser,
    form,
    onSubmit,
  }
}
