import React from 'react'
import { PublicRoute } from 'src/components/routes/PublicRoute'
import { Switch, useRouteMatch } from 'react-router-dom'
import AdminNoticePage from '.'
import AdminNoticeWirtePage from './Write'

const AdminNoticeRoutes = () => {
  const { path } = useRouteMatch()
  return (
    <Switch>
      <PublicRoute path={`${path}/write/:id`} component={AdminNoticeWirtePage} />
      <PublicRoute path={`${path}/write`} component={AdminNoticeWirtePage} />
      <PublicRoute path={`${path}/`} component={AdminNoticePage} />
    </Switch>
  )
}

export default AdminNoticeRoutes
