import React, { useCallback, useEffect } from 'react'
import { Button } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { ColumnsType } from 'antd/lib/table'
import {
  TestTel,
  TestTelType,
  useCreateTestTelMutation,
  useDeleteTestTelMutation,
  useIsUnderInspectionQuery,
  useTestTelsQuery,
  useUpdateUnderInspectionMutation,
} from 'src/generated/graphql'
import { showError, showSuccess } from 'src/utils'

export interface CreateTestTelFormState {
  tel: string
  type: TestTelType
}

export interface UpdateUnderInspectionFormState {
  underInspection: boolean
}

export const useController = () => {
  const { data: testTelsData, loading, refetch } = useTestTelsQuery()
  const [createTestTel] = useCreateTestTelMutation()
  const [deleteTestTel] = useDeleteTestTelMutation()
  const [form] = useForm()
  const [underInspectionForm] = useForm()
  const [updateUnderInspection] = useUpdateUnderInspectionMutation()
  const { data: underInspectionData } = useIsUnderInspectionQuery()

  const isUnderInspection = underInspectionData?.isUnderInspection ?? false

  const testTels = testTelsData?.testTels.items ?? []

  useEffect(() => {
    if (underInspectionData)
      underInspectionForm.setFieldsValue({ underInspection: isUnderInspection })
    // eslint-disable-next-line
  }, [underInspectionData])

  const onSubmit = useCallback(async () => {
    const updatedFormState = form.getFieldsValue(true) as CreateTestTelFormState

    const { tel, type } = updatedFormState

    try {
      await createTestTel({ variables: { data: { tel, type } } })
      refetch()
      form.setFieldsValue({ tel: undefined, type: undefined })
    } catch (e) {
      showError(e?.message)
    }
  }, [form, createTestTel, refetch])

  const onSubmitConfig = useCallback(async () => {
    const updatedFormState = underInspectionForm.getFieldsValue(
      true,
    ) as UpdateUnderInspectionFormState

    const { underInspection } = updatedFormState

    try {
      await updateUnderInspection({ variables: { underInspection } })
      showSuccess('사이트 운영을 업데이트 하였습니다')
    } catch (e) {
      showError(e?.message)
    }
  }, [underInspectionForm, updateUnderInspection])

  const onClickDeleteTestTel = useCallback(
    async (testTel: TestTel) => {
      try {
        await deleteTestTel({ variables: { id: testTel.id } })
        refetch()
      } catch (e) {
        showError(e?.message)
      }
    },
    [deleteTestTel, refetch],
  )

  // 테이블 구성
  const tableColumns: ColumnsType<NonNullable<typeof testTels[number]>> = [
    {
      title: '전화번호',
      dataIndex: 'tel',
      align: 'center',
    },
    {
      title: '통신사',
      dataIndex: 'type',
      align: 'center',
    },
    {
      title: '관리',
      dataIndex: [],
      align: 'center',
      render: (testTel: TestTel) => {
        return (
          <Button danger onClick={() => onClickDeleteTestTel(testTel)}>
            삭제
          </Button>
        )
      },
    },
  ]

  return {
    onSubmit,
    onSubmitConfig,
    loading,
    testTels: testTels.map((testTel) => ({ ...testTel, key: testTel.id })),
    form,
    underInspectionForm,
    tableColumns,
    isUnderInspection,
  }
}
