import { Space, Button, Form, Input } from 'antd'
import React from 'react'
import * as B from 'src/components'
import theme from 'src/styles/theme'
import RulesValidator from 'src/utils/utils.rules.validator'
import { useController } from './controller'

const MypagePasswordPage = () => {
  const { form, onSubmit } = useController()
  return (
    <B.BaseTemplate narrow backgroundColor={theme.card} noPadding>
      <B.Box mtb={2}>
        <B.TextCenter>
          <B.BaseTitle level={3}>비밀번호 변경</B.BaseTitle>
        </B.TextCenter>
      </B.Box>

      <B.BaseForm form={form} onFinish={onSubmit}>
        <Space direction="vertical" style={{ width: '100%' }}>
          <B.BaseCard>
            <Form.Item
              name="passPrev"
              label="기존 비밀번호"
              rules={[{ required: true }]}
              hasFeedback
            >
              <Input.Password placeholder="기존 비밀번호" />
            </Form.Item>
            <Form.Item
              name="password"
              label="새 비밀번호"
              rules={[
                () => ({
                  validator: (rule, value) => {
                    if (!RulesValidator.isValidPassword(value))
                      return Promise.reject('비밀번호는 영문, 숫자 포함 8자 이상으로 입력해주세요.')
                    return Promise.resolve()
                  },
                }),
                { required: true },
              ]}
              hasFeedback
            >
              <Input.Password placeholder="비밀번호 (영문, 숫자 포함 8자 이상)" />
            </Form.Item>

            <Form.Item
              name="passConf"
              label="새 비밀번호 확인"
              dependencies={['password']}
              rules={[
                { required: true },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue('password') === value) return Promise.resolve()
                    return Promise.reject('비밀번호가 일치하지 않습니다.')
                  },
                }),
              ]}
              hasFeedback
            >
              <Input.Password placeholder="비밀번호 확인" />
            </Form.Item>

            <B.Box>
              <Button block type="primary" htmlType="submit">
                수정
              </Button>
            </B.Box>
          </B.BaseCard>
        </Space>
      </B.BaseForm>
    </B.BaseTemplate>
  )
}

export default MypagePasswordPage
