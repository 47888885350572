import React from 'react'

interface Props {
  money: number
}

export const MoneyFormat = ({ money }: Props) => {
  const moneyFormat = money.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return <span>{moneyFormat}</span>
}
