import styled from 'styled-components'

export const FluorescentUnderline = styled.div`
  display: flex;
  align-items: flex-end;
  position: relative;
  padding: 0 5px;

  && > * {
    position: relative;
    margin: 0;
    margin-bottom: 0;
  }

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 12px;
    background-color: #c5d8ef;
  }
`
