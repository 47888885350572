import styled from 'styled-components'

interface Props {
  height?: number
}
export const BaseLogo = styled.img.attrs((props) => ({
  alt: '로고 이미지',
  src: '/logo.png',
}))<Props>`
  height: ${(props) => (props.height ? `${props.height}px` : `50px`)};
`
