import React from 'react'
import { Card } from 'antd'
import styled from 'styled-components'
import { CardProps } from 'antd/lib/card'

interface Props extends CardProps {
  noPadding?: boolean
  children?: React.ReactNode
  clickable?: boolean
  title?: React.ReactNode
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

export const BaseCard = ({ noPadding, children, clickable, onClick, title, ...rest }: Props) => {
  return (
    <StyledCard
      title={title}
      onClick={onClick}
      nopadding={noPadding ? 'true' : undefined}
      clickable={clickable ? 'true' : undefined}
      children={children}
      {...rest}
    />
  )
}

interface StyledProps {
  nopadding?: 'true'
  clickable?: 'true'
}

const StyledCard = styled(Card)<StyledProps>`
  width: 100%;
  && {
    .ant-card-body {
      ${(props) => props.nopadding && 'padding: 0;'}
    }

    ${(props) => props.clickable && 'cursor: pointer;'}
    transition: background-color 0.3s;
    &:hover {
      ${(props) => props.clickable && 'background-color: rgba(0,0,0, 0.03);'}
    }
  }
`
