import {
  useMutation as useOriginalMutation,
  DocumentNode,
  OperationVariables,
  MutationHookOptions as OriginalMutationHookOptions,
  useQuery as useOriginalQuery,
  QueryHookOptions as OriginalQueryHookOptions,
  useLazyQuery as useOriginalLazyQuery,
  LazyQueryHookOptions as OriginalLazyQueryHookOptions,
  MutationTuple,
  QueryResult,
  QueryTuple,
} from '@apollo/client'

export const useMutation = <TData = any, TVariables = OperationVariables>(
  mutation: DocumentNode,
  options?: OriginalMutationHookOptions<TData, TVariables>,
): MutationTuple<TData, TVariables> => {
  return useOriginalMutation(mutation, options)
}

export const useQuery = <TData = any, TVariables = OperationVariables>(
  query: DocumentNode,
  options?: OriginalQueryHookOptions<TData, TVariables>,
): QueryResult<TData, TVariables> => {
  return useOriginalQuery(query, { fetchPolicy: 'cache-and-network', ...options })
}

export const useLazyQuery = <TData = any, TVariables = OperationVariables>(
  query: DocumentNode,
  options?: OriginalLazyQueryHookOptions<TData, TVariables>,
): QueryTuple<TData, TVariables> => {
  return useOriginalLazyQuery(query, { fetchPolicy: 'cache-and-network', ...options })
}

export interface MutationHookOptions<TData = any, TVariables = OperationVariables>
  extends OriginalMutationHookOptions<TData, TVariables> {}

export interface QueryHookOptions<TData = any, TVariables = OperationVariables>
  extends OriginalQueryHookOptions<TData, TVariables> {}

export interface LazyQueryHookOptions<TData = any, TVariables = OperationVariables>
  extends OriginalLazyQueryHookOptions<TData, TVariables> {}
