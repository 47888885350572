import React, { useCallback, useEffect, useState } from 'react'
import * as B from 'src/components'
import { ColumnsType } from 'antd/lib/table'
import { User, UserStatus, useUsersQuery, UserType } from 'src/generated/graphql'
import { format } from 'date-fns'
import { useForm } from 'antd/lib/form/Form'
import { Button } from 'antd'
import { Link } from 'react-router-dom'

export interface SearchUserFilterFormState {
  searchText: string
  searchNote?: string
}

export const useController = () => {
  const [form] = useForm<SearchUserFilterFormState>()
  const { data, loading, refetch: searchUsers } = useUsersQuery({ variables: { data: {} } })

  const users = data?.users?.items ?? []
  const total = data?.users?.total ?? 0

  // 테이블 페이지네이션
  const [tablePage, setTablePage] = useState(1)
  const [totalMessages, setTotalMessages] = useState(0)

  const search = useCallback(async () => {
    const updatedFormState = form.getFieldsValue(true) as SearchUserFilterFormState
    const { searchText, searchNote } = updatedFormState

    searchUsers({
      data: {
        searchText,
        searchNote,
        page: tablePage,
      },
    })
  }, [form, tablePage, searchUsers])

  const onChangeTablePage = useCallback((page: number) => {
    setTablePage(page)
  }, [])

  const onClickSearch = useCallback(() => {
    setTablePage(1)
    search()
  }, [search])

  // 페이지가 바뀌면 새로 불러온다
  useEffect(() => {
    search()
    // eslint-disable-next-line
  }, [tablePage])

  useEffect(() => {
    const dailyMessages = JSON.parse(localStorage.getItem('dailyMessages') ?? '[]')
    const currntDate = format(new Date(), 'yyyy-MM-dd')
    const data = dailyMessages.filter((item: any) => item.date === currntDate)
    if (!!data.length) {
      const moneyFormat = data[0].count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      setTotalMessages(moneyFormat)
    }
  }, [])

  // 테이블 구성
  const tableColumns: ColumnsType<NonNullable<typeof users[number]>> = [
    {
      title: 'ID',
      dataIndex: 'id',
      align: 'center',
    },
    {
      title: '조회',
      dataIndex: [],
      render: (user: User) => (
        <Link to={`/admin/user/${user.id}`}>
          <Button size="small">조회</Button>
        </Link>
      ),
      align: 'center',
    },
    {
      title: '아이디',
      dataIndex: 'email',
      align: 'center',
    },
    {
      title: '유저타입',
      dataIndex: 'userType',
      render: (userType: UserType) =>
        userType === UserType.Normal ? (
          <B.BaseText>일반</B.BaseText>
        ) : (
          <B.BaseText>총판</B.BaseText>
        ),
      align: 'center',
    },
    {
      title: '건당 포인트',
      dataIndex: ['userCode', 'cost'],
      align: 'center',
    },
    {
      title: '잔여 포인트',
      dataIndex: 'point',
      align: 'center',
    },
    {
      title: '총 입금금액',
      dataIndex: 'sumCharged',
      align: 'center',
    },
    {
      title: '정지 여부',
      dataIndex: 'status',
      render: (status: UserStatus) =>
        status === UserStatus.Normal ? (
          <B.BaseText>활성화</B.BaseText>
        ) : (
          <B.BaseText type="danger">정지</B.BaseText>
        ),
      align: 'center',
    },
    {
      title: '최근 접속일',
      dataIndex: 'loggedAt',
      render: (loggedAt: Date) => (
        <B.BaseText>{format(new Date(loggedAt), 'yyyy.MM.dd')}</B.BaseText>
      ),
      align: 'center',
    },
    {
      title: '생성일',
      dataIndex: 'createdAt',
      render: (createdAt: Date) => (
        <B.BaseText>{format(new Date(createdAt), 'yyyy.MM.dd')}</B.BaseText>
      ),
      align: 'center',
    },
    {
      title: `회원 발송량 (${totalMessages}건)`,
      dataIndex: 'sumSendeddDaily',
      align: 'center',
    },
    {
      title: `노트`,
      dataIndex: 'note',
      align: 'center',
    },
  ]

  return {
    users: users.map((user) => ({ ...user, key: user.id })),
    loading,
    tableColumns,
    total,
    onChangeTablePage,
    onClickSearch,
    tablePage,
    form,
  }
}
