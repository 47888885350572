/* eslint no-template-curly-in-string: "off" */
export default class RulesValidator {
  static isValidPassword = (password: string) => {
    const alphabet = /[a-zA-Z]/
    const numeric = /[0-9]/
    return alphabet.test(password) && numeric.test(password) && password.length >= 8
  }

  static defaultValidateMessages = {
    // default: "Validation error on field ${label}",
    required: '${label}을(를) 입력해주세요.',
    // enum: "${label} must be one of [${enum}]",
    // whitespace: "${label} cannot be empty",
    // date: {
    //   format: "${label} is invalid for format date",
    //   parse: "${label} could not be parsed as date",
    //   invalid: "${label} is invalid date",
    // },
    types: {
      // string: typeTemplate,
      // method: typeTemplate,
      // array: typeTemplate,
      // object: typeTemplate,
      // number: typeTemplate,
      // date: typeTemplate,
      // boolean: typeTemplate,
      // integer: typeTemplate,
      // float: typeTemplate,
      // regexp: typeTemplate,
      email: `유효하지 않은 이메일 형식입니다.`,
      // url: typeTemplate,
      // hex: typeTemplate,
    },
    string: {
      len: '${label}은(는) ${len}자 이어야 합니다.',
      min: '${label}은(는) ${min}자 이상 입력해주세요.',
      max: '${label}은(는) ${max}자 이하로 입력해주세요.',
      range: '${label}은(는) ${min}자 이상 ${max}자 이내로 입력해주세요.',
    },
    // number: {
    //   len: "${label} must equal ${len}",
    //   min: "${label} cannot be less than ${min}",
    //   max: "${label} cannot be greater than ${max}",
    //   range: "${label} must be between ${min} and ${max}",
    // },
    // array: {
    //   len: "${label} must be exactly ${len} in length",
    //   min: "${label} cannot be less than ${min} in length",
    //   max: "${label} cannot be greater than ${max} in length",
    //   range: "${label} must be between ${min} and ${max} in length",
    // },
    // pattern: {
    //   mismatch: "${label} does not match pattern ${pattern}",
    // },
  }
}
