import React, { createContext, useContext } from 'react'
import { FormInstance } from 'antd/lib/form'
import { useForm } from 'antd/lib/form/Form'

export type RecursivePartial<T> = {
  [P in keyof T]?: T[P] extends (infer U)[]
    ? RecursivePartial<U>[]
    : T[P] extends object
    ? RecursivePartial<T[P]>
    : T[P]
}

interface BaseFormContextProps {
  onSubmit: () => void
  form: FormInstance
  onFormsUpdate?: (formName?: string, value?: any) => void
  onFormsFinish?: (formName?: string, value?: any) => void
}

const BaseFormContext = createContext({} as BaseFormContextProps)

export function useBaseFormContext<T = any>() {
  return useContext<{
    onSubmit: () => void
    form: FormInstance
    onFormsFinish?: (formName?: string, value?: RecursivePartial<T>) => void
  }>(BaseFormContext)
}

interface BaseFormProviderProps<T = any> {
  value: {
    onSubmit: () => void
    form?: FormInstance
  }
  onFormsFinish?: (formName?: string, value?: RecursivePartial<T>) => void
}
export const BaseFormProvider = <T,>({
  children,
  value,
  ...rest
}: BaseFormProviderProps<T> & { children?: React.ReactNode }) => {
  const [form] = useForm()

  return (
    <BaseFormContext.Provider value={{ form, ...value, ...rest }}>
      {children}
    </BaseFormContext.Provider>
  )
}
