import React from 'react'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import ReactDOM from 'react-dom'
import App from './App'
import { ConfigProvider } from 'antd'
import koKR from 'antd/es/locale/ko_KR'
import 'antd/dist/antd.less'
import { ThemeProvider } from 'styled-components'
import theme from 'src/styles/theme'
import { ApolloProvider } from '@apollo/client'
import client from 'src/graphql'
import { GlobalStyle } from './styles/global.styled'
import 'src/styles/global.css'
import { UserProvider } from './contexts/UserContext'
import { BrowserRouter } from 'react-router-dom'

ReactDOM.render(
  <ApolloProvider client={client}>
    <ThemeProvider theme={theme}>
      <ConfigProvider locale={koKR}>
        <GlobalStyle />
        <BrowserRouter>
          <UserProvider>
            <App />
          </UserProvider>
        </BrowserRouter>
      </ConfigProvider>
    </ThemeProvider>
  </ApolloProvider>,

  document.getElementById('root'),
)
