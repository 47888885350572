import React from 'react'
import { PublicRoute } from 'src/components/routes/PublicRoute'
import { Switch, useRouteMatch } from 'react-router-dom'
import MessagePage from './'
import MessageListPage from './List'

const MessageRoutes = () => {
  const { path } = useRouteMatch()
  return (
    <Switch>
      <PublicRoute path={`${path}/list`} component={MessageListPage} />
      <PublicRoute path={`${path}/`} component={MessagePage} />
    </Switch>
  )
}

export default MessageRoutes
