import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from './hooks';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: any }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
};

export type UserCode = {
  __typename?: 'UserCode';
  id: Scalars['Int'];
  content: Scalars['String'];
  user?: Maybe<User>;
  userId?: Maybe<Scalars['Int']>;
  creator?: Maybe<User>;
  creatorId?: Maybe<Scalars['Int']>;
  cost: Scalars['Int'];
  bonus: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  userCodeType: UserCodeType;
};


export enum UserCodeType {
  Normal = 'NORMAL',
  Agency = 'AGENCY'
}

export type User = {
  __typename?: 'User';
  id: Scalars['Int'];
  userCode?: Maybe<UserCode>;
  userType: UserType;
  name: Scalars['String'];
  email: Scalars['String'];
  point: Scalars['Int'];
  agencyPoint: Scalars['Int'];
  status: UserStatus;
  ip: Scalars['String'];
  note: Scalars['String'];
  loggedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  /** 입금한 총액 */
  sumCharged: Scalars['Int'];
  /** 발송한 메시지 수 */
  sumSended: Scalars['Int'];
  /** 하루 발송한 메시지 수 */
  sumSendeddDaily: Scalars['Int'];
  skt: Scalars['String'];
  kt: Scalars['String'];
  lg: Scalars['String'];
  sendNumber: Scalars['String'];
};

export enum UserType {
  Normal = 'NORMAL',
  Agency = 'AGENCY',
  Admin = 'ADMIN'
}

export enum UserStatus {
  Normal = 'NORMAL',
  Ban = 'BAN'
}

export type UserCharge = {
  __typename?: 'UserCharge';
  id: Scalars['Int'];
  user: User;
  userId: Scalars['Int'];
  name: Scalars['String'];
  money: Scalars['Int'];
  status: UserChargeStatus;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export enum UserChargeStatus {
  Ready = 'READY',
  Applied = 'APPLIED',
  Rejected = 'REJECTED'
}

export type PaginatedUser = {
  __typename?: 'PaginatedUser';
  items: Array<User>;
  total: Scalars['Int'];
};

export type PaginatedUserCode = {
  __typename?: 'PaginatedUserCode';
  items: Array<UserCode>;
  total: Scalars['Int'];
};

export type PaginatedUserCharge = {
  __typename?: 'PaginatedUserCharge';
  items: Array<UserCharge>;
  total: Scalars['Int'];
};

export type AgencyPointLog = {
  __typename?: 'AgencyPointLog';
  id: Scalars['Int'];
  user: User;
  userId: Scalars['Int'];
  point: Scalars['Int'];
  type: AgencyPointType;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export enum AgencyPointType {
  Point = 'POINT',
  Cash = 'CASH'
}

export type PointLogType = {
  __typename?: 'PointLogType';
  id: Scalars['Int'];
  content: Scalars['String'];
};

export type Message = {
  __typename?: 'Message';
  id: Scalars['Int'];
  user: User;
  userId: Scalars['Int'];
  pointLog: PointLog;
  pointLogId: Scalars['Int'];
  content: Scalars['String'];
  tel: Scalars['String'];
  count: Scalars['Int'];
  ratio: Scalars['Int'];
  ip: Scalars['String'];
  visible: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type PointLog = {
  __typename?: 'PointLog';
  id: Scalars['Int'];
  user: User;
  userId: Scalars['Int'];
  pointLogType: PointLogType;
  pointLogTypeId: Scalars['Int'];
  message?: Maybe<Message>;
  point: Scalars['Int'];
  restPoint: Scalars['Int'];
  note: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type PaginatedPointLog = {
  __typename?: 'PaginatedPointLog';
  items: Array<PointLog>;
  total: Scalars['Int'];
};

export type PaginatedAgencyPointLog = {
  __typename?: 'PaginatedAgencyPointLog';
  items: Array<AgencyPointLog>;
  total: Scalars['Int'];
};

export type TestTel = {
  __typename?: 'TestTel';
  id: Scalars['Int'];
  tel: Scalars['String'];
  type: TestTelType;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export enum TestTelType {
  Kt = 'KT',
  Sk = 'SK',
  Lg = 'LG',
  Charge = 'CHARGE'
}

export type LoginUserPayload = {
  __typename?: 'LoginUserPayload';
  id: Scalars['Int'];
  email: Scalars['String'];
  name: Scalars['String'];
  userType: UserType;
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  user: LoginUserPayload;
  token: Scalars['String'];
};

export type PaginatedMessage = {
  __typename?: 'PaginatedMessage';
  items: Array<Message>;
  total: Scalars['Int'];
};

export type PaginatedTestTel = {
  __typename?: 'PaginatedTestTel';
  items: Array<TestTel>;
  total: Scalars['Int'];
};

export type Notice = {
  __typename?: 'Notice';
  id: Scalars['Int'];
  user: User;
  userId: Scalars['Int'];
  title: Scalars['String'];
  content: Scalars['String'];
  visible: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type PaginatedNotice = {
  __typename?: 'PaginatedNotice';
  items: Array<Notice>;
  total: Scalars['Int'];
};

export type ChartUserChargeRow = {
  __typename?: 'ChartUserChargeRow';
  date: Scalars['String'];
  money: Scalars['String'];
  start?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['String']>;
};

export type ChartMessageRow = {
  __typename?: 'ChartMessageRow';
  date: Scalars['String'];
  count: Scalars['String'];
  start?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['String']>;
};

export type StatisticsPayload = {
  __typename?: 'StatisticsPayload';
  dailyCharges: Array<ChartUserChargeRow>;
  dailyMessages: Array<ChartMessageRow>;
  weeklyCharges: Array<ChartUserChargeRow>;
  weeklyMessages: Array<ChartMessageRow>;
  monthlyCharges: Array<ChartUserChargeRow>;
  monthlyMessages: Array<ChartMessageRow>;
};

export type Query = {
  __typename?: 'Query';
  /** [Auth] 로그인 된 유저의 정보를 가져옵니다. */
  me?: Maybe<User>;
  users: PaginatedUser;
  user: User;
  userCodes: PaginatedUserCode;
  userCharges: PaginatedUserCharge;
  pointLogs: PaginatedPointLog;
  agencyPointLogs: PaginatedAgencyPointLog;
  messages: PaginatedMessage;
  messageCountQueued: Scalars['Int'];
  testTels: PaginatedTestTel;
  notices: PaginatedNotice;
  notice: Notice;
  statistics: StatisticsPayload;
  isUnderInspection: Scalars['Boolean'];
};


export type QueryUsersArgs = {
  data: UsersInput;
};


export type QueryUserArgs = {
  id: Scalars['Int'];
};


export type QueryUserCodesArgs = {
  data: UserCodesInput;
};


export type QueryUserChargesArgs = {
  data: UserChargesInput;
};


export type QueryPointLogsArgs = {
  data: PointLogsInput;
};


export type QueryAgencyPointLogsArgs = {
  data: AgencyPointLogsInput;
};


export type QueryMessagesArgs = {
  data: MessagesInput;
};


export type QueryNoticesArgs = {
  data: NoticesInput;
};


export type QueryNoticeArgs = {
  id: Scalars['Int'];
};

export type UsersInput = {
  searchText?: Maybe<Scalars['String']>;
  searchNote?: Maybe<Scalars['String']>;
  creatorId?: Maybe<Scalars['Float']>;
  agency?: Maybe<Scalars['Boolean']>;
  page?: Maybe<Scalars['Int']>;
};

export type UserCodesInput = {
  used?: Maybe<Scalars['Boolean']>;
  creatorId?: Maybe<Scalars['Float']>;
  page?: Maybe<Scalars['Int']>;
};

export type UserChargesInput = {
  searchId?: Maybe<Scalars['String']>;
  searchName?: Maybe<Scalars['String']>;
  searchNote?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['Int']>;
  status?: Maybe<UserChargeStatus>;
  page?: Maybe<Scalars['Int']>;
};

export type PointLogsInput = {
  userId?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};

export type AgencyPointLogsInput = {
  page?: Maybe<Scalars['Int']>;
};

export type MessagesInput = {
  userId?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  visibleAll?: Maybe<Scalars['Boolean']>;
};

export type NoticesInput = {
  visible?: Maybe<Scalars['Boolean']>;
  page?: Maybe<Scalars['Int']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createUser: User;
  updateUser: Scalars['Boolean'];
  charge: Scalars['Boolean'];
  updatePassword: Scalars['Boolean'];
  updateSendNumber: User;
  createUserCode: Scalars['Boolean'];
  deleteUserCharge: Scalars['Boolean'];
  deleteUserCode: Scalars['Boolean'];
  updateUserCharge: Scalars['Boolean'];
  givePointToUser: Scalars['Boolean'];
  giveAgencyPointToUser: Scalars['Boolean'];
  /** 로그인 Mutation */
  login: LoginResponse;
  sendMessages: Scalars['Boolean'];
  hideMessage: Scalars['Boolean'];
  createTestTel: Scalars['Boolean'];
  deleteTestTel: Scalars['Boolean'];
  createNotice: Scalars['Boolean'];
  updateNotice: Scalars['Boolean'];
  deleteNotice: Scalars['Boolean'];
  updateUnderInspection: Scalars['Boolean'];
};


export type MutationCreateUserArgs = {
  data: CreateUserInput;
};


export type MutationUpdateUserArgs = {
  data: UpdateUserInput;
};


export type MutationChargeArgs = {
  data: ChargeInput;
};


export type MutationUpdatePasswordArgs = {
  data: UpdatePasswordInput;
};


export type MutationUpdateSendNumberArgs = {
  data: UpdateUserSendNumberInput;
};


export type MutationCreateUserCodeArgs = {
  data: CreateUserCodeInput;
};


export type MutationDeleteUserChargeArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteUserCodeArgs = {
  id: Scalars['Int'];
};


export type MutationUpdateUserChargeArgs = {
  data: UpdateUserChargeInput;
};


export type MutationGivePointToUserArgs = {
  data: GivePointToUserInput;
};


export type MutationGiveAgencyPointToUserArgs = {
  data: GiveAgencyPointToUserInput;
};


export type MutationLoginArgs = {
  data: LoginInput;
};


export type MutationSendMessagesArgs = {
  data: SendMessagesInput;
};


export type MutationHideMessageArgs = {
  id: Scalars['Int'];
};


export type MutationCreateTestTelArgs = {
  data: CreateTestTelInput;
};


export type MutationDeleteTestTelArgs = {
  id: Scalars['Int'];
};


export type MutationCreateNoticeArgs = {
  data: CreateNoticeInput;
};


export type MutationUpdateNoticeArgs = {
  data: UpdateNoticeInput;
};


export type MutationDeleteNoticeArgs = {
  id: Scalars['Int'];
};


export type MutationUpdateUnderInspectionArgs = {
  underInspection: Scalars['Boolean'];
};

export type CreateUserInput = {
  name: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
  code: Scalars['String'];
};

export type UpdateUserInput = {
  id: Scalars['Int'];
  password: Scalars['String'];
  note: Scalars['String'];
  cost: Scalars['Int'];
  status: UserStatus;
};

export type ChargeInput = {
  name: Scalars['String'];
  money: Scalars['Int'];
};

export type UpdatePasswordInput = {
  passPrev: Scalars['String'];
  password: Scalars['String'];
};

export type UpdateUserSendNumberInput = {
  sendNumber: Scalars['String'];
};

export type CreateUserCodeInput = {
  cost: Scalars['Int'];
  bonus: Scalars['Int'];
  type?: Maybe<UserCodeType>;
};

export type UpdateUserChargeInput = {
  id: Scalars['Int'];
  status: UserChargeStatus;
};

export type GivePointToUserInput = {
  userId: Scalars['Int'];
  point: Scalars['Int'];
  note?: Maybe<Scalars['String']>;
};

export type GiveAgencyPointToUserInput = {
  userId: Scalars['Int'];
  point: Scalars['Int'];
  type?: Maybe<AgencyPointType>;
};

export type LoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type SendMessagesInput = {
  content: Scalars['String'];
  tel: Array<Scalars['String']>;
  forTest?: Maybe<Scalars['Boolean']>;
  replaceMode?: Maybe<Scalars['Boolean']>;
  sendType?: Maybe<MessageSendType>;
};

export enum MessageSendType {
  Random = 'RANDOM',
  Fixed = 'FIXED'
}

export type CreateTestTelInput = {
  tel: Scalars['String'];
  type: TestTelType;
};

export type CreateNoticeInput = {
  title: Scalars['String'];
  content: Scalars['String'];
  visible: Scalars['Boolean'];
};

export type UpdateNoticeInput = {
  id: Scalars['Int'];
  title: Scalars['String'];
  content: Scalars['String'];
  visible: Scalars['Boolean'];
};

export type LoginMutationVariables = Exact<{
  data: LoginInput;
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login: (
    { __typename?: 'LoginResponse' }
    & Pick<LoginResponse, 'token'>
    & { user: (
      { __typename?: 'LoginUserPayload' }
      & Pick<LoginUserPayload, 'id' | 'name' | 'email' | 'userType'>
    ) }
  ) }
);

export type StatisticsQueryVariables = Exact<{ [key: string]: never; }>;


export type StatisticsQuery = (
  { __typename?: 'Query' }
  & { statistics: (
    { __typename?: 'StatisticsPayload' }
    & { dailyCharges: Array<(
      { __typename?: 'ChartUserChargeRow' }
      & ChartUserChargeRowFragmentFragment
    )>, dailyMessages: Array<(
      { __typename?: 'ChartMessageRow' }
      & ChartMessageRowFragmentFragment
    )>, weeklyCharges: Array<(
      { __typename?: 'ChartUserChargeRow' }
      & ChartUserChargeRowFragmentFragment
    )>, weeklyMessages: Array<(
      { __typename?: 'ChartMessageRow' }
      & ChartMessageRowFragmentFragment
    )>, monthlyCharges: Array<(
      { __typename?: 'ChartUserChargeRow' }
      & ChartUserChargeRowFragmentFragment
    )>, monthlyMessages: Array<(
      { __typename?: 'ChartMessageRow' }
      & ChartMessageRowFragmentFragment
    )> }
  ) }
);

export type ChartUserChargeRowFragmentFragment = (
  { __typename?: 'ChartUserChargeRow' }
  & Pick<ChartUserChargeRow, 'date' | 'money' | 'start' | 'end'>
);

export type ChartMessageRowFragmentFragment = (
  { __typename?: 'ChartMessageRow' }
  & Pick<ChartMessageRow, 'date' | 'count' | 'start' | 'end'>
);

export type IsUnderInspectionQueryVariables = Exact<{ [key: string]: never; }>;


export type IsUnderInspectionQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'isUnderInspection'>
);

export type UpdateUnderInspectionMutationVariables = Exact<{
  underInspection: Scalars['Boolean'];
}>;


export type UpdateUnderInspectionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateUnderInspection'>
);

export type MessageCountQueuedQueryVariables = Exact<{ [key: string]: never; }>;


export type MessageCountQueuedQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'messageCountQueued'>
);

export type MessagesQueryVariables = Exact<{
  data: MessagesInput;
}>;


export type MessagesQuery = (
  { __typename?: 'Query' }
  & { messages: (
    { __typename?: 'PaginatedMessage' }
    & Pick<PaginatedMessage, 'total'>
    & { items: Array<(
      { __typename?: 'Message' }
      & MessageFragmentFragment
    )> }
  ) }
);

export type SendMessagesMutationVariables = Exact<{
  data: SendMessagesInput;
}>;


export type SendMessagesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sendMessages'>
);

export type HideMessageMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type HideMessageMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'hideMessage'>
);

export type MessageFragmentFragment = (
  { __typename?: 'Message' }
  & Pick<Message, 'id' | 'content' | 'tel' | 'count' | 'ratio' | 'userId' | 'createdAt'>
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'email'>
  ), pointLog: (
    { __typename?: 'PointLog' }
    & Pick<PointLog, 'point' | 'restPoint'>
  ) }
);

export type NoticesQueryVariables = Exact<{
  data: NoticesInput;
}>;


export type NoticesQuery = (
  { __typename?: 'Query' }
  & { notices: (
    { __typename?: 'PaginatedNotice' }
    & Pick<PaginatedNotice, 'total'>
    & { items: Array<(
      { __typename?: 'Notice' }
      & Pick<Notice, 'id' | 'title' | 'content' | 'visible' | 'createdAt'>
    )> }
  ) }
);

export type NoticeQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type NoticeQuery = (
  { __typename?: 'Query' }
  & { notice: (
    { __typename?: 'Notice' }
    & Pick<Notice, 'id' | 'title' | 'content' | 'visible' | 'createdAt'>
  ) }
);

export type CreateNoticeMutationVariables = Exact<{
  data: CreateNoticeInput;
}>;


export type CreateNoticeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createNotice'>
);

export type UpdateNoticeMutationVariables = Exact<{
  data: UpdateNoticeInput;
}>;


export type UpdateNoticeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateNotice'>
);

export type DeleteNoticeMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteNoticeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteNotice'>
);

export type GivePointToUserMutationVariables = Exact<{
  data: GivePointToUserInput;
}>;


export type GivePointToUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'givePointToUser'>
);

export type GiveAgencyPointToUserMutationVariables = Exact<{
  data: GiveAgencyPointToUserInput;
}>;


export type GiveAgencyPointToUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'giveAgencyPointToUser'>
);

export type PointLogsQueryVariables = Exact<{
  data: PointLogsInput;
}>;


export type PointLogsQuery = (
  { __typename?: 'Query' }
  & { pointLogs: (
    { __typename?: 'PaginatedPointLog' }
    & Pick<PaginatedPointLog, 'total'>
    & { items: Array<(
      { __typename?: 'PointLog' }
      & PointLogFragmentFragment
    )> }
  ) }
);

export type PointLogFragmentFragment = (
  { __typename?: 'PointLog' }
  & Pick<PointLog, 'id' | 'userId' | 'point' | 'restPoint' | 'createdAt' | 'note'>
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'email'>
  ), pointLogType: (
    { __typename?: 'PointLogType' }
    & Pick<PointLogType, 'content'>
  ), message?: Maybe<(
    { __typename?: 'Message' }
    & Pick<Message, 'content'>
  )> }
);

export type AgencyPointLogsQueryVariables = Exact<{
  data: AgencyPointLogsInput;
}>;


export type AgencyPointLogsQuery = (
  { __typename?: 'Query' }
  & { agencyPointLogs: (
    { __typename?: 'PaginatedAgencyPointLog' }
    & Pick<PaginatedAgencyPointLog, 'total'>
    & { items: Array<(
      { __typename?: 'AgencyPointLog' }
      & AgencyPointLogFragmentFragment
    )> }
  ) }
);

export type AgencyPointLogFragmentFragment = (
  { __typename?: 'AgencyPointLog' }
  & Pick<AgencyPointLog, 'id' | 'userId' | 'point' | 'createdAt' | 'type'>
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'email'>
  ) }
);

export type TestTelsQueryVariables = Exact<{ [key: string]: never; }>;


export type TestTelsQuery = (
  { __typename?: 'Query' }
  & { testTels: (
    { __typename?: 'PaginatedTestTel' }
    & Pick<PaginatedTestTel, 'total'>
    & { items: Array<(
      { __typename?: 'TestTel' }
      & Pick<TestTel, 'id' | 'tel' | 'type'>
    )> }
  ) }
);

export type CreateTestTelMutationVariables = Exact<{
  data: CreateTestTelInput;
}>;


export type CreateTestTelMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createTestTel'>
);

export type DeleteTestTelMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteTestTelMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteTestTel'>
);

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name' | 'email' | 'note' | 'userType' | 'point' | 'agencyPoint' | 'status'>
    & { userCode?: Maybe<(
      { __typename?: 'UserCode' }
      & Pick<UserCode, 'cost' | 'content'>
    )> }
  )> }
);

export type UserQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type UserQuery = (
  { __typename?: 'Query' }
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name' | 'email' | 'note' | 'userType' | 'point' | 'agencyPoint' | 'status' | 'sumCharged'>
    & { userCode?: Maybe<(
      { __typename?: 'UserCode' }
      & Pick<UserCode, 'cost' | 'content'>
    )> }
  ) }
);

export type MySendNumberQueryVariables = Exact<{ [key: string]: never; }>;


export type MySendNumberQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'sendNumber'>
  )> }
);

export type UsersQueryVariables = Exact<{
  data: UsersInput;
}>;


export type UsersQuery = (
  { __typename?: 'Query' }
  & { users: (
    { __typename?: 'PaginatedUser' }
    & Pick<PaginatedUser, 'total'>
    & { items: Array<(
      { __typename?: 'User' }
      & UserDetailFragmentFragment
    )> }
  ) }
);

export type UserCodesQueryVariables = Exact<{
  data: UserCodesInput;
}>;


export type UserCodesQuery = (
  { __typename?: 'Query' }
  & { userCodes: (
    { __typename?: 'PaginatedUserCode' }
    & Pick<PaginatedUserCode, 'total'>
    & { items: Array<(
      { __typename?: 'UserCode' }
      & UserCodeFragmentFragment
    )> }
  ) }
);

export type UserChargesQueryVariables = Exact<{
  data: UserChargesInput;
}>;


export type UserChargesQuery = (
  { __typename?: 'Query' }
  & { userCharges: (
    { __typename?: 'PaginatedUserCharge' }
    & Pick<PaginatedUserCharge, 'total'>
    & { items: Array<(
      { __typename?: 'UserCharge' }
      & UserChargeFragmentFragment
    )> }
  ) }
);

export type CreateUserMutationVariables = Exact<{
  data: CreateUserInput;
}>;


export type CreateUserMutation = (
  { __typename?: 'Mutation' }
  & { createUser: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
  ) }
);

export type CreateUserCodeMutationVariables = Exact<{
  data: CreateUserCodeInput;
}>;


export type CreateUserCodeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createUserCode'>
);

export type DeleteUserCodeMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteUserCodeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteUserCode'>
);

export type DeleteUserChargeMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteUserChargeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteUserCharge'>
);

export type UpdateUserChargeMutationVariables = Exact<{
  data: UpdateUserChargeInput;
}>;


export type UpdateUserChargeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateUserCharge'>
);

export type ChargeMutationVariables = Exact<{
  data: ChargeInput;
}>;


export type ChargeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'charge'>
);

export type UpdateUserMutationVariables = Exact<{
  data: UpdateUserInput;
}>;


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateUser'>
);

export type UpdatePasswordMutationVariables = Exact<{
  data: UpdatePasswordInput;
}>;


export type UpdatePasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updatePassword'>
);

export type UpdateSendNumberMutationVariables = Exact<{
  data: UpdateUserSendNumberInput;
}>;


export type UpdateSendNumberMutation = (
  { __typename?: 'Mutation' }
  & { updateSendNumber: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'sendNumber'>
  ) }
);

export type UserFragmentFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'name' | 'email' | 'userType'>
);

export type UserDetailFragmentFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'name' | 'email' | 'userType' | 'status' | 'point' | 'agencyPoint' | 'loggedAt' | 'createdAt' | 'ip' | 'note' | 'sumCharged' | 'sumSended' | 'sumSendeddDaily'>
  & { userCode?: Maybe<(
    { __typename?: 'UserCode' }
    & Pick<UserCode, 'content' | 'cost' | 'userCodeType'>
  )> }
);

export type UserCodeFragmentFragment = (
  { __typename?: 'UserCode' }
  & Pick<UserCode, 'id' | 'content' | 'userId' | 'userCodeType' | 'creatorId' | 'createdAt' | 'cost' | 'bonus'>
  & { user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'email'>
  )>, creator?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'email'>
  )> }
);

export type UserChargeFragmentFragment = (
  { __typename?: 'UserCharge' }
  & Pick<UserCharge, 'id' | 'name' | 'money' | 'status' | 'userId' | 'createdAt'>
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'email'>
  ) }
);

export const ChartUserChargeRowFragmentDoc = gql`
    fragment ChartUserChargeRowFragment on ChartUserChargeRow {
  date
  money
  start
  end
}
    `;
export const ChartMessageRowFragmentDoc = gql`
    fragment ChartMessageRowFragment on ChartMessageRow {
  date
  count
  start
  end
}
    `;
export const MessageFragmentDoc = gql`
    fragment MessageFragment on Message {
  id
  content
  tel
  count
  ratio
  userId
  user {
    email
  }
  pointLog {
    point
    restPoint
  }
  createdAt
}
    `;
export const PointLogFragmentDoc = gql`
    fragment PointLogFragment on PointLog {
  id
  userId
  user {
    email
  }
  pointLogType {
    content
  }
  point
  restPoint
  createdAt
  note
  message {
    content
  }
}
    `;
export const AgencyPointLogFragmentDoc = gql`
    fragment AgencyPointLogFragment on AgencyPointLog {
  id
  userId
  user {
    email
  }
  point
  createdAt
  type
}
    `;
export const UserFragmentDoc = gql`
    fragment UserFragment on User {
  id
  name
  email
  userType
}
    `;
export const UserDetailFragmentDoc = gql`
    fragment UserDetailFragment on User {
  id
  name
  email
  userType
  status
  point
  agencyPoint
  loggedAt
  createdAt
  ip
  note
  sumCharged
  sumSended
  sumSendeddDaily
  userCode {
    content
    cost
    userCodeType
  }
}
    `;
export const UserCodeFragmentDoc = gql`
    fragment UserCodeFragment on UserCode {
  id
  content
  userId
  userCodeType
  user {
    id
    email
  }
  creatorId
  creator {
    id
    email
  }
  createdAt
  cost
  bonus
}
    `;
export const UserChargeFragmentDoc = gql`
    fragment UserChargeFragment on UserCharge {
  id
  name
  money
  status
  userId
  user {
    id
    email
  }
  createdAt
}
    `;
export const LoginDocument = gql`
    mutation login($data: LoginInput!) {
  login(data: $data) {
    token
    user {
      id
      name
      email
      userType
    }
  }
}
    `;
export type LoginMutationFn = ApolloReactCommon.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        return ApolloReactHooks.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, baseOptions);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = ApolloReactCommon.MutationResult<LoginMutation>;
export type LoginMutationOptions = ApolloReactCommon.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const StatisticsDocument = gql`
    query statistics {
  statistics {
    dailyCharges {
      ...ChartUserChargeRowFragment
    }
    dailyMessages {
      ...ChartMessageRowFragment
    }
    weeklyCharges {
      ...ChartUserChargeRowFragment
    }
    weeklyMessages {
      ...ChartMessageRowFragment
    }
    monthlyCharges {
      ...ChartUserChargeRowFragment
    }
    monthlyMessages {
      ...ChartMessageRowFragment
    }
  }
}
    ${ChartUserChargeRowFragmentDoc}
${ChartMessageRowFragmentDoc}`;

/**
 * __useStatisticsQuery__
 *
 * To run a query within a React component, call `useStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStatisticsQuery({
 *   variables: {
 *   },
 * });
 */
export function useStatisticsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StatisticsQuery, StatisticsQueryVariables>) {
        return ApolloReactHooks.useQuery<StatisticsQuery, StatisticsQueryVariables>(StatisticsDocument, baseOptions);
      }
export function useStatisticsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StatisticsQuery, StatisticsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StatisticsQuery, StatisticsQueryVariables>(StatisticsDocument, baseOptions);
        }
export type StatisticsQueryHookResult = ReturnType<typeof useStatisticsQuery>;
export type StatisticsLazyQueryHookResult = ReturnType<typeof useStatisticsLazyQuery>;
export type StatisticsQueryResult = ApolloReactCommon.QueryResult<StatisticsQuery, StatisticsQueryVariables>;
export const IsUnderInspectionDocument = gql`
    query isUnderInspection {
  isUnderInspection
}
    `;

/**
 * __useIsUnderInspectionQuery__
 *
 * To run a query within a React component, call `useIsUnderInspectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsUnderInspectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsUnderInspectionQuery({
 *   variables: {
 *   },
 * });
 */
export function useIsUnderInspectionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsUnderInspectionQuery, IsUnderInspectionQueryVariables>) {
        return ApolloReactHooks.useQuery<IsUnderInspectionQuery, IsUnderInspectionQueryVariables>(IsUnderInspectionDocument, baseOptions);
      }
export function useIsUnderInspectionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsUnderInspectionQuery, IsUnderInspectionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsUnderInspectionQuery, IsUnderInspectionQueryVariables>(IsUnderInspectionDocument, baseOptions);
        }
export type IsUnderInspectionQueryHookResult = ReturnType<typeof useIsUnderInspectionQuery>;
export type IsUnderInspectionLazyQueryHookResult = ReturnType<typeof useIsUnderInspectionLazyQuery>;
export type IsUnderInspectionQueryResult = ApolloReactCommon.QueryResult<IsUnderInspectionQuery, IsUnderInspectionQueryVariables>;
export const UpdateUnderInspectionDocument = gql`
    mutation updateUnderInspection($underInspection: Boolean!) {
  updateUnderInspection(underInspection: $underInspection)
}
    `;
export type UpdateUnderInspectionMutationFn = ApolloReactCommon.MutationFunction<UpdateUnderInspectionMutation, UpdateUnderInspectionMutationVariables>;

/**
 * __useUpdateUnderInspectionMutation__
 *
 * To run a mutation, you first call `useUpdateUnderInspectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUnderInspectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUnderInspectionMutation, { data, loading, error }] = useUpdateUnderInspectionMutation({
 *   variables: {
 *      underInspection: // value for 'underInspection'
 *   },
 * });
 */
export function useUpdateUnderInspectionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUnderInspectionMutation, UpdateUnderInspectionMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUnderInspectionMutation, UpdateUnderInspectionMutationVariables>(UpdateUnderInspectionDocument, baseOptions);
      }
export type UpdateUnderInspectionMutationHookResult = ReturnType<typeof useUpdateUnderInspectionMutation>;
export type UpdateUnderInspectionMutationResult = ApolloReactCommon.MutationResult<UpdateUnderInspectionMutation>;
export type UpdateUnderInspectionMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUnderInspectionMutation, UpdateUnderInspectionMutationVariables>;
export const MessageCountQueuedDocument = gql`
    query messageCountQueued {
  messageCountQueued
}
    `;

/**
 * __useMessageCountQueuedQuery__
 *
 * To run a query within a React component, call `useMessageCountQueuedQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessageCountQueuedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessageCountQueuedQuery({
 *   variables: {
 *   },
 * });
 */
export function useMessageCountQueuedQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MessageCountQueuedQuery, MessageCountQueuedQueryVariables>) {
        return ApolloReactHooks.useQuery<MessageCountQueuedQuery, MessageCountQueuedQueryVariables>(MessageCountQueuedDocument, baseOptions);
      }
export function useMessageCountQueuedLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MessageCountQueuedQuery, MessageCountQueuedQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MessageCountQueuedQuery, MessageCountQueuedQueryVariables>(MessageCountQueuedDocument, baseOptions);
        }
export type MessageCountQueuedQueryHookResult = ReturnType<typeof useMessageCountQueuedQuery>;
export type MessageCountQueuedLazyQueryHookResult = ReturnType<typeof useMessageCountQueuedLazyQuery>;
export type MessageCountQueuedQueryResult = ApolloReactCommon.QueryResult<MessageCountQueuedQuery, MessageCountQueuedQueryVariables>;
export const MessagesDocument = gql`
    query messages($data: MessagesInput!) {
  messages(data: $data) {
    items {
      ...MessageFragment
    }
    total
  }
}
    ${MessageFragmentDoc}`;

/**
 * __useMessagesQuery__
 *
 * To run a query within a React component, call `useMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessagesQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useMessagesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MessagesQuery, MessagesQueryVariables>) {
        return ApolloReactHooks.useQuery<MessagesQuery, MessagesQueryVariables>(MessagesDocument, baseOptions);
      }
export function useMessagesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MessagesQuery, MessagesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MessagesQuery, MessagesQueryVariables>(MessagesDocument, baseOptions);
        }
export type MessagesQueryHookResult = ReturnType<typeof useMessagesQuery>;
export type MessagesLazyQueryHookResult = ReturnType<typeof useMessagesLazyQuery>;
export type MessagesQueryResult = ApolloReactCommon.QueryResult<MessagesQuery, MessagesQueryVariables>;
export const SendMessagesDocument = gql`
    mutation sendMessages($data: SendMessagesInput!) {
  sendMessages(data: $data)
}
    `;
export type SendMessagesMutationFn = ApolloReactCommon.MutationFunction<SendMessagesMutation, SendMessagesMutationVariables>;

/**
 * __useSendMessagesMutation__
 *
 * To run a mutation, you first call `useSendMessagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMessagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMessagesMutation, { data, loading, error }] = useSendMessagesMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSendMessagesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendMessagesMutation, SendMessagesMutationVariables>) {
        return ApolloReactHooks.useMutation<SendMessagesMutation, SendMessagesMutationVariables>(SendMessagesDocument, baseOptions);
      }
export type SendMessagesMutationHookResult = ReturnType<typeof useSendMessagesMutation>;
export type SendMessagesMutationResult = ApolloReactCommon.MutationResult<SendMessagesMutation>;
export type SendMessagesMutationOptions = ApolloReactCommon.BaseMutationOptions<SendMessagesMutation, SendMessagesMutationVariables>;
export const HideMessageDocument = gql`
    mutation hideMessage($id: Int!) {
  hideMessage(id: $id)
}
    `;
export type HideMessageMutationFn = ApolloReactCommon.MutationFunction<HideMessageMutation, HideMessageMutationVariables>;

/**
 * __useHideMessageMutation__
 *
 * To run a mutation, you first call `useHideMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHideMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [hideMessageMutation, { data, loading, error }] = useHideMessageMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useHideMessageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<HideMessageMutation, HideMessageMutationVariables>) {
        return ApolloReactHooks.useMutation<HideMessageMutation, HideMessageMutationVariables>(HideMessageDocument, baseOptions);
      }
export type HideMessageMutationHookResult = ReturnType<typeof useHideMessageMutation>;
export type HideMessageMutationResult = ApolloReactCommon.MutationResult<HideMessageMutation>;
export type HideMessageMutationOptions = ApolloReactCommon.BaseMutationOptions<HideMessageMutation, HideMessageMutationVariables>;
export const NoticesDocument = gql`
    query notices($data: NoticesInput!) {
  notices(data: $data) {
    items {
      id
      title
      content
      visible
      createdAt
    }
    total
  }
}
    `;

/**
 * __useNoticesQuery__
 *
 * To run a query within a React component, call `useNoticesQuery` and pass it any options that fit your needs.
 * When your component renders, `useNoticesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNoticesQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useNoticesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NoticesQuery, NoticesQueryVariables>) {
        return ApolloReactHooks.useQuery<NoticesQuery, NoticesQueryVariables>(NoticesDocument, baseOptions);
      }
export function useNoticesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NoticesQuery, NoticesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<NoticesQuery, NoticesQueryVariables>(NoticesDocument, baseOptions);
        }
export type NoticesQueryHookResult = ReturnType<typeof useNoticesQuery>;
export type NoticesLazyQueryHookResult = ReturnType<typeof useNoticesLazyQuery>;
export type NoticesQueryResult = ApolloReactCommon.QueryResult<NoticesQuery, NoticesQueryVariables>;
export const NoticeDocument = gql`
    query notice($id: Int!) {
  notice(id: $id) {
    id
    title
    content
    visible
    createdAt
  }
}
    `;

/**
 * __useNoticeQuery__
 *
 * To run a query within a React component, call `useNoticeQuery` and pass it any options that fit your needs.
 * When your component renders, `useNoticeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNoticeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNoticeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NoticeQuery, NoticeQueryVariables>) {
        return ApolloReactHooks.useQuery<NoticeQuery, NoticeQueryVariables>(NoticeDocument, baseOptions);
      }
export function useNoticeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NoticeQuery, NoticeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<NoticeQuery, NoticeQueryVariables>(NoticeDocument, baseOptions);
        }
export type NoticeQueryHookResult = ReturnType<typeof useNoticeQuery>;
export type NoticeLazyQueryHookResult = ReturnType<typeof useNoticeLazyQuery>;
export type NoticeQueryResult = ApolloReactCommon.QueryResult<NoticeQuery, NoticeQueryVariables>;
export const CreateNoticeDocument = gql`
    mutation createNotice($data: CreateNoticeInput!) {
  createNotice(data: $data)
}
    `;
export type CreateNoticeMutationFn = ApolloReactCommon.MutationFunction<CreateNoticeMutation, CreateNoticeMutationVariables>;

/**
 * __useCreateNoticeMutation__
 *
 * To run a mutation, you first call `useCreateNoticeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNoticeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNoticeMutation, { data, loading, error }] = useCreateNoticeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateNoticeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateNoticeMutation, CreateNoticeMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateNoticeMutation, CreateNoticeMutationVariables>(CreateNoticeDocument, baseOptions);
      }
export type CreateNoticeMutationHookResult = ReturnType<typeof useCreateNoticeMutation>;
export type CreateNoticeMutationResult = ApolloReactCommon.MutationResult<CreateNoticeMutation>;
export type CreateNoticeMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateNoticeMutation, CreateNoticeMutationVariables>;
export const UpdateNoticeDocument = gql`
    mutation updateNotice($data: UpdateNoticeInput!) {
  updateNotice(data: $data)
}
    `;
export type UpdateNoticeMutationFn = ApolloReactCommon.MutationFunction<UpdateNoticeMutation, UpdateNoticeMutationVariables>;

/**
 * __useUpdateNoticeMutation__
 *
 * To run a mutation, you first call `useUpdateNoticeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNoticeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNoticeMutation, { data, loading, error }] = useUpdateNoticeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateNoticeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateNoticeMutation, UpdateNoticeMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateNoticeMutation, UpdateNoticeMutationVariables>(UpdateNoticeDocument, baseOptions);
      }
export type UpdateNoticeMutationHookResult = ReturnType<typeof useUpdateNoticeMutation>;
export type UpdateNoticeMutationResult = ApolloReactCommon.MutationResult<UpdateNoticeMutation>;
export type UpdateNoticeMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateNoticeMutation, UpdateNoticeMutationVariables>;
export const DeleteNoticeDocument = gql`
    mutation deleteNotice($id: Int!) {
  deleteNotice(id: $id)
}
    `;
export type DeleteNoticeMutationFn = ApolloReactCommon.MutationFunction<DeleteNoticeMutation, DeleteNoticeMutationVariables>;

/**
 * __useDeleteNoticeMutation__
 *
 * To run a mutation, you first call `useDeleteNoticeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNoticeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNoticeMutation, { data, loading, error }] = useDeleteNoticeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteNoticeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteNoticeMutation, DeleteNoticeMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteNoticeMutation, DeleteNoticeMutationVariables>(DeleteNoticeDocument, baseOptions);
      }
export type DeleteNoticeMutationHookResult = ReturnType<typeof useDeleteNoticeMutation>;
export type DeleteNoticeMutationResult = ApolloReactCommon.MutationResult<DeleteNoticeMutation>;
export type DeleteNoticeMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteNoticeMutation, DeleteNoticeMutationVariables>;
export const GivePointToUserDocument = gql`
    mutation givePointToUser($data: GivePointToUserInput!) {
  givePointToUser(data: $data)
}
    `;
export type GivePointToUserMutationFn = ApolloReactCommon.MutationFunction<GivePointToUserMutation, GivePointToUserMutationVariables>;

/**
 * __useGivePointToUserMutation__
 *
 * To run a mutation, you first call `useGivePointToUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGivePointToUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [givePointToUserMutation, { data, loading, error }] = useGivePointToUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGivePointToUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GivePointToUserMutation, GivePointToUserMutationVariables>) {
        return ApolloReactHooks.useMutation<GivePointToUserMutation, GivePointToUserMutationVariables>(GivePointToUserDocument, baseOptions);
      }
export type GivePointToUserMutationHookResult = ReturnType<typeof useGivePointToUserMutation>;
export type GivePointToUserMutationResult = ApolloReactCommon.MutationResult<GivePointToUserMutation>;
export type GivePointToUserMutationOptions = ApolloReactCommon.BaseMutationOptions<GivePointToUserMutation, GivePointToUserMutationVariables>;
export const GiveAgencyPointToUserDocument = gql`
    mutation giveAgencyPointToUser($data: GiveAgencyPointToUserInput!) {
  giveAgencyPointToUser(data: $data)
}
    `;
export type GiveAgencyPointToUserMutationFn = ApolloReactCommon.MutationFunction<GiveAgencyPointToUserMutation, GiveAgencyPointToUserMutationVariables>;

/**
 * __useGiveAgencyPointToUserMutation__
 *
 * To run a mutation, you first call `useGiveAgencyPointToUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGiveAgencyPointToUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [giveAgencyPointToUserMutation, { data, loading, error }] = useGiveAgencyPointToUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGiveAgencyPointToUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GiveAgencyPointToUserMutation, GiveAgencyPointToUserMutationVariables>) {
        return ApolloReactHooks.useMutation<GiveAgencyPointToUserMutation, GiveAgencyPointToUserMutationVariables>(GiveAgencyPointToUserDocument, baseOptions);
      }
export type GiveAgencyPointToUserMutationHookResult = ReturnType<typeof useGiveAgencyPointToUserMutation>;
export type GiveAgencyPointToUserMutationResult = ApolloReactCommon.MutationResult<GiveAgencyPointToUserMutation>;
export type GiveAgencyPointToUserMutationOptions = ApolloReactCommon.BaseMutationOptions<GiveAgencyPointToUserMutation, GiveAgencyPointToUserMutationVariables>;
export const PointLogsDocument = gql`
    query pointLogs($data: PointLogsInput!) {
  pointLogs(data: $data) {
    items {
      ...PointLogFragment
    }
    total
  }
}
    ${PointLogFragmentDoc}`;

/**
 * __usePointLogsQuery__
 *
 * To run a query within a React component, call `usePointLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePointLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePointLogsQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function usePointLogsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PointLogsQuery, PointLogsQueryVariables>) {
        return ApolloReactHooks.useQuery<PointLogsQuery, PointLogsQueryVariables>(PointLogsDocument, baseOptions);
      }
export function usePointLogsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PointLogsQuery, PointLogsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PointLogsQuery, PointLogsQueryVariables>(PointLogsDocument, baseOptions);
        }
export type PointLogsQueryHookResult = ReturnType<typeof usePointLogsQuery>;
export type PointLogsLazyQueryHookResult = ReturnType<typeof usePointLogsLazyQuery>;
export type PointLogsQueryResult = ApolloReactCommon.QueryResult<PointLogsQuery, PointLogsQueryVariables>;
export const AgencyPointLogsDocument = gql`
    query agencyPointLogs($data: AgencyPointLogsInput!) {
  agencyPointLogs(data: $data) {
    items {
      ...AgencyPointLogFragment
    }
    total
  }
}
    ${AgencyPointLogFragmentDoc}`;

/**
 * __useAgencyPointLogsQuery__
 *
 * To run a query within a React component, call `useAgencyPointLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAgencyPointLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAgencyPointLogsQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAgencyPointLogsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AgencyPointLogsQuery, AgencyPointLogsQueryVariables>) {
        return ApolloReactHooks.useQuery<AgencyPointLogsQuery, AgencyPointLogsQueryVariables>(AgencyPointLogsDocument, baseOptions);
      }
export function useAgencyPointLogsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AgencyPointLogsQuery, AgencyPointLogsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AgencyPointLogsQuery, AgencyPointLogsQueryVariables>(AgencyPointLogsDocument, baseOptions);
        }
export type AgencyPointLogsQueryHookResult = ReturnType<typeof useAgencyPointLogsQuery>;
export type AgencyPointLogsLazyQueryHookResult = ReturnType<typeof useAgencyPointLogsLazyQuery>;
export type AgencyPointLogsQueryResult = ApolloReactCommon.QueryResult<AgencyPointLogsQuery, AgencyPointLogsQueryVariables>;
export const TestTelsDocument = gql`
    query testTels {
  testTels {
    items {
      id
      tel
      type
    }
    total
  }
}
    `;

/**
 * __useTestTelsQuery__
 *
 * To run a query within a React component, call `useTestTelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTestTelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTestTelsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTestTelsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TestTelsQuery, TestTelsQueryVariables>) {
        return ApolloReactHooks.useQuery<TestTelsQuery, TestTelsQueryVariables>(TestTelsDocument, baseOptions);
      }
export function useTestTelsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TestTelsQuery, TestTelsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TestTelsQuery, TestTelsQueryVariables>(TestTelsDocument, baseOptions);
        }
export type TestTelsQueryHookResult = ReturnType<typeof useTestTelsQuery>;
export type TestTelsLazyQueryHookResult = ReturnType<typeof useTestTelsLazyQuery>;
export type TestTelsQueryResult = ApolloReactCommon.QueryResult<TestTelsQuery, TestTelsQueryVariables>;
export const CreateTestTelDocument = gql`
    mutation createTestTel($data: CreateTestTelInput!) {
  createTestTel(data: $data)
}
    `;
export type CreateTestTelMutationFn = ApolloReactCommon.MutationFunction<CreateTestTelMutation, CreateTestTelMutationVariables>;

/**
 * __useCreateTestTelMutation__
 *
 * To run a mutation, you first call `useCreateTestTelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTestTelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTestTelMutation, { data, loading, error }] = useCreateTestTelMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateTestTelMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateTestTelMutation, CreateTestTelMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateTestTelMutation, CreateTestTelMutationVariables>(CreateTestTelDocument, baseOptions);
      }
export type CreateTestTelMutationHookResult = ReturnType<typeof useCreateTestTelMutation>;
export type CreateTestTelMutationResult = ApolloReactCommon.MutationResult<CreateTestTelMutation>;
export type CreateTestTelMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateTestTelMutation, CreateTestTelMutationVariables>;
export const DeleteTestTelDocument = gql`
    mutation deleteTestTel($id: Int!) {
  deleteTestTel(id: $id)
}
    `;
export type DeleteTestTelMutationFn = ApolloReactCommon.MutationFunction<DeleteTestTelMutation, DeleteTestTelMutationVariables>;

/**
 * __useDeleteTestTelMutation__
 *
 * To run a mutation, you first call `useDeleteTestTelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTestTelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTestTelMutation, { data, loading, error }] = useDeleteTestTelMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTestTelMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteTestTelMutation, DeleteTestTelMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteTestTelMutation, DeleteTestTelMutationVariables>(DeleteTestTelDocument, baseOptions);
      }
export type DeleteTestTelMutationHookResult = ReturnType<typeof useDeleteTestTelMutation>;
export type DeleteTestTelMutationResult = ApolloReactCommon.MutationResult<DeleteTestTelMutation>;
export type DeleteTestTelMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteTestTelMutation, DeleteTestTelMutationVariables>;
export const MeDocument = gql`
    query me {
  me {
    id
    name
    email
    note
    userType
    point
    agencyPoint
    status
    userCode {
      cost
      content
    }
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MeQuery, MeQueryVariables>) {
        return ApolloReactHooks.useQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
      }
export function useMeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = ApolloReactCommon.QueryResult<MeQuery, MeQueryVariables>;
export const UserDocument = gql`
    query user($id: Int!) {
  user(id: $id) {
    id
    name
    email
    note
    userType
    point
    agencyPoint
    status
    sumCharged
    userCode {
      cost
      content
    }
  }
}
    `;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserQuery, UserQueryVariables>) {
        return ApolloReactHooks.useQuery<UserQuery, UserQueryVariables>(UserDocument, baseOptions);
      }
export function useUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, baseOptions);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = ApolloReactCommon.QueryResult<UserQuery, UserQueryVariables>;
export const MySendNumberDocument = gql`
    query mySendNumber {
  me {
    id
    sendNumber
  }
}
    `;

/**
 * __useMySendNumberQuery__
 *
 * To run a query within a React component, call `useMySendNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useMySendNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMySendNumberQuery({
 *   variables: {
 *   },
 * });
 */
export function useMySendNumberQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MySendNumberQuery, MySendNumberQueryVariables>) {
        return ApolloReactHooks.useQuery<MySendNumberQuery, MySendNumberQueryVariables>(MySendNumberDocument, baseOptions);
      }
export function useMySendNumberLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MySendNumberQuery, MySendNumberQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MySendNumberQuery, MySendNumberQueryVariables>(MySendNumberDocument, baseOptions);
        }
export type MySendNumberQueryHookResult = ReturnType<typeof useMySendNumberQuery>;
export type MySendNumberLazyQueryHookResult = ReturnType<typeof useMySendNumberLazyQuery>;
export type MySendNumberQueryResult = ApolloReactCommon.QueryResult<MySendNumberQuery, MySendNumberQueryVariables>;
export const UsersDocument = gql`
    query users($data: UsersInput!) {
  users(data: $data) {
    items {
      ...UserDetailFragment
    }
    total
  }
}
    ${UserDetailFragmentDoc}`;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        return ApolloReactHooks.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, baseOptions);
      }
export function useUsersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, baseOptions);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = ApolloReactCommon.QueryResult<UsersQuery, UsersQueryVariables>;
export const UserCodesDocument = gql`
    query userCodes($data: UserCodesInput!) {
  userCodes(data: $data) {
    items {
      ...UserCodeFragment
    }
    total
  }
}
    ${UserCodeFragmentDoc}`;

/**
 * __useUserCodesQuery__
 *
 * To run a query within a React component, call `useUserCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserCodesQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUserCodesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserCodesQuery, UserCodesQueryVariables>) {
        return ApolloReactHooks.useQuery<UserCodesQuery, UserCodesQueryVariables>(UserCodesDocument, baseOptions);
      }
export function useUserCodesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserCodesQuery, UserCodesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserCodesQuery, UserCodesQueryVariables>(UserCodesDocument, baseOptions);
        }
export type UserCodesQueryHookResult = ReturnType<typeof useUserCodesQuery>;
export type UserCodesLazyQueryHookResult = ReturnType<typeof useUserCodesLazyQuery>;
export type UserCodesQueryResult = ApolloReactCommon.QueryResult<UserCodesQuery, UserCodesQueryVariables>;
export const UserChargesDocument = gql`
    query userCharges($data: UserChargesInput!) {
  userCharges(data: $data) {
    items {
      ...UserChargeFragment
    }
    total
  }
}
    ${UserChargeFragmentDoc}`;

/**
 * __useUserChargesQuery__
 *
 * To run a query within a React component, call `useUserChargesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserChargesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserChargesQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUserChargesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserChargesQuery, UserChargesQueryVariables>) {
        return ApolloReactHooks.useQuery<UserChargesQuery, UserChargesQueryVariables>(UserChargesDocument, baseOptions);
      }
export function useUserChargesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserChargesQuery, UserChargesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserChargesQuery, UserChargesQueryVariables>(UserChargesDocument, baseOptions);
        }
export type UserChargesQueryHookResult = ReturnType<typeof useUserChargesQuery>;
export type UserChargesLazyQueryHookResult = ReturnType<typeof useUserChargesLazyQuery>;
export type UserChargesQueryResult = ApolloReactCommon.QueryResult<UserChargesQuery, UserChargesQueryVariables>;
export const CreateUserDocument = gql`
    mutation createUser($data: CreateUserInput!) {
  createUser(data: $data) {
    id
  }
}
    `;
export type CreateUserMutationFn = ApolloReactCommon.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, baseOptions);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = ApolloReactCommon.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const CreateUserCodeDocument = gql`
    mutation createUserCode($data: CreateUserCodeInput!) {
  createUserCode(data: $data)
}
    `;
export type CreateUserCodeMutationFn = ApolloReactCommon.MutationFunction<CreateUserCodeMutation, CreateUserCodeMutationVariables>;

/**
 * __useCreateUserCodeMutation__
 *
 * To run a mutation, you first call `useCreateUserCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserCodeMutation, { data, loading, error }] = useCreateUserCodeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateUserCodeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateUserCodeMutation, CreateUserCodeMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateUserCodeMutation, CreateUserCodeMutationVariables>(CreateUserCodeDocument, baseOptions);
      }
export type CreateUserCodeMutationHookResult = ReturnType<typeof useCreateUserCodeMutation>;
export type CreateUserCodeMutationResult = ApolloReactCommon.MutationResult<CreateUserCodeMutation>;
export type CreateUserCodeMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateUserCodeMutation, CreateUserCodeMutationVariables>;
export const DeleteUserCodeDocument = gql`
    mutation deleteUserCode($id: Int!) {
  deleteUserCode(id: $id)
}
    `;
export type DeleteUserCodeMutationFn = ApolloReactCommon.MutationFunction<DeleteUserCodeMutation, DeleteUserCodeMutationVariables>;

/**
 * __useDeleteUserCodeMutation__
 *
 * To run a mutation, you first call `useDeleteUserCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserCodeMutation, { data, loading, error }] = useDeleteUserCodeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserCodeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteUserCodeMutation, DeleteUserCodeMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteUserCodeMutation, DeleteUserCodeMutationVariables>(DeleteUserCodeDocument, baseOptions);
      }
export type DeleteUserCodeMutationHookResult = ReturnType<typeof useDeleteUserCodeMutation>;
export type DeleteUserCodeMutationResult = ApolloReactCommon.MutationResult<DeleteUserCodeMutation>;
export type DeleteUserCodeMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteUserCodeMutation, DeleteUserCodeMutationVariables>;
export const DeleteUserChargeDocument = gql`
    mutation deleteUserCharge($id: Int!) {
  deleteUserCharge(id: $id)
}
    `;
export type DeleteUserChargeMutationFn = ApolloReactCommon.MutationFunction<DeleteUserChargeMutation, DeleteUserChargeMutationVariables>;

/**
 * __useDeleteUserChargeMutation__
 *
 * To run a mutation, you first call `useDeleteUserChargeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserChargeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserChargeMutation, { data, loading, error }] = useDeleteUserChargeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserChargeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteUserChargeMutation, DeleteUserChargeMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteUserChargeMutation, DeleteUserChargeMutationVariables>(DeleteUserChargeDocument, baseOptions);
      }
export type DeleteUserChargeMutationHookResult = ReturnType<typeof useDeleteUserChargeMutation>;
export type DeleteUserChargeMutationResult = ApolloReactCommon.MutationResult<DeleteUserChargeMutation>;
export type DeleteUserChargeMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteUserChargeMutation, DeleteUserChargeMutationVariables>;
export const UpdateUserChargeDocument = gql`
    mutation updateUserCharge($data: UpdateUserChargeInput!) {
  updateUserCharge(data: $data)
}
    `;
export type UpdateUserChargeMutationFn = ApolloReactCommon.MutationFunction<UpdateUserChargeMutation, UpdateUserChargeMutationVariables>;

/**
 * __useUpdateUserChargeMutation__
 *
 * To run a mutation, you first call `useUpdateUserChargeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserChargeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserChargeMutation, { data, loading, error }] = useUpdateUserChargeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateUserChargeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserChargeMutation, UpdateUserChargeMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUserChargeMutation, UpdateUserChargeMutationVariables>(UpdateUserChargeDocument, baseOptions);
      }
export type UpdateUserChargeMutationHookResult = ReturnType<typeof useUpdateUserChargeMutation>;
export type UpdateUserChargeMutationResult = ApolloReactCommon.MutationResult<UpdateUserChargeMutation>;
export type UpdateUserChargeMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUserChargeMutation, UpdateUserChargeMutationVariables>;
export const ChargeDocument = gql`
    mutation charge($data: ChargeInput!) {
  charge(data: $data)
}
    `;
export type ChargeMutationFn = ApolloReactCommon.MutationFunction<ChargeMutation, ChargeMutationVariables>;

/**
 * __useChargeMutation__
 *
 * To run a mutation, you first call `useChargeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChargeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [chargeMutation, { data, loading, error }] = useChargeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useChargeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChargeMutation, ChargeMutationVariables>) {
        return ApolloReactHooks.useMutation<ChargeMutation, ChargeMutationVariables>(ChargeDocument, baseOptions);
      }
export type ChargeMutationHookResult = ReturnType<typeof useChargeMutation>;
export type ChargeMutationResult = ApolloReactCommon.MutationResult<ChargeMutation>;
export type ChargeMutationOptions = ApolloReactCommon.BaseMutationOptions<ChargeMutation, ChargeMutationVariables>;
export const UpdateUserDocument = gql`
    mutation updateUser($data: UpdateUserInput!) {
  updateUser(data: $data)
}
    `;
export type UpdateUserMutationFn = ApolloReactCommon.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, baseOptions);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = ApolloReactCommon.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const UpdatePasswordDocument = gql`
    mutation updatePassword($data: UpdatePasswordInput!) {
  updatePassword(data: $data)
}
    `;
export type UpdatePasswordMutationFn = ApolloReactCommon.MutationFunction<UpdatePasswordMutation, UpdatePasswordMutationVariables>;

/**
 * __useUpdatePasswordMutation__
 *
 * To run a mutation, you first call `useUpdatePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePasswordMutation, { data, loading, error }] = useUpdatePasswordMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdatePasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePasswordMutation, UpdatePasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdatePasswordMutation, UpdatePasswordMutationVariables>(UpdatePasswordDocument, baseOptions);
      }
export type UpdatePasswordMutationHookResult = ReturnType<typeof useUpdatePasswordMutation>;
export type UpdatePasswordMutationResult = ApolloReactCommon.MutationResult<UpdatePasswordMutation>;
export type UpdatePasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdatePasswordMutation, UpdatePasswordMutationVariables>;
export const UpdateSendNumberDocument = gql`
    mutation updateSendNumber($data: UpdateUserSendNumberInput!) {
  updateSendNumber(data: $data) {
    id
    sendNumber
  }
}
    `;
export type UpdateSendNumberMutationFn = ApolloReactCommon.MutationFunction<UpdateSendNumberMutation, UpdateSendNumberMutationVariables>;

/**
 * __useUpdateSendNumberMutation__
 *
 * To run a mutation, you first call `useUpdateSendNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSendNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSendNumberMutation, { data, loading, error }] = useUpdateSendNumberMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateSendNumberMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSendNumberMutation, UpdateSendNumberMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSendNumberMutation, UpdateSendNumberMutationVariables>(UpdateSendNumberDocument, baseOptions);
      }
export type UpdateSendNumberMutationHookResult = ReturnType<typeof useUpdateSendNumberMutation>;
export type UpdateSendNumberMutationResult = ApolloReactCommon.MutationResult<UpdateSendNumberMutation>;
export type UpdateSendNumberMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSendNumberMutation, UpdateSendNumberMutationVariables>;