import { faCashRegister } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Form, Button, Input, Space, InputNumber } from 'antd'
import * as B from 'src/components'
import { useUserContext } from 'src/contexts/UserContext'
import { useController } from './controller'

const ChargePage = () => {
  const {
    form,
    onSubmit,
    userCharges,
    total,
    tableColumns,
    loading,
    onChangeTablePage,
    tablePage,
    initialValues,
    me,
  } = useController()
  const { user } = useUserContext()

  return (
    <B.BaseTemplate>
      <B.Box mb={4}>
        <B.BaseText size={48}>
          <FontAwesomeIcon icon={faCashRegister} />
        </B.BaseText>

        <B.BaseTitle level={3}>충전하기</B.BaseTitle>

        <B.BaseText type="subText">
          <B.BaseText>
            <B.BaseText bold>{user.name}</B.BaseText>님 반갑습니다!
          </B.BaseText>

          <B.BaseText block>원하시는 발송량만큼 충전해주세요</B.BaseText>
          <B.BaseText block>건당 {me?.userCode?.cost ?? 0}원</B.BaseText>
          <B.BaseText block>
            충전 문의는 <img src="/telegram.png" width="20" height="20" alt="telegram" /> 텔레그램
            로 문의해주세요
          </B.BaseText>
        </B.BaseText>
      </B.Box>

      <B.Box mb={4}>
        <B.BaseForm form={form} onFinish={onSubmit} initialValues={initialValues}>
          <B.BaseCard>
             <Form.Item name="name" label="이름" rules={[{ required: true }]}>
              <Input placeholder="입금하시는 이름을 입력해주세요." />
            </Form.Item>

            <B.Box display="flex" justify="space-between" align="center" mb={2}>
              <B.Center>
                <Form.Item label="금액" name="money" rules={[{ required: true }]}>
                  <InputNumber />
                </Form.Item>
                {/* <B.BaseText pl={0.5} block>
                  충전 금액 :{' '}
                  <Form.Item noStyle shouldUpdate>
                    {({ getFieldValue }) => {
                      const money = getFieldValue('money') ?? 0
                      return (
                        <B.BaseText>
                          <B.MoneyFormat money={money} />원
                        </B.BaseText>
                      )
                    }}
                  </Form.Item>
                </B.BaseText> */}
              </B.Center>
              <B.Box>
                <B.Box mb={1}>
                  <Space>
                    <Button
                      type="primary"
                      onClick={() =>
                        form.setFieldsValue({ money: form.getFieldValue('money') + 10000 })
                      }
                    >
                      +10,000
                    </Button>
                    <Button
                      type="primary"
                      onClick={() =>
                        form.setFieldsValue({ money: form.getFieldValue('money') + 50000 })
                      }
                    >
                      +50,000
                    </Button>
                    <Button
                      type="primary"
                      onClick={() =>
                        form.setFieldsValue({ money: form.getFieldValue('money') + 100000 })
                      }
                    >
                      +100,000
                    </Button>
                    <Button
                      type="primary"
                      onClick={() =>
                        form.setFieldsValue({ money: form.getFieldValue('money') + 300000 })
                      }
                    >
                      +300,000
                    </Button>
                  </Space>
                </B.Box>
                <B.Box>
                  <Space>
                    <Button
                      type="primary"
                      onClick={() =>
                        form.setFieldsValue({ money: form.getFieldValue('money') + 500000 })
                      }
                    >
                      +500,000
                    </Button>
                    <Button
                      type="primary"
                      onClick={() =>
                        form.setFieldsValue({ money: form.getFieldValue('money') + 1000000 })
                      }
                    >
                      +1,000,000
                    </Button>
                    <Button
                      type="primary"
                      onClick={() =>
                        form.setFieldsValue({ money: form.getFieldValue('money') + 2000000 })
                      }
                    >
                      +2,000,000
                    </Button>
                    <Button onClick={() => form.setFieldsValue({ money: 0 })}>초기화</Button>
                  </Space>
                </B.Box>
              </B.Box>
            </B.Box>
            <B.Box>
              <Form.Item label="잔액">
                <B.BaseText>
                  <B.MoneyFormat money={me?.point ?? 0} />원
                </B.BaseText>
              </Form.Item>
              <Form.Item label="발송 가능 수량">
                <B.BaseText>
                  <B.MoneyFormat
                    money={
                      (me?.userCode?.cost ?? 0) === 0
                        ? 0
                        : Math.floor((me?.point ?? 0) / (me?.userCode?.cost ?? 1))
                    }
                  />
                </B.BaseText>
              </Form.Item>
            </B.Box>

            <B.Box>
              <B.TextCenter>
                <Button type="primary" htmlType="submit" block>
                  충전하기
                </Button>
              </B.TextCenter>
            </B.Box>
          </B.BaseCard>
        </B.BaseForm>
      </B.Box>

      <B.BaseDivider>충전 현황</B.BaseDivider>

      <B.BaseTable
        size="small"
        columns={tableColumns}
        dataSource={userCharges}
        loading={loading}
        bordered
        pagination={{
          current: tablePage,
          pageSize: 20,
          total: total,
          onChange: onChangeTablePage,
        }}
      />
    </B.BaseTemplate>
  )
}

export default ChargePage
