import React from 'react'
import { Route, RouteProps, Redirect } from 'react-router-dom'
import { useUserContext } from 'src/contexts/UserContext'
import { UserType } from 'src/generated/graphql'

export const AdminRoute = ({ component, ...rest }: RouteProps) => {
  const { user } = useUserContext()

  if (!component) return null
  const Component = component as React.ComponentType<RouteProps>

  if (!user) return <Redirect to="/" />
  if (user.userType !== UserType.Admin) return <Redirect to="/" />

  return (
    <Route
      {...rest}
      render={(props) => {
        return <Component />
      }}
    />
  )
}
