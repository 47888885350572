import * as B from 'src/components'
import { Form, Tabs, Input, Button, InputNumber, Select } from 'antd'
import { useController } from './controller'
import { HomeOutlined } from '@ant-design/icons'

const { TabPane } = Tabs
const { Option } = Select

const AgencyUserViewPage = () => {
  const {
    user,
    pointLogs,
    userChargesTotal,
    userCharges,
    pointTablePage,
    pointTableColumns,
    pointLogsTotal,
    pointLoading,
    onChangePointTablePage,
    onChangeChargeTablePage,
    chargeTablePage,
    chargeTableColumns,
    chargeLoading,
    messageLoading,
    messageTableColumns,
    messages,
    onChangeMessageTablePage,
    messagesTotal,
    messageTablePage,
    form,
    initialValues,
    onSubmit,
  } = useController()

  const conditionalRender = () => {
    if (!user) return null

    return (
      <Tabs defaultActiveKey="info" type="card">
        <TabPane tab="기본 정보" key="info">
          <B.BaseForm
            form={form}
            onFinish={onSubmit}
            initialValues={initialValues}
            labelCol={{ span: 4 }}
          >
            <Form.Item label="아이디">
              <B.BaseText bold>{user.email}</B.BaseText>
            </Form.Item>

            <Form.Item label="상태">
              <Form.Item name="status" label="상태" noStyle>
                <Select style={{ width: 200 }} placeholder="상태">
                  <Option value="NORMAL">활성화</Option>
                  <Option value="BAN">정지</Option>
                </Select>
              </Form.Item>
              <Button type="primary" htmlType="submit">
                수정
              </Button>
            </Form.Item>

            <Form.Item label="등록 추천인 코드">
              <B.BaseText bold>{user.userCode?.content}</B.BaseText>
            </Form.Item>

            <Form.Item label="잔여 포인트">
              <B.BaseText>
                <B.MoneyFormat money={user.point} />
              </B.BaseText>
            </Form.Item>

            <Form.Item label="총 입금 금액">
              <B.BaseText>
                <B.MoneyFormat money={user.sumCharged} />
              </B.BaseText>
            </Form.Item>

            <Form.Item label="건당 포인트">
              <Form.Item label="건당 포인트" name="cost" noStyle>
                <InputNumber />
              </Form.Item>
              <Button type="primary" htmlType="submit">
                수정
              </Button>
            </Form.Item>

            <Form.Item label="발송 가능 수량">
              <B.BaseText>
                <B.MoneyFormat
                  money={
                    (user.userCode?.cost ?? 0) === 0
                      ? 0
                      : Math.floor(user.point / (user.userCode?.cost ?? 0))
                  }
                />
              </B.BaseText>
            </Form.Item>

            <Form.Item label="노트">
              <Form.Item label="노트" name="note" noStyle>
                <Input.TextArea rows={3} />
              </Form.Item>
              <B.Box mt={1}>
                <Button type="primary" htmlType="submit">
                  기입
                </Button>
              </B.Box>
            </Form.Item>
          </B.BaseForm>
        </TabPane>
        <TabPane tab="포인트 이용 내역" key="point">
          <B.BaseTable
            size="small"
            columns={pointTableColumns}
            dataSource={pointLogs}
            loading={pointLoading}
            bordered
            pagination={{
              current: pointTablePage,
              pageSize: 20,
              total: pointLogsTotal,
              onChange: onChangePointTablePage,
            }}
          />
        </TabPane>
        <TabPane tab="입금 내역" key="charge">
          <B.BaseTable
            size="small"
            columns={chargeTableColumns}
            dataSource={userCharges}
            loading={chargeLoading}
            bordered
            pagination={{
              current: chargeTablePage,
              pageSize: 20,
              total: userChargesTotal,
              onChange: onChangeChargeTablePage,
            }}
          />
        </TabPane>

        <TabPane tab="발송 내역" key="message">
          <B.BaseTable
            size="small"
            columns={messageTableColumns}
            dataSource={messages}
            loading={messageLoading}
            bordered
            pagination={{
              current: messageTablePage,
              pageSize: 20,
              total: messagesTotal,
              onChange: onChangeMessageTablePage,
            }}
          />
        </TabPane>
      </Tabs>
    )
  }

  return (
    <div>
      <B.Box mb={4}>
        <B.BaseBreadcrumb
          items={[
            { name: <HomeOutlined />, link: '/agency' },
            { name: '회원 목록', link: '/agency' },
            { name: '회원 조회' },
          ]}
        />
      </B.Box>

      <B.Box mb={4}>{conditionalRender()}</B.Box>
    </div>
  )
}

export default AgencyUserViewPage
