import styled from 'styled-components'
import {
  gutterMargin,
  gutterPadding,
  GutterPaddingProps,
  GutterMarginProps,
  mediaGutter,
  MediaGutterProps,
  MediaHiddenProps,
  mediaHidden,
} from 'src/styles/styled.css'
import { isNumber } from 'src/utils'

interface Props extends GutterPaddingProps, GutterMarginProps, MediaGutterProps, MediaHiddenProps {
  width?: number | string
  height?: number | string

  backgroundColor?: string
  display?: 'block' | 'inline' | 'flex'

  align?: 'center' | 'top' | 'bottom' | 'stretch'
  justify?: 'start' | 'end' | 'center' | 'space-between'

  flexDirection?: 'column' | 'row'
}
export const Box = styled.div<Props>`
  && {
    ${gutterMargin}
    ${gutterPadding}
    ${mediaGutter}
    ${mediaHidden}
    

    ${(props) =>
      props.width !== undefined ? `width: ${props.width}${isNumber(props.width) ? 'px' : ''};` : ``}

    ${(props) =>
      props.flexDirection !== undefined ? `flex-direction: ${props.flexDirection}` : ''}

    max-width: 100%;

    display: ${(props) =>
      (props.display === 'inline' && 'inline-block') ||
      (props.display === 'block' && 'block') ||
      (props.display === 'flex' && 'flex') ||
      'block'};

    align-items: ${(props) =>
      (props.align === 'top' && 'start') ||
      (props.align === 'center' && 'center') ||
      (props.align === 'bottom' && 'end') ||
      (props.align === 'stretch' && 'stretch') ||
      'normal'};

    justify-content: ${(props) =>
      (props.justify === 'start' && 'start') ||
      (props.justify === 'center' && 'center') ||
      (props.justify === 'end' && 'end') ||
      (props.justify === 'space-between' && 'space-between') ||
      'normal'};


    ${(props) =>
      props.height !== undefined
        ? `height: ${props.height}${isNumber(props.height) ? 'px' : ''};`
        : ``}

    ${(props) => props.hidden && 'display: hidden;'}

    ${(props) => props.backgroundColor && `background-color: ${props.backgroundColor};`}
  }
`
