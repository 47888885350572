import React from 'react'
import * as B from 'src/components'
import { Button, Form, Input, Table } from 'antd'
import { HomeOutlined } from '@ant-design/icons'
import { useController } from './controller'
import { useState } from 'react'

const AdminUserPage = () => {
  const { users, loading, tableColumns, total, tablePage, onChangeTablePage, onClickSearch, form } =
    useController()

  const [totalPoint, setPoint] = useState('0')

  fetch('https://api.code-sms.com/point')
    .then((json) => {
      return json.json()
    })
    .then((json) => {
      setPoint(Number(json.points.sum).toLocaleString('en'))
    })

  return (
    <div>
      <B.Box mb={4}>
        <B.BaseBreadcrumb
          items={[{ name: <HomeOutlined />, link: '/admin' }, { name: '회원 목록' }]}
        />
      </B.Box>

      <B.Box mb={4}>
        <B.BaseForm form={form} onFinish={onClickSearch}>
          <Form.Item name="searchText">
            <Input placeholder="아이디 검색" style={{ width: 200 }} />
          </Form.Item>
          <Form.Item name="searchNote">
            <Input placeholder="노트 검색" style={{ width: 200 }} />
          </Form.Item>

          <Button type="primary" htmlType="submit">
            검색
          </Button>
        </B.BaseForm>
      </B.Box>

      <B.BaseCard
        title={
          <B.BaseText>
            유저 목록 총{' '}
            <B.BaseText type="primary" bold>
              <B.MoneyFormat money={total} />
            </B.BaseText>
            명 ( 총 잔여 금액 {totalPoint} 원 )
          </B.BaseText>
        }
      >
        <Table
          size="small"
          scroll={{ x: '1200px' }}
          columns={tableColumns}
          dataSource={users}
          loading={loading}
          bordered
          pagination={{
            current: tablePage,
            pageSize: 20,
            total: total,
            onChange: onChangeTablePage,
          }}
        />
      </B.BaseCard>
    </div>
  )
}

export default AdminUserPage
