import React from 'react'
import { PublicRoute } from 'src/components/routes/PublicRoute'
import { Link, Switch, useRouteMatch } from 'react-router-dom'
import { Col, Menu, Row } from 'antd'
import { PieChartOutlined, ProfileOutlined } from '@ant-design/icons'
import AdminCodePage from '.'
import AdminCodeLogPage from './Log'

const AdminCodeRoutes = () => {
  const { path } = useRouteMatch()
  return (
    <>
      <Row style={{ flexWrap: 'nowrap' }} gutter={20}>
        <Col flex="0 0 200px">
          <Menu style={{ background: 'transparent' }}>
            <Menu.Item key="1" icon={<PieChartOutlined />}>
              <Link to="/admin/code">코드 발급</Link>
            </Menu.Item>
            <Menu.Item key="2" icon={<ProfileOutlined />}>
              <Link to="/admin/code/log">코드 발급 내역</Link>
            </Menu.Item>
          </Menu>
        </Col>
        <Col flex="1 1 auto">
          <Switch>
            <PublicRoute path={`${path}/log`} component={AdminCodeLogPage} />
            <PublicRoute path={`${path}/`} component={AdminCodePage} />
          </Switch>
        </Col>
      </Row>
    </>
  )
}

export default AdminCodeRoutes
