import React, { useEffect } from 'react'
import { Route, RouteProps, Redirect } from 'react-router-dom'
import { useUserContext } from 'src/contexts/UserContext'
import { useIsUnderInspectionQuery, useMeQuery, UserStatus } from 'src/generated/graphql'
import { showError } from 'src/utils'

export const LoggedRoute = ({
  component,
  underInspection,
  ...rest
}: RouteProps & { underInspection?: boolean }) => {
  const { logged, onLogout } = useUserContext()
  const { data: underInspectionData } = useIsUnderInspectionQuery()
  const { data: meData } = useMeQuery()
  const isUnderInspection = underInspectionData?.isUnderInspection ?? false

  useEffect(() => {
    if (meData) {
      const status = meData.me?.status
      if (status === UserStatus.Ban) onLogout()
    }
    // eslint-disable-next-line
  }, [meData])

  if (!component) return null
  const Component = component as React.ComponentType<RouteProps>

  if (underInspection && isUnderInspection) {
    showError('사이트가 점검중입니다.')
    return <Redirect to="/" />
  }

  if (!logged) {
    showError('로그인해주세요.')
    return <Redirect to={`/`} />
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        return <Component />
      }}
    />
  )
}
