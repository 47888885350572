import React from 'react'
import { AdminTemplate } from 'src/components'
import { PublicRoute } from 'src/components/routes/PublicRoute'
import { Switch, useRouteMatch } from 'react-router-dom'
import AdminUserPage from './User'
import AdminCodeRoutes from './Code/routes'
import AdminPointRoutes from './Point/routes'
import AdminTestPage from './Test'
import AdminUserViewPage from './User/View'
import AdminNoticeRoutes from './Notice/routes'
import AdminPage from '.'

const AdminRoutes = () => {
  const { path } = useRouteMatch()
  return (
    <AdminTemplate>
      <Switch>
        <PublicRoute path={`${path}/test`} component={AdminTestPage} />
        <PublicRoute path={`${path}/point`} component={AdminPointRoutes} />
        <PublicRoute path={`${path}/code`} component={AdminCodeRoutes} />
        <PublicRoute path={`${path}/notice`} component={AdminNoticeRoutes} />

        <PublicRoute path={`${path}/user/:id`} component={AdminUserViewPage} />
        <PublicRoute path={`${path}/user`} component={AdminUserPage} />

        <PublicRoute path={`${path}`} component={AdminPage} />
      </Switch>
    </AdminTemplate>
  )
}

export default AdminRoutes
