import { useEffect } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'

const ScrollToTop = ({ history }: RouteComponentProps) => {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0)
    })

    return () => {
      unlisten()
    }
    // eslint-disable-next-line
  }, [])

  return null
}

export default withRouter(ScrollToTop)
