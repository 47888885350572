import styled from 'styled-components'
import { Tabs } from 'antd'

const { TabPane } = Tabs

export const StyledTabs = styled(Tabs)`
  min-height: 400px;

  & .ant-tabs-nav-list {
    height: 100%;
    display: flex;

    .ant-tabs-tab {
      flex: 1 1 auto;
    }
  }
`
export const StyledTabPane = styled(TabPane)``
