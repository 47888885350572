import React from 'react'
import styled from 'styled-components'
import {
  gutterMargin,
  gutterPadding,
  GutterPaddingProps,
  GutterMarginProps,
  mediaGutter,
  MediaGutterProps,
  MediaHiddenProps,
  mediaHidden,
} from 'src/styles/styled.css'

interface Props {
  level?: 1 | 2 | 3 | 4
  children?: React.ReactNode
  className?: string
}

const Title = ({ children, level, className }: Props) => {
  switch (level) {
    case 4:
      return <h4 className={`ant-typography ${className}`}>{children}</h4>
    case 3:
      return <h3 className={`ant-typography ${className}`}>{children}</h3>
    case 2:
      return <h2 className={`ant-typography ${className}`}>{children}</h2>
    case 1:
    default:
      return <h1 className={`ant-typography ${className}`}>{children}</h1>
  }
}

interface StyledProps
  extends GutterPaddingProps,
    GutterMarginProps,
    MediaGutterProps,
    MediaHiddenProps {
  type?: 'primary' | 'optional' | 'subText' | 'danger' | 'white' | 'text'
}
export const BaseTitle = styled(Title)<StyledProps>`
  ${gutterMargin}
  ${gutterPadding}
  ${mediaGutter}
  ${mediaHidden}

  && {
    color: ${(props) =>
      (props.type === 'primary' && props.theme.primary) ||
      (props.type === 'subText' && props.theme.subText) ||
      (props.type === 'optional' && props.theme.optionalText) ||
      (props.type === 'danger' && props.theme.danger) ||
      (props.type === 'text' && props.theme.text) ||
      (props.type === 'white' && 'white') ||
      'inherit'};

    ${(props) => (props.mb !== undefined ? `margin-bottom: ${props.mb * 10}px;` : ``)}
  }
`
