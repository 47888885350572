import { HomeOutlined } from '@ant-design/icons'
import { Radio, Button, Form, Input, Select, Table } from 'antd'
import React from 'react'
import * as B from 'src/components'
import { useController } from './controller'

const { Option } = Select

const AdminTestPage = () => {
  const {
    testTels,
    onSubmit,
    form,
    loading,
    tableColumns,
    onSubmitConfig,
    underInspectionForm,
  } = useController()
  return (
    <div>
      <B.Box mb={4}>
        <B.BaseBreadcrumb
          items={[{ name: <HomeOutlined />, link: '/admin' }, { name: '테스트' }]}
        />
      </B.Box>

      <B.Box mb={4}>
        <B.BaseCard title="사이트 점검">
          <B.BaseForm form={underInspectionForm} onFinish={onSubmitConfig}>
            <Form.Item name="underInspection">
              <Radio.Group buttonStyle="solid">
                <Radio.Button value={false}>사이트 운영 활성화</Radio.Button>
                <Radio.Button value={true}>사이트 점검</Radio.Button>
              </Radio.Group>
            </Form.Item>

            <Button type="primary" htmlType="submit">
              업데이트
            </Button>
          </B.BaseForm>
        </B.BaseCard>
      </B.Box>

      <B.BaseCard title="3사 테스트">
        <Table columns={tableColumns} loading={loading} dataSource={testTels} />
        <B.BaseDivider />
        <B.BaseForm layout="inline" form={form} onFinish={onSubmit}>
          <Form.Item name="tel" label="연락처" rules={[{ required: true }]}>
            <Input type="tel" placeholder="연락처" />
          </Form.Item>
          <Form.Item name="type" label="타입(통신사)" rules={[{ required: true }]}>
            <Select style={{ width: 200 }} placeholder="타입">
              <Option value="KT">KT</Option>
              <Option value="SK">SK</Option>
              <Option value="LG">LG</Option>
              <Option value="CHARGE">입금 확인 요청 번호</Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              추가
            </Button>
          </Form.Item>
        </B.BaseForm>
      </B.BaseCard>
    </div>
  )
}

export default AdminTestPage
