import { HomeOutlined } from '@ant-design/icons'
import { Row, Col, Table } from 'antd'
import React from 'react'
import * as B from 'src/components'
import { useController } from './controller'

const AdminPage = () => {
  const {
    loading,
    statistics,
    dailyCharges,
    dailyMessages,
    monthlyMessages,
    monthlyCharges,
    dailyMessagesTableColumns,
    weeklyCharges,
    weeklyChargesTableColumns,
    weeklyMessages,
    weeklyMessagesTableColumns,
    dailyChargesTableColumns,
    totalCharges,
    totalMessages,
  } = useController()

  const conditionalRender = () => {
    if (loading || !statistics) return null

    return (
      <>
        <B.BaseSpace direction="vertical">
          <B.BaseCard
            title={
              <B.BaseText>
                총 결제 금액{' '}
                <B.BaseText bold type="primary">
                  <B.MoneyFormat money={totalCharges} />
                </B.BaseText>
                원
              </B.BaseText>
            }
          >
            <Row gutter={20}>
              <Col xs={24} sm={8}>
                <Table
                  dataSource={dailyCharges}
                  columns={dailyChargesTableColumns}
                  size="small"
                  title={() => '일별'}
                />
              </Col>
              <Col xs={24} sm={8}>
                <Table
                  dataSource={weeklyCharges}
                  columns={weeklyChargesTableColumns}
                  size="small"
                  title={() => '주간별'}
                />
              </Col>
              <Col xs={24} sm={8}>
                <Table
                  dataSource={monthlyCharges}
                  columns={dailyChargesTableColumns}
                  size="small"
                  title={() => '월별'}
                />
              </Col>
            </Row>
          </B.BaseCard>

          <B.BaseCard
            title={
              <B.BaseText>
                총 발송 메시지 수{' '}
                <B.BaseText bold type="primary">
                  <B.MoneyFormat money={totalMessages} />
                </B.BaseText>
                건
              </B.BaseText>
            }
          >
            <Row gutter={20}>
              <Col xs={24} sm={8}>
                <Table
                  dataSource={dailyMessages}
                  columns={dailyMessagesTableColumns}
                  size="small"
                  title={() => '일별'}
                />
              </Col>
              <Col xs={24} sm={8}>
                <Table
                  dataSource={weeklyMessages}
                  columns={weeklyMessagesTableColumns}
                  size="small"
                  title={() => '주간별'}
                />
              </Col>
              <Col xs={24} sm={8}>
                <Table
                  dataSource={monthlyMessages}
                  columns={dailyMessagesTableColumns}
                  size="small"
                  title={() => '월별'}
                />
              </Col>
            </Row>
          </B.BaseCard>
        </B.BaseSpace>
      </>
    )
  }
  return (
    <div>
      <B.Box mb={4}>
        <B.BaseBreadcrumb
          items={[{ name: <HomeOutlined />, link: '/admin' }, { name: '대시보드' }]}
        />
      </B.Box>

      {conditionalRender()}
    </div>
  )
}

export default AdminPage
