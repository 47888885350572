import styled from 'styled-components'
import { Layout } from 'antd'
const { Header, Footer, Content } = Layout

export const StyledHeader = styled(Header)`
  padding: 0 20px;
`

export const StyledContent = styled(Content)`
  min-height: 100vh;
  width: 100%;
  max-width: 100%;
  padding: 20px 20px;
  margin: 0 auto;
`

export const StyledFooter = styled(Footer)`
  padding: 0;
`
