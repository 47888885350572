import React from 'react'
import { PublicRoute } from 'src/components/routes/PublicRoute'
import { Link, Switch, useRouteMatch } from 'react-router-dom'
import { Col, Menu, Row } from 'antd'
import {
  DollarOutlined,
  EuroCircleOutlined,
  PieChartOutlined,
  ProfileOutlined,
} from '@ant-design/icons'
import AdminPointPage from '.'
import AdminPointChargePage from './Charge'
import AdminPointLogPage from './Log'
import AdminPointAgencyPage from './Agency'
import AdminPointAgencyLogPage from './Agency/Log'

const AdminPointRoutes = () => {
  const { path } = useRouteMatch()
  return (
    <>
      <Row style={{ flexWrap: 'nowrap' }} gutter={20}>
        <Col flex="0 0 200px">
          <Menu style={{ background: 'transparent' }}>
            <Menu.Item key="1" icon={<PieChartOutlined />}>
              <Link to="/admin/point">포인트 지급&회수</Link>
            </Menu.Item>

            <Menu.Item key="2" icon={<DollarOutlined />}>
              <Link to="/admin/point/charge">입금확인 요청</Link>
            </Menu.Item>

            <Menu.Item key="3" icon={<ProfileOutlined />}>
              <Link to="/admin/point/log">포인트 지급&회수 내역</Link>
            </Menu.Item>

            <Menu.Item key="4" icon={<EuroCircleOutlined />}>
              <Link to="/admin/point/agency">대리점 정산</Link>
            </Menu.Item>

            <Menu.Item key="5" icon={<ProfileOutlined />}>
              <Link to="/admin/point/agency/log">대리점 정산 내역</Link>
            </Menu.Item>
          </Menu>
        </Col>
        <Col flex="1 1 auto">
          <Switch>
            <PublicRoute path={`${path}/log`} component={AdminPointLogPage} />
            <PublicRoute path={`${path}/charge`} component={AdminPointChargePage} />
            <PublicRoute path={`${path}/agency/log`} component={AdminPointAgencyLogPage} />
            <PublicRoute path={`${path}/agency`} component={AdminPointAgencyPage} />
            <PublicRoute path={`${path}/`} component={AdminPointPage} />
          </Switch>
        </Col>
      </Row>
    </>
  )
}

export default AdminPointRoutes
