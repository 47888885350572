import React, { useCallback, useEffect, useMemo, useState } from 'react'
import * as B from 'src/components'
import { ColumnsType } from 'antd/lib/table'
import { useUserContext } from 'src/contexts/UserContext'
import { useMessagesQuery, useHideMessageMutation } from 'src/generated/graphql'
import { format } from 'date-fns'
import { showError, showSuccess } from 'src/utils'
import CopyToClipboard from 'react-copy-to-clipboard'

export interface SendMessagesFormState {
  content: string
  tel: string
}

export const useController = () => {
  const { user } = useUserContext()
  const { data: messagesData, refetch: messageRefetch, loading: messageLoading } = useMessagesQuery(
    {
      variables: { data: { userId: user.id } },
    },
  )
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const [hideMessage] = useHideMessageMutation()

  const messages = useMemo(() => messagesData?.messages?.items ?? [], [messagesData])
  const messagesTotal = messagesData?.messages?.total ?? 0

  // 테이블 페이지네이션
  const [tablePage, setTablePage] = useState(1)

  const onChangeTablePage = useCallback((page: number) => {
    setTablePage(page)
  }, [])

  // 페이지가 바뀌면 새로 불러온다
  useEffect(() => {
    messageRefetch({ data: { page: tablePage, userId: user.id } })
    // eslint-disable-next-line
  }, [tablePage])

  // 테이블 구성
  const tableColumns: ColumnsType<NonNullable<typeof messages[number]>> = [
    {
      title: '발송시각',
      dataIndex: 'createdAt',
      render: (createdAt: Date) => (
        <B.BaseText>{format(new Date(createdAt), 'yyyy.MM.dd HH:mm:ss')}</B.BaseText>
      ),
      align: 'center',
    },
   {
      title: '내용',
      dataIndex: 'content',
      align: 'center',
      render: (content: string) => {
        return (
          <CopyToClipboard text={content}>
            <B.BaseText style={{color: "cornflowerblue",cursor: "pointer"}}onClick={() => showSuccess('메시지를 클립보드에 복사했습니다.')}>
              {content}
            </B.BaseText>
          </CopyToClipboard>
        )
      }
    },
    {
      title: '발송 건수',
      dataIndex: 'count',
      render: (count: number) => {
        return (
          <B.BaseText>
            <B.MoneyFormat money={count} />건
          </B.BaseText>
        )
      },
      align: 'center',
    },
    {
      title: '소요 금액',
      dataIndex: ['pointLog', 'point'],
      align: 'center',
    },
    {
      title: '발송 결과',
      dataIndex: 'result',
      render: (count: number) => {
        
        return (
          <B.BaseText>
            성공
          </B.BaseText>
        )
      },
      align: 'center',
    },
  ]

  const onClickDeleteAll = useCallback(async () => {
    await Promise.all(
      messages.map(async (msg) => {
        try {
          await hideMessage({ variables: { id: msg.id } })
        } catch (e: any) {
          showError(e?.message)
        }
      }),
    )
    showSuccess('성공적으로 삭제하였습니다.')
    messageRefetch({ data: { page: tablePage, userId: user.id } })
  }, [messages, messageRefetch, tablePage, user, hideMessage])

  const onClickDeleteSelected = useCallback(async () => {
    await Promise.all(
      selectedRowKeys.map(async (key) => {
        try {
          await hideMessage({ variables: { id: key as number } })
        } catch (e: any) {
          showError(e?.message)
        }
      }),
    )
    showSuccess('성공적으로 삭제하였습니다.')
    messageRefetch({ data: { page: tablePage, userId: user.id } })
  }, [selectedRowKeys, messageRefetch, tablePage, user, hideMessage])

  return {
    tablePage,
    onChangeTablePage,
    tableColumns,
    messagesTotal,
    messages: messages.map((message) => ({ ...message, key: message.id })),
    messageLoading,
    selectedRowKeys,
    setSelectedRowKeys,

    onClickDeleteSelected,
    onClickDeleteAll,
  }
}
