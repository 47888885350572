import React from 'react'
import { Form } from 'antd'
import RulesValidator from 'src/utils/utils.rules.validator'
import { ColProps } from 'antd/lib/col'
import { SizeType } from 'antd/lib/config-provider/SizeContext'
import { FormLayout, FormInstance } from 'antd/lib/form/Form'
import { Store } from 'antd/lib/form/interface'
import { useBaseFormContext } from 'src/contexts/BaseFormContext'

export interface BaseFormProps {
  name?: string
  form?: FormInstance
  size?: SizeType
  children?: React.ReactNode
  labelCol?: ColProps
  wrapperCol?: ColProps
  layout?: FormLayout
  scrollToFirstError?: boolean
  initialValues?: Store
  validateMessages?: any

  onChange?: (value: Store) => void
  onFinish?: (value: Store) => void
}

export const BaseForm = ({
  name,
  onChange,
  onFinish,
  children,
  size = 'large',
  scrollToFirstError = true,
  validateMessages,
  ...rest
}: BaseFormProps) => {
  const { onFormsFinish } = useBaseFormContext()

  return (
    <Form
      name={name}
      size={size}
      validateMessages={validateMessages ?? RulesValidator.defaultValidateMessages}
      onFinish={(value) => {
        if (onFinish) onFinish(value)
        if (onFormsFinish) onFormsFinish(name, value)
      }}
      {...rest}
    >
      {children}
    </Form>
  )
}
