import * as B from 'src/components'
import { Radio, Button, Form, InputNumber } from 'antd'
import { HomeOutlined } from '@ant-design/icons'
import { useController } from './controller'
import { useUserContext } from 'src/contexts/UserContext'
import { UserType } from 'src/generated/graphql'

const AgencyCodePage = () => {
  const { initialValues, userCodes, loading, tableColumns, onSubmit, form } = useController()
  const { user } = useUserContext()

  return (
    <div>
      <B.Box mb={4}>
        <B.BaseBreadcrumb
          items={[{ name: <HomeOutlined />, link: '/agency' }, { name: '코드 발급' }]}
        />
      </B.Box>

      <B.Box mb={4}>
        <B.BaseForm form={form} onFinish={onSubmit} layout="vertical" initialValues={initialValues}>
          <B.BaseCard title="코드 발급">
            <Form.Item name="cost" label="건당 포인트" rules={[{ required: true }]}>
              <InputNumber placeholder="15" style={{ width: 200 }} />
            </Form.Item>

            <Form.Item name="bonus" label="무료 전송 건수" rules={[{ required: true }]}>
              <InputNumber placeholder="5" style={{ width: 200 }} />
            </Form.Item>

            {user.userType === UserType.Admin && (
              <Form.Item name="type" label="코드 타입" rules={[{ required: true }]}>
                <Radio.Group>
                  <Radio.Button value="NORMAL">일반</Radio.Button>
                  <Radio.Button value="AGENCY">대리점</Radio.Button>
                </Radio.Group>
              </Form.Item>
            )}

            <Button type="primary" htmlType="submit">
              발급하기
            </Button>
          </B.BaseCard>
        </B.BaseForm>
      </B.Box>

      <B.BaseCard title="미 사용 코드 리스트">
        <B.BaseTable
          size="small"
          columns={tableColumns}
          dataSource={userCodes}
          loading={loading}
          bordered
        />
      </B.BaseCard>
    </div>
  )
}

export default AgencyCodePage
