import React from 'react'
import { Layout, Menu } from 'antd'
import {
  UserOutlined,
  HomeOutlined,
  CreditCardOutlined,
  PayCircleOutlined,
  ProfileOutlined,
  FireOutlined,
  FileOutlined,
} from '@ant-design/icons'
import { Link } from 'react-router-dom'
import * as S from './style'
import * as B from 'src/components'
import theme from 'src/styles/theme'

interface Props {
  children?: React.ReactNode
}

export const AdminTemplate = ({ children }: Props) => {
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <S.StyledHeader>
        <Menu theme="dark" mode="horizontal" triggerSubMenuAction="click">
          <Menu.Item key="home">
            <Link to="/">
              <HomeOutlined />
            </Link>
          </Menu.Item>
          <Menu.Item key="dashboard" icon={<CreditCardOutlined />}>
            <Link to="/admin">어드민 대시보드</Link>
          </Menu.Item>
          <Menu.Item key="user" icon={<UserOutlined />}>
            <Link to="/admin/user">회원관리</Link>
          </Menu.Item>

          <Menu.Item key="point" icon={<PayCircleOutlined />}>
            <Link to="/admin/point">포인트관리</Link>
          </Menu.Item>

          <Menu.Item key="code" icon={<ProfileOutlined />}>
            <Link to="/admin/code">코드 관리</Link>
          </Menu.Item>

          <Menu.Item key="notice" icon={<FileOutlined />}>
            <Link to="/admin/notice">팝업 관리</Link>
          </Menu.Item>

          <Menu.Item key="test" icon={<FireOutlined />}>
            <Link to="/admin/test">테스트</Link>
          </Menu.Item>
        </Menu>
      </S.StyledHeader>
      <Layout>
        <Layout style={{ backgroundColor: theme.card }}>
          <S.StyledContent>{children}</S.StyledContent>
          <S.StyledFooter>
            <B.Box mlr={4} mtb={2}>
              <B.BaseText size="small">
                <B.BaseText mr={2}>
                  <B.BaseLink to="/policy/privacy">개인정보처리방침</B.BaseLink>
                </B.BaseText>
                <B.BaseText mr={2}>
                  <B.BaseLink to="/policy/terms">이용약관</B.BaseLink>
                </B.BaseText>
              </B.BaseText>
            </B.Box>
            <B.BaseDivider />
          </S.StyledFooter>
        </Layout>
      </Layout>
    </Layout>
  )
}
