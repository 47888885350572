import * as B from 'src/components'
import { HomeOutlined } from '@ant-design/icons'
import { useController } from './controller'

const AgencyCodeLogPage = () => {
  const { userCodes, loading, tableColumns, onChangeTablePage, total, tablePage } = useController()

  return (
    <div>
      <B.Box mb={4}>
        <B.BaseBreadcrumb
          items={[
            { name: <HomeOutlined />, link: '/agency' },
            { name: '코드 발급', link: '/agency/code' },
            { name: '코드 발급 내역' },
          ]}
        />
      </B.Box>

      <B.BaseCard title="코드 발급 내역">
        <B.BaseTable
          size="small"
          columns={tableColumns}
          dataSource={userCodes}
          loading={loading}
          bordered
          pagination={{
            current: tablePage,
            pageSize: 20,
            total: total,
            onChange: onChangeTablePage,
          }}
        />
      </B.BaseCard>
    </div>
  )
}

export default AgencyCodeLogPage
