import { createHttpLink, from } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'
import { GRAPHQL_API } from 'src/constants'
import storage from 'src/utils/utils.storage'

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) => {
      if (process.env.NODE_ENV === 'development')
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
            locations,
            null,
            2,
          )}, Path: ${path}`,
        )
    })

  if (networkError) console.log(`[Network error]: ${networkError}`)
})

const httpLink = createHttpLink({
  uri: GRAPHQL_API,
})

const authLink = setContext((_, { headers }) => {
  const token = storage.getItem('access_token')
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})

export default from([errorLink, authLink, httpLink])
