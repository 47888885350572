import { css, ThemedCssFunction, DefaultTheme } from 'styled-components'

const sizes = {
  xs: 576 - 1,
  sm: 768 - 1,
  md: 992 - 1,
  lg: 1200 - 1,
  xl: 1600 - 1,
}

export const media = (Object.keys(sizes) as (keyof typeof sizes)[]).reduce((acc, label) => {
  acc[label] = (first: any, ...interpolations: any[]) => css`
    @media (max-width: ${sizes[label]}px) {
      ${css(first, ...interpolations)};
    }
  `
  return acc
}, {} as { [key in keyof typeof sizes]: ThemedCssFunction<DefaultTheme> })

export const mediaUp = (Object.keys(sizes) as (keyof typeof sizes)[]).reduce((acc, label) => {
  acc[label] = (first: any, ...interpolations: any[]) => css`
    @media (min-width: ${sizes[label] + 1}px) {
      ${css(first, ...interpolations)};
    }
  `
  return acc
}, {} as { [key in keyof typeof sizes]: ThemedCssFunction<DefaultTheme> })
