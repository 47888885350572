import { useCallback, useEffect, useMemo, useState } from 'react'
import * as B from 'src/components'
import { Button, Form, Modal, Tooltip } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { ColumnsType } from 'antd/lib/table'
import { useUserContext } from 'src/contexts/UserContext'
import {
  useMeQuery,
  useMessagesQuery,
  useMessageCountQueuedQuery,
  useSendMessagesMutation,
  useHideMessageMutation,
  Message,
  MessageSendType,
} from 'src/generated/graphql'
import { format } from 'date-fns'
import CopyToClipboard from 'react-copy-to-clipboard'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { uniq, uniqBy } from 'ramda'
import { showError, showSuccess } from 'src/utils'

export interface SendMessagesFormState {
  content: string
  tel: string
  sendType: 'random' | 'fixed'
}

export const useController = () => {
  const { user } = useUserContext()
  const [form] = useForm()
  
  useEffect(() => {
    form.setFieldsValue({
      content: '',
      tel: '',
      sendType: 'random', // 초기값 설정
    });
  }, [form]);

  const { data: meData, refetch: meRefetch } = useMeQuery()
  const [sendMessages] = useSendMessagesMutation()
  const {
    data: messagesData,
    refetch: messageRefetch,
    loading: messageLoading,
  } = useMessagesQuery({
    variables: { data: { userId: user.id } },
  })
  const { data: messageCountData, refetch: messageCountRefetch } = useMessageCountQueuedQuery({
    pollInterval: 2000,
  })
  const [hideMessage] = useHideMessageMutation()
  const [tabIndex, setTabIndex] = useState('0')

  const messageCountQueued = messageCountData?.messageCountQueued ?? 0

  const me = meData?.me

  const point = me?.point ?? 0
  const cost = me?.userCode?.cost ?? 0
  const possibleSendNum = cost === 0 ? 0 : Math.floor(point / cost)

  const messages = messagesData?.messages?.items ?? []
  const messagesTotal = messagesData?.messages?.total ?? 0

  const isReplaceMode = tabIndex === '1'

  // 테이블 페이지네이션
  const [tablePage, setTablePage] = useState(1)

  const onChangeTablePage = useCallback((page: number) => {
    setTablePage(page)
  }, [])

  // 페이지가 바뀌면 새로 불러온다
  useEffect(() => {
    messageRefetch({ data: { page: tablePage, userId: user.id } })
    // eslint-disable-next-line
  }, [tablePage])

  // 문자열을 배열로 파싱한다.
  const convertToArrayTels = useCallback(
    (telString: string) => {
      if (isReplaceMode) {
        const telsRemovedDash = telString.replace(/-/g, '')

        return telsRemovedDash.split(/\r?\n/).filter((tel) => tel) // 공백 제거
      } else {
        const telsRemovedDash = telString.replace(/-/g, '').replace(/ /g, '').replace(/,/, '\n')

        return telsRemovedDash.split(/\r?\n/).filter((tel) => tel) // 공백 제거
      }
    },
    [isReplaceMode],
  )

  // 유효한 연락처로만 거른다.
  const matchValidatedTels = useCallback(
    (tels: string[]) => {
      if (isReplaceMode) {
        return tels.filter((tel) => tel.match(/^0?10\d{7,8} [a-zA-Z가-힣]+$/))
      } else {
        return tels.filter((tel) => tel.match(/^0?10\d{7,8}$/))
      }
    },
    [isReplaceMode],
  )

  // 유효하지 않은 연락처가 있는지 검사한다.
  //const validateTels = useCallback(
  //(tels: string[]) => {
  //for (let i = 0; i < tels.length; i++) {
  //const matched = tels[i].match(
  //isReplaceMode ? /^0?10\d{7,8},[a-zA-Z가-힣]+$/ : /^0?10\d{7,8}$/,
  //)
  //}
  //},
  //[isReplaceMode],
  //)

  const onSubmit = useCallback(
    async (forTest: boolean) => {
      const updatedFormState = form.getFieldsValue(true) as SendMessagesFormState

      const target = document.getElementById('content') as HTMLTextAreaElement

      const { tel, sendType } = updatedFormState

      const content = target.value

      const tels = convertToArrayTels(tel ?? '')

      //try {
      // validateTels(tels)
      // } catch (e) {
      // return showError(e?.message)
      // }

      const matchedTels = matchValidatedTels(tels)

      if (matchedTels.length > 10000) return showError('10000건 이하로 입력해주세요.')

      if (matchedTels.length > possibleSendNum) return showError('발송 가능 건수를 초과하였습니다.')

      if (content.length < 3) return showError('메세지 문구를 "필수"로 입력해주세요.')

      if (content.length > 70) return showError('70자를 넘으셨습니다')

      try {
        await sendMessages({
          variables: {
            data: {
              content,
              tel: matchedTels,
              forTest,
              replaceMode: isReplaceMode,
              sendType: sendType === 'fixed' ? MessageSendType.Fixed : MessageSendType.Random,
            },
          },
        })

        const { checked } = document.getElementById('saveMsg') as HTMLInputElement
        showSuccess('메시지가 발송되었습니다.')
        if (!forTest)
          form.setFieldsValue({ content: checked ? content : undefined, tel: undefined })
        meRefetch()
        messageRefetch()
        messageCountRefetch()

        if (!checked) target.value = ''
      } catch (e: any) {
        showError(e.message)
      }
    },
    [
      form,
      convertToArrayTels,
      matchValidatedTels,
      sendMessages,
      possibleSendNum,
      meRefetch,
      messageRefetch,
      messageCountRefetch,
      //validateTels,
      isReplaceMode,
    ],
  )

  const onClickDeleteMessage = useCallback(
    async (_message: Message) => {
      try {
        await hideMessage({ variables: { id: _message.id } })
        showSuccess('성공적으로 삭제하였습니다.')
        messageRefetch({ data: { page: tablePage, userId: user.id } })
      } catch (e) {
        // @ts-ignore
        showError(e?.message)
      }
    },
    [tablePage, user, hideMessage, messageRefetch],
  )

  const onClickTestSend = useCallback(async () => {
    Modal.confirm({
      maskClosable: true,
      okText: '발송',
      content: '3사 테스트 발송하시겠습니까?',
      onOk: async () => {
        await form.validateFields()
        onSubmit(true)
      },
    })
  }, [form, onSubmit])

  const onClickSubmitButton = useCallback(() => {
    Modal.confirm({
      maskClosable: true,
      okText: '발송',
      content: '전화번호 및 내용을 다시 확인하시고 확실하시다면 발송버튼을 눌러주세요',
      onOk: async () => {
        onSubmit(false)
      },
    })
  }, [onSubmit])

  // 테이블 구성
  const tableColumns: ColumnsType<NonNullable<(typeof messages)[number]>> = [
    {
      title: '발송시각',
      dataIndex: 'createdAt',
      render: (createdAt: Date) => (
        <B.BaseText>{format(new Date(createdAt), 'yyyy.MM.dd HH:mm:ss')}</B.BaseText>
      ),
      align: 'center',
    },
    {
      title: (
        <B.BaseText>
          <Tooltip title="클립보드에 복사하려면 내용을 클릭해주세요.">
            내용 <QuestionCircleOutlined />
          </Tooltip>
        </B.BaseText>
      ),
      dataIndex: 'content',
      align: 'center',
      ellipsis: { showTitle: false },
      render: (content: string) => {
        return (
          <>
            <CopyToClipboard text={content}>
              <Tooltip title={content} placement="topLeft">
                <B.BaseText
                  type="primary"
                  clickable
                  onClick={() => showSuccess('메시지를 클립보드에 복사했습니다.')}
                >
                  {content}
                </B.BaseText>
              </Tooltip>
            </CopyToClipboard>
          </>
        )
      },
    },
    {
      title: '발송 건수',
      dataIndex: 'count',
      render: (count: number) => {
        return (
          <B.BaseText>
            <B.MoneyFormat money={count} />건 (100%)
          </B.BaseText>
        )
      },
      align: 'center',
    },
    {
      title: '소모 포인트',
      dataIndex: ['pointLog', 'point'],
      align: 'center',
    },
    {
      title: '관리',
      dataIndex: [],
      render: (_message: Message) => (
        <Button onClick={() => onClickDeleteMessage(_message)} size="small" danger>
          제거
        </Button>
      ),
      align: 'center',
    },
  ]

  // 신호등 색상 결정
  const trafficLightPercent = useMemo(() => {
    return Math.max(20000 - messageCountQueued, 500) / 20000
  }, [messageCountQueued])

  const onChangeUploadTel = useCallback(
    (telString) => {
      // 텍스트를 파싱한다.
      let tels = convertToArrayTels(telString)

      if (tels.length > 10000) {
        showError('전화번호는 10000개 이상 업로드할 수 없습니다.')
        tels = tels.slice(0, 10000)
      }

      //try {
      //validateTels(tels)
      //} catch (e) {
      //return showError(e?.message)
      //}

      form.setFieldsValue({ tel: tels.join('\n') })
    },
    [form, convertToArrayTels],
  )

  const onClickRemoveDuplicatedTels = useCallback(() => {
    const updatedFormState = form.getFieldsValue(true) as SendMessagesFormState
    const { tel } = updatedFormState

    const tels = matchValidatedTels(convertToArrayTels(tel ?? ''))

    const removedTels = isReplaceMode ? uniqBy((tel) => tel.split(',')[0], tels) : uniq(tels)

    const removedCount = tels.length - removedTels.length

    if (removedCount > 0) showSuccess(`${removedCount}건의 중복 번호를 제거하였습니다.`)
    else showSuccess('중복된 번호가 없습니다.')

    form.setFieldsValue({ tel: removedTels.join('\n') })
  }, [form, convertToArrayTels, matchValidatedTels, isReplaceMode])

  return {
    form,
    onSubmit,
    me,
    possibleSendNum,
    point,
    tablePage,
    onChangeTablePage,
    tableColumns,
    messagesTotal,
    messages: messages.map((message) => ({ ...message, key: message.id })),
    messageLoading,
    messageCountQueued,
    trafficLightPercent,
    onClickTestSend,
    onChangeUploadTel,
    convertToArrayTels,
    matchValidatedTels,
    //validateTels,
    onClickRemoveDuplicatedTels,
    tabIndex,
    setTabIndex,
    isReplaceMode,
    onClickSubmitButton,
  }
}
