import React from 'react'
import { Route, RouteProps } from 'react-router-dom'

export const PublicRoute = ({ component, ...rest }: RouteProps) => {
  if (!component) return null
  const Component = component as React.ComponentType<RouteProps>
  return (
    <Route
      {...rest}
      render={(props) => {
        return <Component />
      }}
    />
  )
}
