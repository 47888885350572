import { HomeOutlined } from '@ant-design/icons'
import { Button, Form, Input, Table } from 'antd'
import React from 'react'
import * as B from 'src/components'
import { useController } from './controller'

const AdminPointChargePage = () => {
  const {
    userCharges,
    total,
    tableColumns,
    loading,
    onChangeTablePage,
    tablePage,
    onClickSearch,
    form,
  } = useController()

  return (
    <div>
      <B.Box mb={4}>
        <B.BaseBreadcrumb
          items={[
            { name: <HomeOutlined />, link: '/admin' },
            { name: '포인트 관리', link: '/admin/point' },
            { name: '입금확인 요청' },
          ]}
        />
      </B.Box>

      <B.BaseTitle level={3}>입금확인 요청</B.BaseTitle>

      <B.Box mb={4}>
        <B.BaseForm form={form} onFinish={onClickSearch}>
          <Form.Item name="searchId">
            <Input placeholder="아이디 검색" style={{ width: 200 }} />
          </Form.Item>
          <Form.Item name="searchName">
            <Input placeholder="입금자명 검색" style={{ width: 200 }} />
          </Form.Item>
          <Form.Item name="searchNote">
            <Input placeholder="노트 검색" style={{ width: 200 }} />
          </Form.Item>

          <Button type="primary" htmlType="submit">
            검색
          </Button>
        </B.BaseForm>
      </B.Box>

      <Table
        size="small"
        columns={tableColumns}
        dataSource={userCharges}
        loading={loading}
        bordered
        pagination={{
          current: tablePage,
          pageSize: 20,
          total: total,
          onChange: onChangeTablePage,
        }}
      />
    </div>
  )
}

export default AdminPointChargePage
