import { QuestionCircleOutlined } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { format } from 'date-fns'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import * as B from 'src/components'
import {
  useUserQuery,
  usePointLogsQuery,
  UserChargeStatus,
  useUserChargesQuery,
  useMessagesQuery,
  Message,
  useUpdateUserMutation,
  UserStatus,
} from 'src/generated/graphql'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useForm } from 'antd/es/form/Form'
import { showError, showSuccess } from 'src/utils'

export interface UpdateUserFormState {
  password: string
  note: string
  cost: number
  status: UserStatus
}

export const useController = () => {
  const { id } = useParams<{ id: string }>()
  const { data: userData } = useUserQuery({ variables: { id: Number(id) } })
  const user = userData?.user
  const {
    data: pointLogsData,
    refetch: pointRefetch,
    loading: pointLoading,
  } = usePointLogsQuery({
    variables: { data: { userId: Number(id) } },
  })
  const {
    data: userChargesData,
    refetch: chargeRefetch,
    loading: chargeLoading,
  } = useUserChargesQuery({
    variables: { data: { userId: Number(id) } },
  })
  const {
    data: messagesData,
    refetch: messageRefetch,
    loading: messageLoading,
  } = useMessagesQuery({
    variables: { data: { userId: Number(id), visibleAll: true } },
  })
  const [updateUser] = useUpdateUserMutation()
  const [form] = useForm()

  const initialValues: UpdateUserFormState = useMemo(() => {
    return {
      password: '',
      cost: user?.userCode?.cost ?? 0,
      note: user?.note ?? '',
      status: user?.status ?? UserStatus.Normal,
    }
  }, [user])

  const onSubmit = useCallback(async () => {
    const updatedFormState = form.getFieldsValue(true) as UpdateUserFormState

    const { password, cost, note, status } = updatedFormState
    try {
      await updateUser({
        variables: {
          data: {
            id: Number(id),
            password,
            note,
            cost,
            status,
          },
        },
      })

      showSuccess('회원 정보를 수정하였습니다.')
    } catch (e) {
      showError(e?.message)
    }
  }, [form, updateUser, id])

  const pointLogs = pointLogsData?.pointLogs?.items ?? []
  const pointLogsTotal = pointLogsData?.pointLogs?.total ?? 0

  const userCharges = userChargesData?.userCharges?.items ?? []
  const userChargesTotal = userChargesData?.userCharges?.total ?? 0

  const messages = messagesData?.messages?.items ?? []
  const messagesTotal = messagesData?.messages?.total ?? 0

  // 테이블 페이지네이션
  const [pointTablePage, setPointTablePage] = useState(1)

  const onChangePointTablePage = useCallback((page: number) => {
    setPointTablePage(page)
  }, [])

  const [chargeTablePage, setChargeTablePage] = useState(1)

  const onChangeChargeTablePage = useCallback((page: number) => {
    setChargeTablePage(page)
  }, [])

  const [messageTablePage, setMessageTablePage] = useState(1)

  const onChangeMessageTablePage = useCallback((page: number) => {
    setMessageTablePage(page)
  }, [])

  // 페이지가 바뀌면 새로 불러온다
  useEffect(() => {
    pointRefetch({ data: { page: pointTablePage, userId: Number(id) } })
    // eslint-disable-next-line
  }, [pointTablePage])

  useEffect(() => {
    chargeRefetch({ data: { userId: Number(id), page: chargeTablePage } })
    // eslint-disable-next-line
  }, [chargeTablePage])

  useEffect(() => {
    messageRefetch({ data: { userId: Number(id), page: messageTablePage, visibleAll: true } })
    // eslint-disable-next-line
  }, [messageTablePage])

  // 테이블 구성
  const pointTableColumns: ColumnsType<NonNullable<typeof pointLogs[number]>> = [
    {
      title: '생성일',
      dataIndex: 'createdAt',
      render: (createdAt: Date) => (
        <B.BaseText>{format(new Date(createdAt), 'yyyy.MM.dd')}</B.BaseText>
      ),
      align: 'center',
    },
    {
      title: '타입',
      dataIndex: ['pointLogType', 'content'],
      align: 'center',
    },
    {
      title: '지급&회수 포인트',
      dataIndex: 'point',
      align: 'center',
    },
    {
      title: '잔여 포인트',
      dataIndex: 'restPoint',
      align: 'center',
    },
    {
      title: '노트',
      dataIndex: 'note',
      align: 'center',
    },
  ]

  // 테이블 구성
  const chargeTableColumns: ColumnsType<NonNullable<typeof userCharges[number]>> = [
    {
      title: '생성일',
      dataIndex: 'createdAt',
      render: (createdAt: Date) => (
        <B.BaseText>{format(new Date(createdAt), 'yyyy.MM.dd')}</B.BaseText>
      ),
      align: 'center',
    },
    {
      title: '입금자명',
      dataIndex: 'name',
      align: 'center',
    },
    {
      title: '입금 금액',
      dataIndex: 'money',
      align: 'center',
    },
    {
      title: '진행 상태',
      dataIndex: 'status',
      render: (status: UserChargeStatus) =>
        status === UserChargeStatus.Ready ? (
          <B.BaseText type="subText">대기</B.BaseText>
        ) : status === UserChargeStatus.Applied ? (
          <B.BaseText type="primary">수락</B.BaseText>
        ) : (
          <B.BaseText type="danger">거절</B.BaseText>
        ),
      align: 'center',
    },
  ]

  const onDownloadTels = useCallback((_message: Message) => {
    console.log(_message)
    const element = document.createElement('a')
    const file = new Blob([_message.tel], {
      type: 'text/plain;charset=utf-8',
    })
    element.href = URL.createObjectURL(file)
    element.download = '발송목록.txt'
    document.body.appendChild(element)
    element.click()
  }, [])

  // 테이블 구성
  const messageTableColumns: ColumnsType<NonNullable<typeof messages[number]>> = [
    {
      title: '발송시각',
      dataIndex: 'createdAt',
      render: (createdAt: Date) => (
        <B.BaseText>{format(new Date(createdAt), 'yyyy.MM.dd HH:mm:ss')}</B.BaseText>
      ),
      align: 'center',
    },
    {
      title: (
        <B.BaseText>
          <Tooltip title="클립보드에 복사하려면 내용을 클릭해주세요.">
            내용 <QuestionCircleOutlined />
          </Tooltip>
        </B.BaseText>
      ),
      dataIndex: 'content',
      align: 'center',
      ellipsis: { showTitle: false },
      render: (content: string) => {
        return (
          <>
            <CopyToClipboard text={content}>
              <Tooltip title={content} placement="topLeft">
                <B.BaseText
                  type="primary"
                  clickable
                  onClick={() => showSuccess('메시지를 클립보드에 복사했습니다.')}
                >
                  {content}
                </B.BaseText>
              </Tooltip>
            </CopyToClipboard>
          </>
        )
      },
    },
    {
      title: '발송 건수',
      dataIndex: 'count',
      render: (count: number) => {
        return (
          <B.BaseText>
            <B.MoneyFormat money={count} />건 (100%)
          </B.BaseText>
        )
      },
      align: 'center',
    },
    {
      title: '소모 포인트',
      dataIndex: ['pointLog', 'point'],
      align: 'center',
    },
    {
      title: '발송 번호',
      dataIndex: [],
      render: (_message: Message) => (
        <Button onClick={() => onDownloadTels(_message)} size="small">
          다운로드
        </Button>
      ),
      align: 'center',
    },
  ]

  return {
    user,

    form,
    initialValues,
    onSubmit,

    userCharges: userCharges.map((userCharge) => ({ ...userCharge, key: userCharge.id })),
    userChargesTotal,
    chargeTableColumns,
    chargeLoading,
    chargeTablePage,
    onChangeChargeTablePage,

    pointLogs: pointLogs.map((pointLog) => ({ ...pointLog, key: pointLog.id })),
    pointLogsTotal,
    pointTableColumns,
    pointLoading,
    pointTablePage,
    onChangePointTablePage,

    messages: messages.map((message) => ({ ...message, key: message.id })),
    messagesTotal,
    messageTableColumns,
    messageLoading,
    messageTablePage,
    onChangeMessageTablePage,
  }
}
